import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2';
import { isPlatform } from '@ionic/core';
import { IonApp } from '@ionic/react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useAppSelector } from 'app/hooks';
import 'app/i18n/i18n';

import { DataLoader } from 'common/components';
import { dispatchSavedData } from 'common/helpers/dispatchSavedData';
import { authSelector } from 'features/auth/authSlice';
import { NewPhaseModal } from 'features/modals/NewPhaseModal';
import { RatingAndFeedback } from 'features/modals/RatingAndFeedback';
import { Notification } from 'features/notification/Notification';
import { nutritionSelector, setNutritionProgram } from 'features/nutrition/nutritionSlice';
import { NutritionProgram } from 'features/nutrition/types';
import { getAllData } from 'features/offline-mode/queries/getData';
import { storeData } from 'features/offline-mode/queries/storeData';
import { useGetInfoForOfflineQuery } from 'features/profile/profileAPI';
import { setOfflineMode } from 'features/profile/profileSlice';
import { WorkoutProgram } from 'features/workout/types';
import { setWorkoutProgram, workoutSelector } from 'features/workout/workoutSlice';
import { checkForPermission } from 'push-notifications';
import { Routes } from './app/routes/routes';
import { Product } from './common/constants/product';

export const CalendarContext = createContext<any>(null);

function App() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { workoutProgram } = useAppSelector(workoutSelector);
  const { nutritionProgram } = useAppSelector(nutritionSelector);
  const { user } = useAppSelector(authSelector);
  const { data: fetchedData, isFetching } = useGetInfoForOfflineQuery(undefined, {
    skip: !user || !user.startDate,
  });

  if (isPlatform('mobile') && !isPlatform('mobileweb')) {
    window.screen.orientation.lock('portrait');
  }

  const [sqliteData, setSqliteData] = useState<{
    workout: WorkoutProgram;
    nutrition: NutritionProgram;
    isOfflineMode: boolean;
    dataSize: number;
  } | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  if (isPlatform('mobile') && !isPlatform('mobileweb')) {
    if (Product) {
      const currentProduct = InAppPurchase2.get(Product.id);
      if (!currentProduct || currentProduct?.state !== InAppPurchase2.REGISTERED) {
        InAppPurchase2.register(Product);
        InAppPurchase2.refresh();
        console.log(`${Product.id}: `, currentProduct);
      }
    }
  }

  useEffect(() => {
    const compareData = async () => {
      const { sqliteWorkoutData, sqliteNutritionData, isOfflineMode, dataSize } = await getAllData();

      dispatch(setOfflineMode(isOfflineMode));

      if (sqliteWorkoutData && sqliteNutritionData) {
        if (
          fetchedData &&
          (sqliteWorkoutData.version < fetchedData?.workoutProgram.version ||
            sqliteNutritionData.version < fetchedData?.nutritionProgram.version)
        ) {
          setSqliteData({
            workout: sqliteWorkoutData,
            nutrition: sqliteNutritionData,
            isOfflineMode,
            dataSize,
          });
          setShowPopup(true);
        } else {
          dispatch(setWorkoutProgram(sqliteWorkoutData));
          dispatch(setNutritionProgram(sqliteNutritionData));
        }
      } else {
        if (fetchedData) {
          await storeData(
            {
              workout: fetchedData.workoutProgram,
              nutrition: fetchedData.nutritionProgram,
            },
            { isOfflineMode, dataSize }
          );
        }
        dispatch(setWorkoutProgram(fetchedData?.workoutProgram));
        dispatch(setNutritionProgram(fetchedData?.nutritionProgram));
      }
    };

    if (!isFetching)
      if (isPlatform('mobile') && !isPlatform('mobileweb')) {
        compareData();
      } else {
        dispatch(setWorkoutProgram(fetchedData?.workoutProgram));
        dispatch(setNutritionProgram(fetchedData?.nutritionProgram));
      }
  }, [isFetching]);

  useEffect(() => {
    const handleShouldUpdate = async () => {
      if (shouldUpdate && fetchedData && sqliteData) {
        await dispatchSavedData(fetchedData, sqliteData.dataSize);
        window.location.reload();
      }
      if (!shouldUpdate && sqliteData) {
        await storeData(
          {
            workout: sqliteData.workout,
            nutrition: sqliteData.nutrition,
          },
          { isOfflineMode: sqliteData.isOfflineMode, dataSize: sqliteData.dataSize }
        );
      }
    };

    handleShouldUpdate();
  }, [shouldUpdate]);

  useEffect(() => {
    const checkPermission = async () => {
      if (user && user.startDate && isPlatform('mobile') && !isPlatform('mobileweb')) {
        setShowNotifications(await checkForPermission());
      }
    };

    checkPermission();
  }, []);

  return (
    <IonApp>
      {user && user.startDate && (!nutritionProgram || !workoutProgram) && (
        <DataLoader
          showPopup={showPopup}
          closePopup={() => setShowPopup(false)}
          setShouldUpdate={(value) => setShouldUpdate(value)}
        />
      )}
      {user && user.startDate && showNotifications && nutritionProgram && workoutProgram && (
        <Notification handleHide={() => setShowNotifications(false)} />
      )}
      <CalendarContext.Provider value={{ showCalendar, setShowCalendar }}>
        <div className="min-h-screen App min-w-screen safe-top safe-left safe-right safe-bottom ios-height">
          <Router>
            <PayPalScriptProvider
              deferLoading={true}
              options={{
                'client-id': `${process.env.REACT_APP_CLIENT_ID}`,
                currency: 'EUR',
                components: 'buttons',
              }}
            >
              <Routes />
            </PayPalScriptProvider>
            {user && user.startDate && !!nutritionProgram && !!workoutProgram && (
              <>
                <NewPhaseModal />
                {isPlatform('android') || isPlatform('ios') ? <RatingAndFeedback /> : <></>}
              </>
            )}
          </Router>
        </div>
      </CalendarContext.Provider>
    </IonApp>
  );
}

export default App;
