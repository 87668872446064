import { useEffect, useState } from 'react';
import { Network } from '@capacitor/network';

export const onlineStatus = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    Network.addListener('networkStatusChange', (status) => {
      setIsOnline(status.connected);
    });
  }, []);

  return { isOnline };
};
