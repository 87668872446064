import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { useDispatch } from 'react-redux';

import { Button, ErrorMsg, LoadingOverlay, SuccessMsg } from 'common/components';
import { dispatchSavedData } from 'common/helpers/dispatchSavedData';
import { profileSelector, setOfflineMode } from 'features/profile/profileSlice';
import { workoutSelector } from 'features/workout/workoutSlice';
import { nutritionSelector } from 'features/nutrition/nutritionSlice';
import { deleteOfflineData } from 'features/offline-mode/deleteOfflineData';
import { deleteData } from 'features/offline-mode/queries/deleteData';
import { useLazyGetInfoForOfflineQuery } from './profileAPI';

import ArrowBackIcon from 'assets/icons/ArrowBack.svg';

export const DataSize = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataSize, media, isOfflineMode, toBeDownloaded, alreadyDownloaded } = useAppSelector(profileSelector);
  const { nutritionProgram } = useAppSelector(nutritionSelector);
  const { workoutProgram } = useAppSelector(workoutSelector);

  const [refetch, { isLoading: isNewDataLoading }] = useLazyGetInfoForOfflineQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [isSavedSuccess, setIsSavedSuccess] = useState<boolean | null>(null);
  const [isDeletedSuccess, setIsDeletedSuccess] = useState<boolean | null>(null);

  const saveDataForOfflineMode = async () => {
    setIsLoading(true);

    await dispatchSavedData({ ...media, nutritionProgram, workoutProgram }, dataSize);

    setIsSavedSuccess(true);
    setIsLoading(false);
  };

  const deleteDataForOfflineMode = async () => {
    setIsLoading(true);

    const { isSuccess } = await deleteOfflineData();

    if (isSuccess) {
      dispatch(setOfflineMode(false));
      await deleteData();
      await refetch();
    }

    setIsDeletedSuccess(isSuccess);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isSavedSuccess !== null)
      setTimeout(() => {
        setIsSavedSuccess(null);
        window.location.reload();
      }, 2000);
    if (isDeletedSuccess !== null)
      setTimeout(() => {
        setIsDeletedSuccess(null);
        window.location.reload();
      }, 2000);
  }, [isSavedSuccess, isDeletedSuccess]);

  return (
    <>
      {(isLoading || isNewDataLoading) && (
        <LoadingOverlay
          message={
            toBeDownloaded &&
            alreadyDownloaded &&
            `${toBeDownloaded - alreadyDownloaded} von ${toBeDownloaded} erfolgreich heruntergeladen`
          }
        />
      )}
      {isSavedSuccess && <SuccessMsg text={t('success.downloadCompleted')} />}
      {isDeletedSuccess && <SuccessMsg text={t('success.deleteCompleted')} />}
      {isDeletedSuccess === false && <ErrorMsg text={t('errors.wentWrong')} />}
      <div className="text-center relative mb-11">
        <div className="absolute flex items-center h-6" onClick={() => navigate(-1)}>
          <img className="w-4 h-auto mr-1" src={ArrowBackIcon} />
          <span>{t('basics.back')}</span>
        </div>
        <h3 className="">{t('profile.offlineMode')}</h3>
      </div>
      <div className="pb-8 xl:pb-12 flex flex-col justify-between h-[calc(100%-72px)]">
        <div>
          <p className="font-bold pb-4">
            {isOfflineMode ? t('profile.dataDownloaded') : `${t('profile.spaceNeeded')}:`}
          </p>
          <p className="font-bold text-2xl pb-4">
            {((dataSize || 1) / (1024 * 1024)).toFixed(2)} GB
            <span className={isOfflineMode ? 'text-base font-normal ml-4' : 'hidden'}>
              {t('profile.downloadedOnDevice')}
            </span>
          </p>
          <p className="font-bold pb-4">{!isOfflineMode && t('profile.wifiRecommended')}</p>
        </div>
        <Button
          title={isOfflineMode ? t('profile.buttonDelete') : t('profile.buttonDownload')}
          onClick={isOfflineMode ? deleteDataForOfflineMode : saveDataForOfflineMode}
        />
      </div>
    </>
  );
};
