import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Lottie from 'react-lottie';

import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';
import { Exercises } from 'features/workout/types';
import { Timer } from '../Timer/Timer';
import { ExerciseElement } from './ExerciseElement';

import HandSwipe from 'assets/lotties/hand-swipe.json';

interface ExerciseProps {
  allExercises: Exercises[];
  selectedExercise: Exercises;
  close?: () => void;
  changeSelectedWorkout?: (workout: Exercises) => void;
}

export const Exercise: React.FunctionComponent<ExerciseProps> = ({
  allExercises,
  selectedExercise,
  close,
  changeSelectedWorkout,
}) => {
  const { platform } = useCheckPlatform();
  const { duration, break: exerciseBreak, sets, unit, exercise } = selectedExercise;
  const { type, name } = exercise;

  const [beginExercise, setBeginExercise] = useState(false);
  const [showHandSwipe, setShowHandSwipe] = useState(true);
  const [activeSwipeIndex, setActiveSwipeIndex] = useState<number>(0);

  const exerciseNumber = allExercises.indexOf(selectedExercise) + 1;
  const numberOfExercises = allExercises.length;

  useEffect(() => {
    if (selectedExercise && platform === 'desktop') setBeginExercise(false);
  }, [selectedExercise]);

  useEffect(() => {
    setTimeout(() => {
      setShowHandSwipe(false);
    }, 5000);
  });

  return beginExercise && duration ? (
    <Timer
      duration={duration}
      name={name}
      type={type}
      unit={unit}
      exerciseBreak={exerciseBreak}
      sets={sets}
      onClick={() => setBeginExercise(false)}
    />
  ) : platform === 'desktop' ? (
    <ExerciseElement
      selectedExercise={selectedExercise}
      close={close}
      exerciseNumber={exerciseNumber}
      activeSwipeIndex={activeSwipeIndex}
      countOfExercises={numberOfExercises}
      onClick={() => setBeginExercise(true)}
    />
  ) : (
    <>
      <Swiper
        effect="cards"
        slidesPerView={1}
        spaceBetween={25}
        initialSlide={exerciseNumber - 1}
        className="h-[calc(100vh-82px)]"
        onActiveIndexChange={(swipe) => {
          setActiveSwipeIndex(swipe.activeIndex);
        }}
      >
        {allExercises.map((exercise, index) => (
          <SwiperSlide
            key={`swip-exercise-${exercise.id}`}
            defaultValue={exercise.id}
            className="overflow-y-auto bb-hide-scrollbar"
          >
            <ExerciseElement
              selectedExercise={exercise}
              close={close}
              exerciseNumber={index + 1}
              countOfExercises={numberOfExercises}
              activeSwipeIndex={activeSwipeIndex}
              onClick={() => {
                setBeginExercise(true);
                changeSelectedWorkout && changeSelectedWorkout(exercise);
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {showHandSwipe && (
        <div className="absolute right-5 bottom-20 z-10">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: HandSwipe,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            height={64}
            width={64}
          />
        </div>
      )}
    </>
  );
};
