import React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { ForgotPasswordBody } from './types';
import { Button, ErrorMsg, Input, LoadingOverlay, SuccessMsg } from '../../common/components';
import { useForgotPasswordMutation } from './authAPI';

export const ForgotPasswordPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [send, { isLoading, isSuccess, isError }] = useForgotPasswordMutation();

  const ForgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string().required(t('errors.emailRequired')).email(t('errors.emailInvalid')),
  });

  const handleSubmit = async (values: ForgotPasswordBody) => {
    await send(values);
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <h1 className="mb-3 md:mb-6">{t('forgotPassword.title')}</h1>
      <Formik initialValues={{ email: '' }} validationSchema={ForgotPasswordValidationSchema} onSubmit={handleSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="flex flex-col justify-between h-[68%] w-full">
            <div className="flex flex-col">
              <Input
                autoComplete="username"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('basics.email')}
                value={values.email}
                error={errors.email}
                touched={touched.email}
              />
              <Link to="/login" className="text-right">
                {t('forgotPassword.backToLoginButton')}
              </Link>
            </div>
            <div className='flex flex-col items-center'>
              {((touched.email && errors.email) || isError) && (
                <ErrorMsg text={errors.email || t('errors.wentWrong') || ''} blocked />
              )}
              {isSuccess && <SuccessMsg blocked text={t('success.emailSent') || ''} />}
              <Button
                onClick={handleSubmit}
                disabled={!values.email || !!Object.keys(errors).length || isLoading}
                title={t('forgotPassword.send')}
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
