import React from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { de, uk } from 'date-fns/locale';
import { useDispatch } from 'react-redux';

import { WorkoutDay } from 'features/workout/types';
import { setSelectedDate } from 'features/workout/workoutSlice';
import { validateClickableDates } from 'common/helpers/validateClickableDates';

export const WeekExercises: React.FunctionComponent<{ days: WorkoutDay[]; startDate: Date; currentDay: number }> = ({
  days,
  startDate,
  currentDay,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const today = Math.floor((new Date().getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24)) + 1;
  const workoutDays = days ? [...days] : [];

  const nextDayWithExercises = workoutDays
    ?.sort((a, b) => a.day - b.day)
    .find((day) => day.day > today && day.exercises.length)?.day;
  const nextDateWithExercises = new Date(
    new Date(startDate).getTime() + 1000 * 3600 * 24 * (nextDayWithExercises ? nextDayWithExercises - 1 : 1)
  );

  const handleSelectDay = (day: { date: Date }) => {
    const { date } = day;

    if (validateClickableDates(date, nextDateWithExercises, new Date(startDate).getTime())) {
      dispatch(setSelectedDate(new Date(date).getTime()));
    }
  };

  const weekDays = () => {
    const weekDaysData: { date: Date; current: boolean; hasExercises: boolean; day: number }[] = [];

    const firstDay = new Date().getTime() - 1000 * 3600 * 24 * 2;

    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDay + 1000 * 3600 * 24 * i);
      const dayOfprogram =
        Math.floor((new Date(date).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24)) + 1;

      const hasExercises = !!days?.find((day) => day.day === dayOfprogram);

      weekDaysData.push({ date, current: i === 2, hasExercises, day: dayOfprogram });
    }

    return weekDaysData;
  };

  return (
    <div className={today !== currentDay ? 'mb-4' : 'mb-6 md:mb-8'}>
      <div className="bg-progresbarbg grid grid-cols-7 gap-3 md:gap-4 rounded-ml py-2 px-[13px] md:px-[26px] mb-4">
        {weekDays().map((day) => (
          <div
            key={`date-${format(new Date(day.date), 'dd.MM.yyyy')}`}
            className={`h-9 md:h-14 md:w-full flex flex-col items-center justify-center  rounded-ms 
            ${day.current ? 'bg-main-red' : 'bg-dayofweek'}
            ${day.day === currentDay ? 'border border-white' : ''}
            ${
              validateClickableDates(day.date, nextDateWithExercises, new Date(startDate).getTime())
                ? 'cursor-pointer'
                : ''
            }
            ${
              validateClickableDates(day.date, nextDateWithExercises, new Date(startDate).getTime()) && !day.current
                ? '!bg-gray-light'
                : ''
            }
            `}
            onClick={() => handleSelectDay(day)}
          >
            <span className="font-bold text-xs md:text-sm md:mb-0.5 leading-[12px] md:leading-[16px]">
              {format(new Date(day.date), 'dd')}
            </span>
            <span className="text-[10px] md:text-xs md:mb-0.5 leading-[10px] md:leading-[14px] uppercase">
              {format(new Date(day.date), 'EEE', { locale: i18next.language === 'de' ? de : uk }).substr(0, 2)}
            </span>
            {day.hasExercises && (
              <span className={`w-1 h-1 rounded-full ${day.current ? 'bg-white' : 'bg-main-red'}`}></span>
            )}
          </div>
        ))}
      </div>
      {today !== currentDay && (
        <div className="flex justify-end">
          <button
            onClick={() => dispatch(setSelectedDate(new Date().setHours(0, 0, 0, 0)))}
            className="px-2 py-0.5 bg-red text-font-main rounded-sm text-sm font-bold scale-100 duration-200 hover:scale-105"
          >
            {t('workout.backToToday')}
          </button>
        </div>
      )}
    </div>
  );
};
