import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Impressum, PrivacyPolicy, Terms } from './components';
import { LegalPageProps } from './types';

export const LegalPage: React.FunctionComponent<LegalPageProps> = ({ page }) => {
  const { t } = useTranslation();

  const getData = () => {
    switch (page) {
      case 'terms':
        return <Terms />;
      case 'privacy-policy':
        return <PrivacyPolicy />;
      case 'imprint':
        return <Impressum />;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div className="flex flex-col flex-1 justify-between">
      {getData()}
      <div className="mt-20 pt-10 text-center border-t border-white">
        <ul className="mb-4 mx-auto">
          <li className="inline-block">
            <Link className="mr-[10px] hover:underline" to="/terms">
              <strong>{t('basics.terms')}</strong>
            </Link>
          </li>
          <li className="inline-block">
            <Link className="mr-[10px] hover:underline" to="/privacy-policy">
              <strong>{t('basics.policy')}</strong>
            </Link>
          </li>
          <li className="inline-block">
            <Link className="hover:underline" to="/imprint">
              <strong>{t('basics.impressum')}</strong>
            </Link>
          </li>
        </ul>
        <p>&copy; {new Date().getFullYear()} Urbanathletix GmbH</p>
      </div>
    </div>
  );
};
