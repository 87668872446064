import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'common/components';

export const RegisterConfirmationPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const email = localStorage.getItem('email');

  return (
    <div className="flex flex-col justify-between h-[calc(100%-152px)]">
      <div className="text-left  flex flex-col">
        <h1 className="mt-7 md:mt-3 md:mb-6 mb-8">{t('registerConfirmation.title')}</h1>
        <p className="mb-6 font-bold">{t('registerConfirmation.emailSent')}</p>
        <p className="max-w-lg">{t('registerConfirmation.msg', { email })}</p>
      </div>

      <Button title={t('forgotPassword.backToLoginButton')} onClick={() => navigate('/login')} />
    </div>
  );
};
