import { connectToDB } from '../connectToDb';

export const deleteData = async () => {
  const connectionResult = await connectToDB();
  const DB = connectionResult?.db;

  await DB?.execute(`
    DELETE FROM workoutProgram;
    DELETE FROM nutritionProgram;
    DELETE FROM offlineMode;
  `);
};
