import React from 'react';

interface RadioProps {
  name: string;
  id: string;
  checked: boolean;
  value: 'stripe' | 'paypal';
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Radio: React.FunctionComponent<RadioProps> = ({ name, id, checked, value, onChange }) => {
  return (
    <input
      name={name}
      onChange={onChange}
      value={value}
      checked={checked}
      id={id}
      type="radio"
      className="appearance-none rounded-full w-4 h-4 focus:outline-none bg-font-secondary cursor-pointer checked:bg-white checked:border-[5px] checked:border-red"
    />
  );
};
