import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router-dom';

import { Header, LoadingOverlay, ProgramProgress } from 'common/components';
import { nutritionSelector } from 'features/nutrition/nutritionSlice';
import { authSelector } from 'features/auth/authSlice';
import { PhasesProps } from 'features/nutrition/types';
import { PhaseElement } from './components/PhaseElement/PhaseElement';
import { AddProgress } from './components/AddProgress/AddProgress';
import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';
import { useGetProgressQueryWithState } from 'common/helpers/hooks/useGetProgressQuery';
import { Photo } from './components/Photo/Photo';
import { ProgressItem } from './types';
import { progressesSelector } from './progressSlice';

import MediaIcon from 'assets/icons/Media.svg';
import { GalleryPage } from './GalleryPage';
import { CalendarContext } from 'App';
import { CalendarBuild } from 'common/components/Calendar/CalendarBuild';

export const ProgressPage = () => {
  const { showCalendar } = useContext(CalendarContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading } = useGetProgressQueryWithState();
  const { user } = useAppSelector(authSelector);
  const { nutritionProgram } = useAppSelector(nutritionSelector);
  const { progresses } = useAppSelector(progressesSelector);
  const { platform } = useCheckPlatform();

  const [showAddProgressMobile, setShowProgressMobile] = useState(false);
  const [entry, setEntry] = useState<ProgressItem | null>(null);

  const currentDay = Math.floor((new Date().getTime() - new Date(user.startDate).getTime()) / (1000 * 3600 * 24)) + 1;

  const currentPhase: PhasesProps = nutritionProgram?.phases.find(
    (phase: PhasesProps) => phase.phase.fromDay <= currentDay && phase.phase.untilDay >= currentDay
  );

  const lastProgress = progresses[progresses.length - 1];
  const progressWithImage = progresses
    .filter((progress: ProgressItem) => progress.image)
    .sort((a: ProgressItem, b: ProgressItem) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

  const handleChange = (index: number) => {
    if (index < 0) setEntry(progressWithImage[progressWithImage.length - 1]);
    if (index > progressWithImage.length - 1) setEntry(progressWithImage[0]);
    if (index >= 0 && index < progressWithImage.length) setEntry(progressWithImage[index]);
  };

  return showAddProgressMobile && platform === 'non-desktop' ? (
    <>
      {isLoading && <LoadingOverlay />}
      <AddProgress
        currentPhase={currentPhase?.phaseNumber}
        phaseId={currentPhase.phase.id}
        close={() => setShowProgressMobile(false)}
      />
    </>
  ) : (
    <>
      {isLoading && <LoadingOverlay />}
      <Header title={t(nutritionProgram?.days >= currentDay ? 'progress.title' : 'nutrition.programCompleted')} />
      <Photo progress={entry} handleClose={() => setEntry(null)} handleChange={handleChange} />
      <div className="xl:flex justify-between">
        <div className="xl:w-[calc(50%-15px)]">
          <ProgramProgress />
          {nutritionProgram?.days < currentDay && (
            <div className="mb-10">
              <h3 className="mb-4">{t('progress.congratulations')}</h3>
              {/* change it later */}
              <p className="mb-6">
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat.{' '}
              </p>
              <div className="flex">
                <div className="w-1/2">
                  <p className="font-bold mb-3">{t('progress.startWeight')}</p>
                  <span className="text-[32px] font-bold">{user.weight} KG</span>
                </div>
                {lastProgress && (
                  <div className="w-1/2">
                    <p className="font-bold mb-3">{t('progress.endWeight')}</p>
                    <span className="text-[32px] font-bold">{lastProgress.weight} KG</span>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="flex justify-between mb-4">
            <h3>{t('progress.phases')}</h3>
            {(nutritionProgram?.days >= currentDay || platform === 'non-desktop') && (
              <img className="w-6 cursor-pointer" src={MediaIcon} onClick={() => navigate('gallery')} />
            )}
          </div>
          <div className="space-y-4  pb-[96px] md:space-y-6 xl:pb-12">
            {nutritionProgram?.phases.map((phase: PhasesProps) => (
              <PhaseElement
                key={`phase-${phase.id}`}
                phase={phase}
                currentDay={currentDay}
                isCurrentPhase={phase.phaseNumber === currentPhase?.phaseNumber}
                isFuturePhase={phase.phaseNumber > currentPhase?.phaseNumber || !currentPhase}
                startDate={user.startDate}
                onClick={() => setShowProgressMobile(true)}
                setEntry={(entry) => setEntry(entry)}
              />
            ))}
          </div>
        </div>
        {showCalendar ? (
          <div className="lg:w-[calc(50%-15px)] ">
            <CalendarBuild />
          </div>
        ) : (
          <div className="hidden xl:block xl:w-[calc(50%-15px)] max-h-[724px] pb-8 xl:pb-12">
            <>
              {nutritionProgram?.days >= currentDay && currentPhase ? (
                <AddProgress currentPhase={currentPhase?.phaseNumber} phaseId={currentPhase?.phase.id} />
              ) : (
                <GalleryPage />
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
};
