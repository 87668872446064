import { useSelector } from 'react-redux';

import { useLazyCreatePaypalCheckoutSessionQuery } from 'features/payments/paymentsAPI';
import { paymentsSelector } from 'features/payments/paymentsSlice';

export const useCreatePaypalCheckoutSessionQueryWithState = () => {
  const { checkoutSession } = useSelector(paymentsSelector);
  const [refetch, { isLoading, isSuccess, isFetching }] = useLazyCreatePaypalCheckoutSessionQuery();

  return { checkoutSession, isFetching, isSuccess, isLoading, refetch };
};
