import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { BodyTypeItems } from './config';
import { BodyTypeItem } from '../components/BodyTypeItem/BodyTypeItem';

export const Bodytype: React.FunctionComponent<{
  handleClick: (value: string) => void;
  selectedValue: string;
  gender: string;
}> = ({ handleClick, selectedValue, gender }) => {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="mb-6 md:mb-9">{t('onboarding.bodyType')}</h1>
      <Swiper
        breakpoints={{ 640: { slidesPerView: 2.1 }, 1000: { slidesPerView: 3 } }}
        slidesPerView={1.1}
        spaceBetween={25}
        className="h-[calc(100%-160px)]"
      >
        {BodyTypeItems.map((type) => (
          <SwiperSlide key={`${type.title}-body-type`} defaultValue={type.value}>
            <BodyTypeItem
              {...type}
              gender={gender}
              handleClick={(value: string) => handleClick(value)}
              isActive={selectedValue === type.value}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
