import React, { FunctionComponent, useEffect, useState } from 'react';

import { ExerciseInformation } from 'common/components';
import { Exercises } from 'features/workout/types';
import { replaceDataUrl } from '../../../offline-mode/queries/getData';

interface TrainingProps {
  exercises: Exercises;
  onClick: () => void;
  isActive?: boolean;
}

export const Training: FunctionComponent<TrainingProps> = ({ exercises, onClick, isActive }) => {
  const { duration, break: exerciseBreak, repetitions, sets, unit, exercise } = exercises;
  const { type, name, image } = exercise;
  const [storedImage, setImage] = useState(image?.url || '');

  useEffect(() => {
    const getImageFromStorage = async (image: string) => {
      return replaceDataUrl(image);
    };
    if (image) {
      getImageFromStorage(image.url).then((url: string) => {
        setImage(url);
      });
    }
  }, [image]);
  return (
    <div
      className={`relative w-full h-30 md:h-[144px] cursor-pointer ${
        isActive && 'border-4 border-main-red rounded-lg'
      }`}
      onClick={onClick}
    >
      <img className="w-full h-full rounded-[20px] object-cover" src={storedImage} />
      <div className="absolute flex flex-col justify-between bottom-2 p-2 ml-2 w-[calc(100%-16px)] bg-main-opacity rounded-ml">
        <div className="w-full flex justify-between items-center">
          <p className="font-bold text-lg">{name}</p>
          <p className="rounded-ms w-auto px-2 py-0.5 bg-main-opacity text-sm first-letter:uppercase">{type}</p>
        </div>
        <ExerciseInformation
          duration={duration}
          exerciseBreak={exerciseBreak}
          repetitions={repetitions}
          sets={sets}
          unit={unit}
        />
      </div>
    </div>
  );
};
