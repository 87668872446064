import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetContentQuery } from './onboardingAPI';
import { Button, LoadingOverlay } from 'common/components';
import { useNavigate } from 'react-router-dom';

export const MeetTheCoachPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = useGetContentQuery();

  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <>
      <h1 className="mb-6 md:mb-9">{data?.heading}</h1>
      <div className="md:flex flex-1 md:justify-between h-[calc(100%-68px)]">
        <img src={data?.image.url} className="md:w-1/2 rounded-lg object-cover mb-4 md:mb-0" />
        <div className="md:w-[45%]">
          <h1 className="mb-3">{data?.name}</h1>
          <div className="md:flex md:flex-col md:justify-between h-[calc(100%-44px)]">
            <div className="mb-8 bb-coach-info" dangerouslySetInnerHTML={{ __html: data?.text || '' }}></div>
            <Button title={t('onboarding.letsgo')} onClick={() => navigate('/onboarding')} responsive />
          </div>
        </div>
      </div>
    </>
  );
};
