import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocationItem } from '../components/LocationItem/LocationItem';
import { LocationItems } from './config';

export const Location: React.FunctionComponent<{ handleClick: (value: string) => void; selectedValue: string }> = ({
  handleClick,
  selectedValue,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="mb-6 md:mb-9">{t('onboarding.trainPlace')}</h1>
      <div className="flex flex-col md:flex-row justify-between h-[calc(100%-160px)]">
        {LocationItems.map((place) => (
          <LocationItem
            key={`${place.value}`}
            {...place}
            handleClick={(value: string) => handleClick(value)}
            isActive={selectedValue === place.value}
          />
        ))}
      </div>
    </>
  );
};
