import React from 'react';

interface ButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  responsive?: boolean;
  image?: string;
  smallSize?: boolean;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  title,
  onClick,
  disabled,
  responsive,
  image,
  smallSize,
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-red text-font-main rounded-md w-full font-bold scale-100 duration-200 hover:scale-105 disabled:bg-disabled disabled:text-font-disabled 
      ${responsive ? 'md:w-1/2' : ''}
      ${smallSize ? 'h-9' : 'h-12'}
      ${image ? 'flex items-center justify-center' : ''}`}
      disabled={disabled}
    >
      {title}
      {image && <img className="h-6 ml-1" src={image} />}
    </button>
  );
};
