import React from 'react';
import { Navigate } from 'react-router-dom';

import { authSelector } from 'features/auth/authSlice';
import { useAppSelector } from '../../hooks';
import { privateFallbackRoute, publicFallbackRoute, allowedPublicRoutes } from '../routes.config';
import { RouteTypes } from '../types';

export const PrivateRoute: React.FunctionComponent<{
  children?: any;
  type: RouteTypes;
  path: string;
}> = ({ children, type, path }) => {
  const { jwt, user } = useAppSelector(authSelector);
  if (allowedPublicRoutes.indexOf(path) > -1) return children;

  const { workout_program: workoutProgram } = user ?? { workout_program: null };

  if (jwt && (type === 'public' || type === 'fallback')) {
    if (user.isOnboarded && !!workoutProgram && user.startDate) return <Navigate to={privateFallbackRoute} />;
    else if (user.isOnboarded && !!workoutProgram && !user.startDate) return <Navigate to={'/set-start-date'} />;
    else if (user.isOnboarded && !workoutProgram) return <Navigate to={'/payments'} />;
    else return <Navigate to={'/the-coach'} />;
  } else if ((jwt && type === 'private') || (!jwt && type === 'public')) {
    return children;
  } else {
    return <Navigate to={publicFallbackRoute} />;
  }
};
