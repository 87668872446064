import { createSlice } from '@reduxjs/toolkit';
import { onboardingApi } from 'features/onboarding/onboardingAPI';
import { paymentsApi } from 'features/payments/paymentsAPI';
import { profileApi } from 'features/profile/profileAPI';
import { RootState } from '../../app/store/store';
import { authApi } from './authAPI';
import { AuthState } from './types';

const initialState: AuthState = {
  jwt: localStorage.getItem('jwt') || null,
  user: JSON.parse(localStorage.getItem('user') || '0') || null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetUser: (state) => {
      localStorage.removeItem('jwt');
      localStorage.removeItem('user');
      state.jwt = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        const { user, jwt } = payload;
        localStorage.setItem('jwt', jwt);
        localStorage.setItem('user', JSON.stringify(user));
        state.jwt = jwt;
        state.user = user;
      })
      .addMatcher(onboardingApi.endpoints.saveUserDetails.matchFulfilled, (state, { payload }) => {
        if (state.user) {
          const user = {
            ...state.user,
            isOnboarded: true,
            gender: payload.gender,
            weight: payload.weight,
            age: payload.age,
            height: payload.height,
            goal: payload.goal,
            bodyType: payload.bodyType,
            location: payload.location,
            activity: payload.activity,
          };
          localStorage.setItem('user', JSON.stringify(user));
          state.user = user;
        }
      })
      .addMatcher(onboardingApi.endpoints.setStartData.matchFulfilled, (state, { payload }) => {
        if (state.user) {
          const user = { ...state.user, startDate: payload.startDate };
          localStorage.setItem('user', JSON.stringify(user));
          state.user = user;
        }
      })
      .addMatcher(paymentsApi.endpoints.confirmPayment.matchFulfilled, (state, { payload }) => {
        if (state.user) {
          const user = {
            ...state.user,
            workout_program: payload.fullWorkoutProgram,
            nutrition_program: payload.fullNutritionProgram,
          };
          localStorage.removeItem('checkout-session');
          localStorage.setItem('user', JSON.stringify(user));
          state.user = user;
        }
      })
      .addMatcher(profileApi.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
        if (state.user) {
          const user = {
            ...state.user,
            email: payload.email,
            username: payload.username,
            firstName: payload.firstName,
            lastName: payload.lastName,
            deletionDate: payload.deletionDate,
          };
          localStorage.setItem('user', JSON.stringify(user));
          state.user = user;
        }
      });
  },
});

export const { resetUser } = authSlice.actions;
export const authSelector = (state: RootState) => state.authSlice;
