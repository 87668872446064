import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store/store';
import { progressApi } from './progressAPI';
import { Progresses } from './types';

const initialState: { progresses: Progresses } = {
  progresses: [],
};

export const progressSlice = createSlice({
  name: 'progresses',
  initialState,
  reducers: {
    resetProgresses: (state) => {
      state.progresses = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(progressApi.endpoints.getProgress.matchFulfilled, (state, { payload }) => {
        state.progresses = payload;
      })
      .addMatcher(progressApi.endpoints.createProgress.matchFulfilled, (state, { payload }) => {
        const progress = state.progresses;
        progress?.push(payload);
        state.progresses = progress;
      })
      .addMatcher(progressApi.endpoints.deleteProgress.matchFulfilled, (state, { payload }) => {
        const progresses = state.progresses;
        const newProgresses = progresses.filter((progress) => progress.id !== payload.id);
        state.progresses = newProgresses;
      });
  },
});

export const { resetProgresses } = progressSlice.actions;
export const progressesSelector = (state: RootState) => state.progressSlice;
