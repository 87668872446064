import React from 'react';

// import AuthVideo from 'assets/videos/auth-video.mp4';
import BackGroundLoginImg from 'assets/images/meet-the-coach-new.png';
import Logo from 'assets/Logo.png';

export const AuthLayout: React.FunctionComponent = ({ children }) => {
  return (
    <div className="min-w-screen min-h-screen ios-height">
      <img className="w-screen h-screen object-cover absolute top-0" src={BackGroundLoginImg}></img>
      <div className="absolute inset-0 z-10 bg-auth-overlay"></div>
      <div className="absolute inset-0 z-20 py-5 px-4 md:py-10 max-w-sm max-h-[800px] m-auto text-center">
        <img src={Logo} alt="#" className="block m-auto w-40 md:w-44 h-auto mb-3 md:mb-5" />
        {children}
      </div>
    </div>
  );
};
