import React from 'react';
import { t } from 'i18next';

import ScheduleIcon from 'assets/icons/Schedule.svg';
import SpeedIcon from 'assets/icons/Speed.svg';

export const MealInformation: React.FunctionComponent<{ duration: number; difficulty: string }> = ({
  duration,
  difficulty,
}) => {
  return (
    <>
      <div className="flex space-x-2 xl:space-x-4">
        {duration && (
          <div>
            <img className="inline h-4 mr-1" src={ScheduleIcon} />
            <span>
              {duration} {t('basics.minutes')}
            </span>
          </div>
        )}
        <div>
          <img className="inline h-4 mr-1" src={SpeedIcon} />
          <span className="inline-block first-letter:uppercase">{difficulty}</span>
        </div>
      </div>
    </>
  );
};
