import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from 'features/auth/types';

export const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      const { authSlice } = getState() as { authSlice: AuthState };
      if (authSlice.jwt) headers.set('Authorization', `Bearer ${authSlice.jwt}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createToken: builder.mutation<void, { token: string }>({
      query: (params) => ({
        method: 'POST',
        url: 'device-tokens',
        params,
      }),
    }),
  }),
});

export const { useCreateTokenMutation } = notificationApi;
