import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';

import { Button, ExerciseInformation } from 'common/components';
import { Exercises } from 'features/workout/types';

import ArrowBackIcon from 'assets/icons/ArrowBack.svg';
import PlayArrowIcon from 'assets/icons/PlayArrow.svg';
import Logo from 'assets/Logo-192x192.png';
import { replaceDataUrl } from '../../../offline-mode/queries/getData';
import { isPlatform } from '@ionic/core';

interface ExerciseElementProps {
  selectedExercise: Exercises;
  exerciseNumber: number;
  countOfExercises: number;
  activeSwipeIndex: number;
  close?: () => void;
  onClick: () => void;
}

export const ExerciseElement: React.FunctionComponent<ExerciseElementProps> = ({
  selectedExercise,
  exerciseNumber,
  countOfExercises,
  activeSwipeIndex,
  close,
  onClick,
}) => {
  const { id, duration, break: exerciseBreak, repetitions, sets, unit, exercise } = selectedExercise;
  const { name, video, explanation, equipment } = exercise;
  const [activateControls, setActivateControls] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [storedVideo, setVideo] = useState(video?.url || '');

  useEffect(() => {
    const getImageFromStorage = async (image: string) => {
      return replaceDataUrl(image);
    };
    if (video.url) {
      getImageFromStorage(video.url).then((url: string) => {
        console.log(url);
        setVideo(url);
      });
    }
  }, [video?.url]);

  useEffect(() => {
    if (isPlatform('mobile') && !isPlatform('mobileweb')) {
      window.screen.orientation.unlock();
      return () => {
        window.screen.orientation.lock('portrait');
      };
    }
  }, []);

  useEffect(() => {
    if (exerciseNumber - 1 === activeSwipeIndex || !(isPlatform('ios') || isPlatform('android'))) {
      setIsLoading(true);
      videoRef?.current?.load();
      const handlePlayThrough = () => {
        setIsLoading(false);
        setActivateControls(true);
      };
      return videoRef?.current?.addEventListener('canplaythrough', handlePlayThrough, false);
      return () => videoRef?.current?.removeEventListener('canplaythrough', handlePlayThrough);
    }
  }, [activeSwipeIndex, storedVideo]);

  return (
    <>
      <div className="text-center relative mb-8 xl:hidden">
        <div className="absolute flex items-center h-6" onClick={() => (close ? close() : null)}>
          <img className="w-4 h-auto mr-1" src={ArrowBackIcon} />
          <span>{t('basics.back')}</span>
        </div>
        <h4>{t('workout.todaysWorkout')}</h4>
      </div>
      <div className={'relative'}>
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center bg-loading z-[80]">
            <div className="animate-spin rounded-full h-16 w-16 border-4 border-b-red border-r-red border-t-red border-l-transparent"></div>
          </div>
        )}
        <video
          ref={videoRef}
          preload="none"
          controls={activateControls}
          key={id}
          className="w-full rounded-lg object-cover h-[220px] xl:h-[300px] mb-4"
          poster={Logo}
        >
          <source src={storedVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="mb-2 flex justify-between items-center">
        <h3>{name}</h3>
      </div>
      <ExerciseInformation
        duration={duration}
        exerciseBreak={exerciseBreak}
        repetitions={repetitions}
        sets={sets}
        unit={unit}
      />
      <div className="mt-4 pb-[96px]">
        <div className="">
          {explanation && <div className="bb-inner-html" dangerouslySetInnerHTML={{ __html: explanation }} />}
          {equipment && <div className="bb-inner-html" dangerouslySetInnerHTML={{ __html: equipment }} />}
        </div>
        <div className="w-8/12 xl:w-1/2 mt-10 mx-auto">
          {duration && <Button title={t('workout.beginExercise')} onClick={onClick} image={PlayArrowIcon} />}
          <div className="text-center mt-4">
            <p className="text-sm">{t('workout.exercise')}</p>
            <p className="text-2xl font-bold">
              {exerciseNumber}/{countOfExercises}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
