import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { PushNotifications, Token } from '@capacitor/push-notifications';

import { Button } from 'common/components';
import { useCreateTokenMutation } from './notificationAPI';

import NotificationIcon from 'assets/icons/Notifications.svg';

export const Notification: React.FunctionComponent<{ handleHide: () => void }> = ({ handleHide }) => {
  const [sendToken] = useCreateTokenMutation();

  const [token, setToken] = useState('');

  const getPermission = async () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();
    PushNotifications.requestPermissions();

    handleHide();
  };

  PushNotifications.addListener('registration', (token: Token) => {
    setToken(token.value);
  });

  useEffect(() => {
    if (token) sendToken({ token });
  }, [token]);

  return (
    <div className="fixed inset-0 flex flex-col bg-main p-6 z-40">
      <h1 className="mt-1">{t('onboarding.notifications')}</h1>
      <img className="opacity-40 mt-20 w-[128px] mx-auto" src={NotificationIcon} />
      <p className="text-lg font-bold w-full text-center mt-4">{t('onboarding.keepMeNotified')}</p>
      <div className="flex flex-1 flex-col justify-between h-full mt-8">
        <p className="text-base text-center">{t('onboarding.notificationMsg')}</p>
        <div>
          <Button title={t('onboarding.turnOn')} onClick={getPermission} />
          <button className="font-bold text-center w-full py-3 mt-4" onClick={handleHide}>
            {t('onboarding.notNow')}
          </button>
        </div>
      </div>
    </div>
  );
};
