import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import { CalendarContext } from 'App';

import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';
import {
  Button,
  CalendarBuild,
  ErrorMsg,
  Header,
  LoadingOverlay,
  ProgramProgress,
  SuccessMsg,
} from 'common/components';
import { WeekExercises } from './components/WeekExercises/WeekExercises';
import { Training } from './components/Training/Training';
import { Exercise } from './components/Exercise/Exercise';
import { workoutSelector } from './workoutSlice';
import { authSelector } from 'features/auth/authSlice';
import { useSetStartDataMutation } from 'features/onboarding/onboardingAPI';
import { Exercises, WorkoutDay } from './types';

import ArrowForward from 'assets/icons/ArrowForward.svg';
import WorkoutIcon from 'assets/icons/Workout.svg';
import { replaceDataUrl } from '../offline-mode/queries/getData';

export const WorkoutPage = () => {
  const { showCalendar } = useContext(CalendarContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { platform } = useCheckPlatform();
  const { user } = useAppSelector(authSelector);
  const { workoutProgram, selectedDate } = useAppSelector(workoutSelector);
  const [setStartDay, { isLoading, isSuccess, isError, reset }] = useSetStartDataMutation();
  const [workoutImage, setWorkoutImage] = useState(workoutProgram?.image?.url || '');
  useEffect(() => {
    setSelectedWorkout(null);
  }, [selectedDate]);
  useEffect(() => {
    const getImageFromStorage = async (image: string) => {
      return replaceDataUrl(image);
    };
    if (workoutProgram?.image?.url) {
      getImageFromStorage(workoutProgram?.image?.url).then((url: string) => {
        setWorkoutImage(url);
      });
    }
  }, [workoutProgram?.image?.url]);

  const [selectedWorkout, setSelectedWorkout] = useState<Exercises | null>(null);
  const [startDate, setStartDate] = useState('');

  const today =
    Math.floor((new Date().getTime() - new Date(user.startDate).setHours(0, 0, 0, 0)) / (1000 * 3600 * 24)) + 1;
  const currentDay =
    Math.floor(
      (new Date(selectedDate).getTime() - new Date(user.startDate).setHours(0, 0, 0, 0)) / (1000 * 3600 * 24)
    ) + 1;
  const workoutForTheDay: WorkoutDay = workoutProgram?.days?.find((workout: WorkoutDay) => workout.day === currentDay);

  const sendStartDay = async () => {
    await setStartDay({ date: new Date(startDate).getTime() });
  };

  useEffect(() => {
    if (isError || isSuccess) setTimeout(() => reset(), 5000);
  }, [isSuccess, isError]);

  return platform === 'non-desktop' && selectedWorkout ? (
    <Exercise
      allExercises={workoutForTheDay.exercises}
      selectedExercise={selectedWorkout}
      close={() => setSelectedWorkout(null)}
      changeSelectedWorkout={(workout: Exercises) => setSelectedWorkout(workout)}
    />
  ) : (
    <>
      {isLoading && <LoadingOverlay />}
      {isSuccess && <SuccessMsg text={t('success.dateChanges')} />}
      {isError && <ErrorMsg text={t('errors.wentWrong')} />}
      <Header title={t('workout.title')} infoType="workout" />
      <div className={`${currentDay > 0 ? '' : 'hidden'} flex xl:w-[calc(50%-15px)] mb-4 justify-between items-center`}>
        <h3>{t('nutrition.subtitle')}</h3>
        <span className="flex justify-between text-sm cursor-pointer" onClick={() => navigate('/progress')}>
          {t('workout.overview')} <img className="w-4 h-4 ml-1" src={ArrowForward} />
        </span>
      </div>
      <div className="flex flex-col xl:flex-row xl:justify-between w-full min-h-[calc(100%-100px)]">
        <div className="xl:w-[calc(50%-15px)]">
          <div className="flex flex-col-reverse xl:flex-col">
            <div className={`${currentDay > 0 ? '' : 'hidden xl:block'}`}>
              <ProgramProgress />
            </div>
            <WeekExercises days={workoutProgram?.days} startDate={user.startDate} currentDay={currentDay} />
          </div>
          <div className={`mb-6 ${currentDay > 0 ? '' : 'hidden xl:block'}`}>
            <h3 className="mb-3">{t('workout.todaysWorkout')}</h3>
            <p className="text-font-secondary">
              {workoutForTheDay
                ? t('workout.exercisesCount', { count: workoutForTheDay.exercises?.length })
                : t('workout.noWorkout')}
            </p>
          </div>

          <div className={`space-y-4 pb-[96px] xl:pb-12 md:space-y-6 ${currentDay > 0 ? '' : 'hidden xl:block'}`}>
            {workoutForTheDay?.exercises?.map((exercise) => (
              <Training
                key={`exercise-${exercise.id}`}
                exercises={exercise}
                onClick={() => setSelectedWorkout(exercise)}
                isActive={exercise.id === selectedWorkout?.id && platform === 'desktop'}
              />
            ))}
          </div>
        </div>
        {showCalendar ? (
          <div className="xl:w-[calc(50%-15px)]">
            <CalendarBuild />
          </div>
        ) : (
          <div className={`${currentDay > 0 ? 'hidden xl:block' : ''} xl:w-[calc(50%-15px)]`}>
            {currentDay > 0 || today > 0 ? (
              selectedWorkout && workoutForTheDay ? (
                platform === 'desktop' && (
                  <Exercise allExercises={workoutForTheDay.exercises} selectedExercise={selectedWorkout} />
                )
              ) : (
                <div className="hidden xl:flex h-[calc(100vh-152px)] flex-col items-center justify-center">
                  <p className="text-[32px] leading-[32px] font-bold text-gray mb-8">{t('workout.selectExercise')}</p>
                  <img className="h-[196px] mb-8" src={WorkoutIcon} />
                  <p className="w-[60%] text-gray text-center">{t('workout.pleaseSelectExercise')}</p>
                </div>
              )
            ) : (
              <div className="pb-8 xl:pb-12 min-h-[calc(100vh-244px)] xl:h-auto">
                <img
                  className="rounded-lg mb-6 xl:mb-9 max-h-[220px] md:max-h-[260px] w-full object-cover"
                  src={workoutImage}
                />
                <div className="text-center flex flex-col justify-between h-[calc(100%-325px)]">
                  <div>
                    <p className="text-[52px] xl:text-[56px] font-bold mb-4 leading-[62px]">
                      {t('workout.days', { days: Math.abs(today - 1) })}
                    </p>
                    <p>{t('workout.untilBegin')}</p>
                  </div>
                  <div className="xl:mt-8">
                    <p className="mb-3 text-left">{t('workout.changeStartDate')}</p>
                    <input
                      type="date"
                      min={new Date().toISOString().split('T')[0]}
                      className="focus:outline-none appearance-none rounded-md h-12 px-4 mb-3 text-sm text-gray-base w-full "
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="text-center mt-8 flex">
                  <button className="w-full xl:w-1/2 mr-4" onClick={() => navigate('/nutrition')}>
                    {t('workout.seeNutrition')}
                  </button>
                  <Button title={t('workout.saveChanges')} onClick={sendStartDay} responsive />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
