export default {
  translation: {
    navigation: {
      workout: 'Workout',
      nutrition: 'Nutrition',
      progress: 'Progress',
      tips: 'Tips',
      profile: 'Profile',
    },
    register: {
      title: 'Register',
      registerButton: 'Register your account',
      alreadyHaveAnAccount: 'Already have an account?',
      loginHere: 'Login here',
      subscribe: 'Subscribe for Newsletter',
      readAndAccept: 'I have read and accepted the',
      afterReadAndAccept: '',
      emailTaken: 'Email is already taken'
    },
    registerConfirmation: {
      title: 'Thanks for registering',
      emailSent: 'A confirmation email has been sent',
      msg: 'Please go to the email address that you provided for the registration ({{email}}) and click the activation link to complete the registration process!',
    },
    emailConfirmation: {
      title: 'Email confirmation',
      thanks: 'Thanks for confirming your email',
      msg: 'You can now see your program and begin your journey with Urbanathletix',
    },
    login: {
      title: 'Login',
      forgotPasswordLink: 'Forgotten password?',
      dontHaveAccaount: "Don't have an account?",
      registerHere: 'Register here',
    },
    forgotPassword: {
      title: 'Forgot Password',
      backToLoginButton: 'Back to login',
      send: 'Send',
    },
    resetPassword: {
      title: 'Reset your password',
      button: 'Continue',
    },
    onboarding: {
      explanation:
        'Before we begin we need additional information from you. Based on that we can create an individual workout and nutrition plan.',
      letsgo: "Let's go",
      setupProfile: 'Set up your profile',
      selectYourGender: 'Select your gender',
      selectGender: 'Select gender',
      male: 'Male',
      female: 'Female',
      selectAge: 'Select your age',
      enterYourAge: 'Enter yout age',
      enterHeight: 'Enter your height',
      enterWeight: 'Enter your weight',
      // enterHeight: 'Enter your height (cm)',
      // enterWeight: 'Enter your weight (kg)',
      physicalActivity: 'Your physical activity level',
      bodyType: 'Select your body type',
      goal: 'Set your goal',
      trainPlace: 'Where will you train?',
      getStarted: 'Get started',
      beginJourney: 'Begin your journey',
      programDescription:
        "This program is made for you! You choose when to start the 11 week program according to your preferences and free time. You'll receive access to the 1st Phase of the nutrition regime, so you can prepare for training",
      selectDate: 'Select start date',
      notifications: 'Notifications',
      keepMeNotified: 'Keep me notified',
      notificationMsg: 'Stay up to date with our notifications about phase progress, tips and more!',
      turnOn: 'Turn on',
      notNow: 'Not now',
    },
    payments: {
      title: 'Ready to start?',
      payment: 'Payment',
      getAccess: 'Get access for {{amount}}€',
      with: 'with',
    },
    workout: {
      title: 'My coach',
      overview: 'Overview',
      days: '{{days}} Days',
      untilBegin: 'Until your program begins',
      todaysWorkout: "Today's Workout",
      seeNutrition: 'See nutrition',
      noWorkout: 'No workout today',
      exercisesCount: "You have {{count}} exercises for today's workout. Your transformation awaits you!",
      sets: 'Sets',
      repetitions: 'Repetitions',
      selectExercise: 'Select exercise',
      pleaseSelectExercise:
        'Please select an exercise from the list on the left and start your daily training right away!',
      beginExercise: 'Begin exercise',
      exercise: 'Exercise',
      timerRunsOut: '{{name}} while the timer runs out',
      information: 'Workout information',
      changeStartDate: 'You can still change the start date of your program before the countdown runs out',
      saveChanges: 'Save changes',
      backToToday: 'Back to Today',
    },
    nutrition: {
      title: 'My Nutrition',
      subtitle: 'Phase progress',
      phase: 'Phase',
      overall: 'Overall',
      daysLeftInPhase: 'days left in this phase',
      daysLeftInProgram: 'days left in the program',
      trackingStartsOn: 'Tracking starts on',
      programStartsOn: 'Program starts on',
      selectRecipe: 'Select recipe',
      pleaseSelectRecipe:
        "Please select a recipe from the list on the left and start cooking what's best for you in this phase of the program",
      breakfast: 'Frühstück',
      lunch: 'Mittagessen',
      dinner: 'Abendessen',
      snacks: 'Snacks',
      ingredients: 'Zutaten',
      directions: 'Zubereitung',
      nextPhase: 'Nächste Phase',
      currentPhase: 'Current Phase',
      phaseCompleted: 'Phase Completed',
      programCompleted: 'Program Completed',
      information: 'Nutrition information',
    },
    progress: {
      title: 'My Progress',
      phases: 'Phases',
      placeholder: 'Enter weight in kilograms (e.g. 78.5)',
      addProgressEntry: 'Add progress entry',
      addProgressDescription:
        'Here you can add a progress entry for Phase {{currentPhase}}. Simply enter your weight and add a photo so you can easily track your progress going through the program.',
      addEntry: 'Add entry',
      addPhotoOptional: 'Add Photo (Optional)',
      addPhoto: 'Add Photo',
      browseFiles: 'Browse your files or drag and drop an image here',
      phaseStartsOn: 'Phase {{phaseNumber}} unlocks on {{date}}',
      weightAndPhoto: 'Weight and photo',
      setWeight: 'Set Weight',
      noPhoto: 'No photo',
      noInternet: 'No internet connection',
      noInternetMsg:
        "Adding progress entries will be available once you've established an internet connection again. Please check your internet or Wi-fi.",
      deleteEntry: 'Delete Entry',
      gallery: 'Gallery',
      yourGallery: 'Your gallery',
      uploadProgress: 'Gallery is empty. Upload some photos so you can check them here.',
      congratulations: 'Congratulations!',
      startWeight: 'Start weight',
      endWeight: 'End weight',
    },
    tips: {
      title: 'My Tips',
      tips: 'Tips',
      latest: 'Latest',
      older: 'Older tips',
      noOlderTips: 'There are no older tips, this is the first one',
    },
    profile: {
      title: 'User Profile',
      name: 'Name',
      physicalInfo: 'Your physical information',
      downloadData: 'Download data for offline mode',
      resetPassword: 'Reset Password',
      logout: 'Log out',
      offlineMode: 'Offline mode',
      spaceNeeded: 'Space needed for downloading data',
      dataDownloaded: 'You have already downloaded data for offline mode',
      downloadedOnDevice: 'Downloaded on your device',
      buttonDownload: 'Download data',
      buttonDelete: 'Delete data',
      deletingPopupTitle: 'Warning deleting all your data',
      deletingPopupMessage: 'Are you sure you want to delete all your data',
      deletingDataButton: 'Request deletion of all user data',
      deletingDate: 'Deleting Date {{deletionDate}}',
      wifiRecommended:
        'The download can take up to multiple minutes. We recommend to download while you are connected to a wifi.',
    },
    additional: {
      newVersion: 'New version available',
      newVersionContent:
        'Hey, we have a new version of Urbanathletix available for you. You can download it now to have the most up to date experience! Keep training! The download can take up to multiple minutes. We recommend to download while you are connected to a wifi.',
      loadingData: 'Loading your data',
      pleaseWait: 'Please wait while we fetch your data',
      newPhase:
        'You have reached Phase {{phase}}! Enter your weight and photo (optional) to track your progress and proceed to the exercises in Phase {{phase}}!',
      rateAndFeedback: 'Rating and feedback',
      feedbackDescription:
        "We'd like to hear from you what do you think about the service we're providing! You can rate us on the App Store or give us a feedback on how we can improve!",
      rateUs: 'Rate us',
      feedback: 'Give Feedback',
    },
    basics: {
      back: 'Back',
      save: 'Save',
      update: 'Update',
      dismiss: 'Dismiss',
      email: 'E-mail Address',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      firstName: 'First Name',
      lastName: 'Last Name',
      terms: 'Terms of Service',
      policy: 'Data Privacy Policy',
      and: 'and',
      impressum: 'Impressum',
      gender: 'Gender',
      height: 'Height',
      age: 'Age',
      weight: 'Initial Weight',
      minutes: 'Minutes',
      calendar: 'Calendar',
      delete: 'Delete',
      easy: 'easy',
      medium: 'medium',
      hard: 'hard',
    },
    notifications: {},
    success: {
      emailSent: 'An email has been sent',
      downloadCompleted: 'Download completed successfully',
      deleteCompleted: 'Delete data completed successfully',
      deleteEntry: 'Progress entry was deleted successfully',
      dateChanges: 'Start date was changed successfully',
    },
    errors: {
      invalidUser: 'Invalid identifier or password',
      firstNameRequire: 'Please fill in your first name',
      lastNameRequire: 'Please fill in your last name',
      emailRequired: 'E-mail is required',
      emailInvalid: 'Not a valid email address',
      passwordRequired: 'Password is required',
      passwordCharacters: 'Password requires 8 characters',
      passwordConfirmationRequired: 'Please confirm your password',
      passwordConfirmationMatch: 'Passwords do not match',
      termsRequired: 'The terms and conditions must be accepted',
      wentWrong: 'Something went wrong. Please try again',
    },
  },
};
