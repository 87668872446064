import React, { ChangeEvent, FocusEvent, HTMLInputTypeAttribute } from 'react';

interface InputProps {
  value: string | number;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  name: string;
  type: HTMLInputTypeAttribute;
  autoComplete: string;
  error: string | undefined;
  touched: boolean | undefined;
  halfWidth?: boolean;
  editMode?: boolean;
}

export const Input: React.FunctionComponent<InputProps> = ({
  value,
  onBlur,
  onChange,
  placeholder,
  name,
  type,
  autoComplete,
  error,
  touched,
  halfWidth,
  editMode,
}) => {
  return (
    <input
      autoComplete={autoComplete}
      type={type}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      className={`focus:outline-none rounded-md h-12 px-4 mb-3 text-sm
      ${error && touched ? 'text-error border border-error' : 'text-gray-base'}
      ${halfWidth ? 'w-[48%]' : 'w-full'}
      ${editMode ? 'bg-pen bg-no-repeat bg-right-3 pr-12' : ''}`}
    />
  );
};
