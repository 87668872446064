import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';

import { Button, ModalsBackground } from 'common/components';
import { authSelector } from 'features/auth/authSlice';
import { PhasesProps } from 'features/nutrition/types';
import { nutritionSelector } from 'features/nutrition/nutritionSlice';

import CloseIcon from 'assets/icons/Close.svg';

export const NewPhaseModal = () => {
  const navigate = useNavigate();
  const { user } = useAppSelector(authSelector);
  const { nutritionProgram } = useAppSelector(nutritionSelector);

  const [showPopup, setShowPopup] = useState(false);

  const currentDay = Math.floor((new Date().getTime() - new Date(user.startDate).getTime()) / (1000 * 3600 * 24)) + 1;
  const currentPhase: PhasesProps = nutritionProgram?.phases?.find(
    (phase: PhasesProps) => phase.phase.fromDay <= currentDay && phase.phase.untilDay >= currentDay
  );

  const isAlreadyShown = !!localStorage.getItem('shown');

  const addProgress = () => {
    navigate('/progress');
    setShowPopup(false);
    localStorage.setItem('shown', 'true');
  };

  const closeModal = () => {
    setShowPopup(false);
    localStorage.setItem('shown', 'true');
  };

  useEffect(() => {
    if (currentPhase?.phase.fromDay === currentDay && currentPhase?.phaseNumber !== 1 && !isAlreadyShown) {
      setShowPopup(true);
    }

    if (currentPhase?.phase.fromDay !== currentDay && isAlreadyShown) {
      localStorage.removeItem('shown');
    }
  }, [currentPhase]);

  return showPopup ? (
    <ModalsBackground>
      <img src={CloseIcon} className="absolute right-2 w-8 cursor-pointer" onClick={closeModal} />
      <div>
        <h1 className="mb-4">{t('progress.congratulations')}</h1>
        <p>{t('additional.newPhase', { phase: currentPhase?.phaseNumber })}</p>
      </div>
      <Button title={t('progress.addProgressEntry')} onClick={addProgress} />
    </ModalsBackground>
  ) : null;
};
