import { useEffect, useState } from 'react';

export const useCheckPlatform = () => {
  const [platform, setPlatform] = useState<'desktop' | 'non-desktop'>(
    window.innerWidth >= 1280 ? 'desktop' : 'non-desktop'
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) setPlatform('desktop');
      else setPlatform('non-desktop');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { platform };
};
