import WorkoutIcon from 'assets/icons/Workout.svg';
import NutritionIcon from 'assets/icons/Nutrition.svg';
import ProgressIcon from 'assets/icons/Progress.svg';
import TipsIcon from 'assets/icons/Tips.svg';
import ProfileIcon from 'assets/icons/Profile.svg';

import WorkoutActiveIcon from 'assets/icons/Workout-active.svg';
import NutritionActiveIcon from 'assets/icons/Nutrition-active.svg';
import ProgressActiveIcon from 'assets/icons/Progress-active.svg';
import TipsActiveIcon from 'assets/icons/Tips-active.svg';
import ProfileActiveIcon from 'assets/icons/Profile-active.svg';

export const NavigationItems = [
  {
    title: 'navigation.workout',
    link: '/workout',
    image: WorkoutIcon,
    imageActive: WorkoutActiveIcon,
  },
  {
    title: 'navigation.nutrition',
    link: '/nutrition',
    image: NutritionIcon,
    imageActive: NutritionActiveIcon,
  },
  {
    title: 'navigation.progress',
    link: '/progress',
    image: ProgressIcon,
    imageActive: ProgressActiveIcon,
  },
  {
    title: 'navigation.tips',
    link: '/tips',
    image: TipsIcon,
    imageActive: TipsActiveIcon,
  },
  {
    title: 'navigation.profile',
    link: '/profile',
    image: ProfileIcon,
    imageActive: ProfileActiveIcon,
  },
];
