import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ForgotPasswordBody, LoginBody, LoginResponse, RegisterBody, ResetPasswordBody } from './types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/auth/` }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginBody>({
      query: ({ email, password }: LoginBody) => ({
        url: 'local',
        method: 'POST',
        body: {
          identifier: email,
          password,
        },
      }),
    }),
    register: builder.mutation<void, RegisterBody>({
      query: ({ firstName, lastName, isSubscribed, email, password }: RegisterBody) => ({
        url: 'local/register',
        method: 'POST',
        body: {
          firstName,
          lastName,
          isSubscribed,
          username: email,
          email,
          password,
        },
      }),
    }),
    forgotPassword: builder.mutation<void, ForgotPasswordBody>({
      query: ({ email }: ForgotPasswordBody) => ({
        url: 'forgot-password',
        method: 'POST',
        body: { email },
      }),
    }),
    resetPassword: builder.mutation<void, ResetPasswordBody>({
      query: ({ code, password, passwordConfirmation }: ResetPasswordBody) => ({
        url: 'reset-password',
        method: 'POST',
        body: {
          code,
          password,
          passwordConfirmation,
        },
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useForgotPasswordMutation, useResetPasswordMutation } = authApi;
