import React from 'react';

import SuccessIcon from 'assets/icons/Success.svg';

export const SuccessMsg: React.FunctionComponent<{ text: string; blocked?: boolean }> = ({ text, blocked }) => {
  return (
    <div
      className={`${
        blocked ? 'mb-6' : 'fixed top-12 left-[50%] translate-x-[-50%]'
      } flex rounded-ms bg-white text-left text-success w-auto px-3 py-1 z-[30]`}
    >
      <img src={SuccessIcon} className="mr-2" />
      {text}
    </div>
  );
};
