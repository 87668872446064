import React from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';

import { PrivateRoute } from './components/PrivateRoute';
import { routeConfig } from './routes.config';

export const Routes: React.FunctionComponent = () => {
  const navigate = useNavigate();

  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(-1, () => {
      navigate(-1);
    });
  });

  return useRoutes(
    routeConfig.map((route) => ({
      path: route.path,
      element: (
        <PrivateRoute type={route.type} path={route.path}>
          {route.layout ? <route.layout>{route.element}</route.layout> : route.element}
        </PrivateRoute>
      ),
      children: route.nestedRoutes,
    }))
  );
};
