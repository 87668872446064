import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isPlatform } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import * as Yup from 'yup';

import { CalendarContext } from 'App';
import { useAppSelector } from 'app/hooks';
import { useUpdateUserMutation } from './profileAPI';
import { authSelector, resetUser } from 'features/auth/authSlice';
import { useForgotPasswordMutation } from 'features/auth/authAPI';
import { ErrorMsg, Header, Input, LoadingOverlay, ProgramProgress, SuccessMsg } from 'common/components';
import { CalendarBuild } from 'common/components/Calendar/CalendarBuild';
import { DeletePopup } from './components/DeletePopup';
import { deleteData } from 'features/offline-mode/queries/deleteData';

import DownloadIcon from 'assets/icons/Download.svg';

export const ProfilePage = () => {
  const { showCalendar } = useContext(CalendarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAppSelector(authSelector);
  const { t } = useTranslation();
  const [send, { isLoading, isSuccess, isError, reset }] = useForgotPasswordMutation();
  const [updateUser, { isLoading: updateUserLoad }] = useUpdateUserMutation();

  const EmailValidation = Yup.object({
    email: Yup.string().email(),
  });

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const deletionDate = new Date();
  deletionDate.setDate(deletionDate.getDate() + 30);

  const resetPassword = async () => {
    await send({ email: user.email });
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const saveNewData = async () => {
    await updateUser({
      email: email && EmailValidation.isValidSync({ email }) ? email : user.email,
      username: email && EmailValidation.isValidSync({ email }) ? email : user.email,
      firstName: firstName ? firstName : user.firstName,
      lastName: lastName ? lastName : user.lastName,
      isSubscribed: user.isSubscribed,
    });

    setEmail('');
    setFirstName('');
    setLastName('');
    setEmailTouched(false);
  };

  const handleLogout = async () => {
    dispatch(resetUser());
    if (isPlatform('mobile') && !isPlatform('mobileweb')) await deleteData();
  };

  useEffect(() => {
    if (isError || isSuccess) setTimeout(() => reset(), 5000);
  }, [isError, isSuccess]);

  return (
    <>
      {(isLoading || updateUserLoad) && <LoadingOverlay />}
      {isSuccess && <SuccessMsg text={t('success.emailSent')} />}
      {isError && <ErrorMsg text={t('errors.wentWrong')} />}
      {showPopup && <DeletePopup handleClose={() => setShowPopup(false)} />}
      {!EmailValidation.isValidSync({ email }) && emailTouched && <ErrorMsg text={t('errors.emailInvalid')} />}
      <Header title={t('profile.title')} />
      <div className="flex flex-col xl:flex-row xl:justify-between w-full min-h-[calc(100%-100px)]">
        <div className="xl:w-[calc(50%-15px)] h-[calc(100%-56px)]">
          <ProgramProgress />
          <div className="flex flex-col pb-[100px] xl:pb-12 justify-between h-[calc(100%-140px)] max-h-[540px]">
            <div>
              <div className="mb-3">
                <div className="flex justify-between">
                  <p className="font-bold mb-2">{t('profile.name')}</p>
                  {(firstName || lastName) && (
                    <p className="text-font-secondary cursor-pointer" onClick={saveNewData}>
                      {t('basics.save')}
                    </p>
                  )}
                </div>
                <div className="flex justify-between">
                  <Input
                    autoComplete="off"
                    type="text"
                    name="firstName"
                    onChange={(e) => setFirstName(e.target.value)}
                    onBlur={() => null}
                    placeholder={user.firstName}
                    value={firstName}
                    error={''}
                    touched={false}
                    halfWidth
                    editMode
                  />
                  <Input
                    autoComplete="off"
                    type="text"
                    name="lastName"
                    onChange={(e) => setLastName(e.target.value)}
                    onBlur={() => null}
                    placeholder={user.lastName}
                    value={lastName}
                    error={''}
                    touched={false}
                    halfWidth
                    editMode
                  />
                </div>
              </div>
              <div className="mb-3 md:mb-4">
                <div className="flex justify-between">
                  <p className="font-bold mb-2">{t('basics.email')}</p>
                  {email && (
                    <p
                      className="text-font-secondary cursor-pointer"
                      onClick={EmailValidation.isValidSync({ email }) ? saveNewData : () => null}
                    >
                      {t('basics.save')}
                    </p>
                  )}
                </div>
                <Input
                  autoComplete="off"
                  type="email"
                  name="email"
                  placeholder={user.email}
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={() => setEmailTouched(true)}
                  error={''}
                  touched={false}
                  editMode
                />
              </div>
              <div className="mb-8">
                <p className="font-bold mb-4">{t('profile.physicalInfo')}</p>
                <div className="flex">
                  <div className="w-1/2">
                    <p className="mb-4">
                      {t('basics.gender')}:{' '}
                      <span className="inline-block font-bold first-letter:uppercase">
                        {t(`onboarding.${user.gender}`)}
                      </span>
                    </p>
                    <p>
                      {t('basics.height')}: <span className="font-bold">{user.height} cm</span>
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p className="mb-4">
                      {t('basics.age')}: <span className="font-bold">{user.age}</span>
                    </p>
                    <p>
                      {t('basics.weight')}: <span className="font-bold">{user.weight} kg</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-center">
              {isPlatform('mobile') && !isPlatform('mobileweb') && (
                <a
                  className="flex items-center justify-center text-lg font-bold mb-4"
                  onClick={() => navigate('offline-mode')}
                >
                  <img src={DownloadIcon} />
                  {t('profile.downloadData')}
                </a>
              )}
              <a className="block text-lg font-bold mb-4 cursor-pointer" onClick={resetPassword}>
                {t('profile.resetPassword')}
              </a>
              {user.deletionDate ? (
                <p className="text-lg font-bold mb-4">
                  {' '}
                  {t('profile.deletingDate', { deletionDate: format(deletionDate, 'dd MMMM') })}
                </p>
              ) : (
                <a className="block text-lg font-bold mb-4 cursor-pointer" onClick={() => setShowPopup(true)}>
                  {t('profile.deletingDataButton')}
                </a>
              )}
              <a className="block text-lg font-bold text-main-red cursor-pointer" onClick={handleLogout}>
                {t('profile.logout')}
              </a>
            </div>
          </div>
        </div>
        {showCalendar ? (
          <div className="xl:w-[calc(50%-15px)]">
            <CalendarBuild />
          </div>
        ) : null}
      </div>
    </>
  );
};
