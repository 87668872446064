import React from 'react';

export const Impressum = () => {
  return <div className="bb-privacy-policy-formatting">
    <h1>Impressum</h1>
    <div className="bb-legal-formatting">
      <p>
        <span>Angaben gemäß § 5 TMG</span>
        <span><strong>Urbanathletix GmbH</strong></span>
        <span>Kurfürstendamm 11</span>
        <span>10719 Berlin</span>
        <span>Vertreten durch: Kenney Sey</span>
      </p>
    </div>
    <div className="bb-legal-formatting">
      <h3>Kontakt</h3>
      <p>E-Mail: <a href="mailto: kontakt@urbanathletix.de" className="underline">kontakt@urbanathletix.de</a></p>
    </div>
    <div className="bb-legal-formatting">
      <h3>Umsatzsteuer-ID</h3>
      <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE354710087.</p>
    </div>
    <div className="bb-legal-formatting">
      <h3>EU-Streitschlichtung</h3>
      <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:</p>
      <p><a href="https://ec.europa.eu/consumers/odr/" className="underline">https://ec.europa.eu/consumers/odr/</a>.</p>
      <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
    </div>
    <div className="bb-legal-formatting">
      <p><strong>Verbraucherstreitbeilegung/Universalschlichtungsstelle</strong></p>
      <p>Wir sind nicht verpflichtet, an Streitbeilegungsverfahren vor einer</p>
      <p>Verbraucherschlichtungsstelle teilzunehmen.</p>
    </div>
  </div>;
};
