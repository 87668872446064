import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import { CalendarContext } from 'App';

import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';
import { MealTime } from './components/MealTime/MealTime';
import { MealElement } from './components/MealElement/MealElement';
import { MealRecipe } from './components/MealRecipe/MealRecipe';
import { CalendarBuild, Header, ProgramProgress } from 'common/components';
import { nutritionSelector } from './nutritionSlice';
import { authSelector } from 'features/auth/authSlice';
import { Meal, PhasesProps } from './types';

import NutritionIcon from 'assets/icons/Nutrition.svg';
import { workoutSelector } from '../workout/workoutSlice';

export enum MealTimes {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  DINNER = 'dinner',
  SNACKS = 'snacks',
}

export const NutritionPage = () => {
  const { showCalendar } = useContext(CalendarContext);
  const { t } = useTranslation();
  const { platform } = useCheckPlatform();
  const { user } = useAppSelector(authSelector);
  const { nutritionProgram } = useAppSelector(nutritionSelector);
  const { selectedDate } = useAppSelector(workoutSelector);

  const [mealTime, setMealTime] = useState<MealTimes>(MealTimes.BREAKFAST);
  const [selectedMeal, setSelectedMeal] = useState<Meal | null>(null);
  const [showNextPhase, setShowNextPhase] = useState(false);

  const currentDay = Math.floor((selectedDate - new Date(user.startDate).getTime()) / (1000 * 3600 * 24)) + 1;
  const currentPhase: PhasesProps =
    currentDay > 0
      ? nutritionProgram?.phases.find(
          (phase: PhasesProps) => phase.phase.fromDay <= currentDay && phase.phase.untilDay >= currentDay
        )
      : nutritionProgram?.phases[0];

  const nextPhase = nutritionProgram?.phases.find(
    (phase: PhasesProps) => currentPhase?.phaseNumber + 1 === phase.phaseNumber
  );

  const meals = showNextPhase ? nextPhase?.nutritionPlan[mealTime] : currentPhase?.nutritionPlan[mealTime];

  return platform === 'non-desktop' && selectedMeal ? (
    <MealRecipe mealTime={mealTime} selectedMeal={selectedMeal} close={() => setSelectedMeal(null)} />
  ) : (
    <div>
      <Header title={t('nutrition.title')} infoType="nutrition" />
      <div className="xl:w-[calc(50%-15px)] mb-4 flex justify-between items-center">
        <h3>{t('nutrition.subtitle')}</h3>
        {currentPhase?.phase?.untilDay - currentDay <= 5 && (
          <span
            className="text-sm font-bold bg-main-red py-1 px-3 rounded-[4px] cursor-pointer"
            onClick={() => setShowNextPhase(!showNextPhase)}
          >
            {showNextPhase ? t('nutrition.currentPhase') : t('nutrition.nextPhase')}
          </span>
        )}
      </div>
      <div className="flex flex-col xl:flex-row xl:justify-between w-full min-h-[calc(100%-100px)] mb-10">
        <div className="xl:w-[calc(50%-15px)]">
          <ProgramProgress />
          <MealTime mealTime={mealTime} onClick={(time: MealTimes) => setMealTime(time)} />

          <div className="space-y-4 pb-[96px] md:space-y-6 xl:pb-12">
            {meals?.map((meal: Meal) => (
              <MealElement
                key={`meal-${meal.id}`}
                image={meal.image.url}
                title={meal.title}
                duration={meal.duration}
                difficulty={meal.difficulty}
                onClick={() => setSelectedMeal(meal)}
                isActive={meal.id === selectedMeal?.id && platform === 'desktop'}
              />
            ))}
          </div>
        </div>
        {showCalendar ? (
          <div className="xl:w-[calc(50%-15px)]">
            <CalendarBuild />
          </div>
        ) : (
          <div className="hidden xl:block xl:w-[calc(50%-15px)]">
            {selectedMeal ? (
              <MealRecipe mealTime={mealTime} selectedMeal={selectedMeal} />
            ) : (
              <div className="hidden xl:flex h-[calc(100vh-152px)] flex-col items-center justify-center">
                <p className="text-[32px] leading-[32px] font-bold text-gray mb-8">{t('nutrition.selectRecipe')}</p>
                <img className="h-[196px] mb-8" src={NutritionIcon} />
                <p className="w-[60%] text-gray text-center">{t('nutrition.pleaseSelectRecipe')}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
