import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { NutritionProgram } from 'features/nutrition/types';
import { WorkoutProgram } from 'features/workout/types';
import { connectToDB } from '../connectToDb';
import { dbName } from '../constants';

export const getAllData = async () => {
  const connectionResult = await connectToDB();
  const DB = connectionResult?.db;

  const data: {
    sqliteWorkoutData: WorkoutProgram | null;
    sqliteNutritionData: NutritionProgram | null;
    isOfflineMode: boolean;
    dataSize: number;
  } = { sqliteWorkoutData: null, sqliteNutritionData: null, isOfflineMode: false, dataSize: 0 };

  const tablesList = await DB?.getTableList();
  if (!tablesList?.values?.length) return { ...data };

  try {
    const results = await Promise.all([
      DB?.query('SELECT * FROM workoutProgram'),
      DB?.query('SELECT * FROM nutritionProgram'),
      DB?.query('SELECT * FROM offlineMode'),
    ]);
    for (const result of results) {
      const values = result?.values;
      if (values?.length) {
        const valuesData = values[0];
        if (valuesData.workout) {
          let value = valuesData.workout;
          const mediaUrls = [...value.matchAll(/DB_DATA_IMG_(\d*)/g)];
          const mediaUrlIds: { original: string; converted: string }[] = [];
          await Promise.all(
            mediaUrls.map(async (url: string[]) => {
              const converted = await replaceDataUrl(url[0]);
              mediaUrlIds.push({ original: url[0], converted });
            })
          );
          for (const url of mediaUrlIds) {
            value = value.replace(url.original, url.converted);
          }
          const workoutJSON: WorkoutProgram = JSON.parse(value);
          data.sqliteWorkoutData = { ...workoutJSON, version: valuesData.version };
        }
        if (valuesData.nutrition) {
          let value = valuesData.nutrition;
          const mediaUrls = [...value.matchAll(/DB_DATA_IMG_(\d*)/g)];
          const mediaUrlIds: { original: string; converted: string }[] = [];
          await Promise.all(
            mediaUrls.map(async (url: string[]) => {
              const converted = await replaceDataUrl(url[0]);
              mediaUrlIds.push({ original: url[0], converted });
            })
          );
          for (const url of mediaUrlIds) {
            value = value.replace(url.original, url.converted);
          }
          const nutritionJSON: NutritionProgram = JSON.parse(value);
          data.sqliteNutritionData = { ...nutritionJSON, version: valuesData.version };
        }
        if (valuesData.isOfflineMode) {
          data.isOfflineMode = JSON.parse(valuesData.isOfflineMode);
          data.dataSize = valuesData.dataSize;
        }
      }
    }
  } catch (err) {
    console.log(err, ' -> get data error');
  }
  await DB?.close();
  return { ...data };
};

export const replaceDataUrl = async (url: string): Promise<string> => {
  if (!url.includes('DB_DATA_')) {
    return url;
  }

  const [urlData, extension] = url.split('.');
  const arr = urlData.split('_').reverse();
  const [id, type] = arr;
  const path = `${dbName}/${type === 'IMG' ? 'images' : 'videos'}/${id}${type === 'IMG' ? '' : `.${extension}`}`;

  const { uri } = await Filesystem.getUri({
    path: path,
    directory: Directory.Data,
  });
  return Capacitor.convertFileSrc(uri);
};
