import { useSelector } from 'react-redux';

import { useLazyCreateStripeCheckoutSessionQuery } from 'features/payments/paymentsAPI';
import { paymentsSelector } from 'features/payments/paymentsSlice';

export const useCreateStripeCheckoutSessionQueryWithState = () => {
  const { checkoutSession } = useSelector(paymentsSelector);
  const [refetch, { isLoading, isSuccess, isFetching }] = useLazyCreateStripeCheckoutSessionQuery();

  return { checkoutSession, isFetching, isSuccess, isLoading, refetch };
};
