import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface CircleProgressBarProps {
  percentage: number;
  day: string;
  color: string;
}

export const CircleProgressBar: React.FunctionComponent<CircleProgressBarProps> = ({ percentage, day, color }) => {
  return (
    <div className="w-[55px] xl:w-[80px]">
      <CircularProgressbar
        value={percentage}
        text={day}
        styles={buildStyles({
          textColor: 'white',
          pathColor: color,
          trailColor: '#666666',
        })}
      />
    </div>
  );
};
