import GainWeightImage from 'assets/images/GainWeight.jpg';
import LoseWeightImage from 'assets/images/LoseWeight.jpg';

export const GoalItems = [
  {
    title: 'Muskelwachstum',
    description: 'Du möchtest mehr Muskeln aufbauen!',
    image: GainWeightImage,
    value: 'gain',
  },
  {
    title: 'Abnehmen',
    description: 'Du möchtest Körperfett verlieren!',
    image: LoseWeightImage,
    value: 'reduce',
  },
];
