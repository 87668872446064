import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useAppSelector } from 'app/hooks';
import { RateApp } from 'capacitor-rate-app';

import { Button, ModalsBackground } from 'common/components';
import { authSelector } from 'features/auth/authSlice';
import { isPlatform } from '@ionic/core';

export const RatingAndFeedback = () => {
  const { user } = useAppSelector(authSelector);

  const [showPopup, setShowPopup] = useState(false);

  const currentDay = Math.floor((new Date().getTime() - new Date(user.startDate).getTime()) / (1000 * 3600 * 24)) + 1;
  const isAlreadyShown = !!localStorage.getItem('rate');

  const rate = async () => {
    await RateApp.requestReview();
    closeModal();
  };

  const feedback = () => {
    window.location.href = 'https://urbanathletix.freshdesk.com/';
    closeModal();
  };

  const closeModal = () => {
    setShowPopup(false);
    localStorage.setItem('rate', 'shown');
  };

  useEffect(() => {
    if (currentDay === 50 && (isPlatform('android') || isPlatform('ios')) && !isAlreadyShown) {
      setShowPopup(true);
    }

    if (currentDay !== 50 && isAlreadyShown) localStorage.removeItem('rate');
  }, [currentDay]);

  return showPopup ? (
    <ModalsBackground>
      <div>
        <h1 className="mb-4">{t('additional.rateAndFeedback')}</h1>
        <p>{t('additional.feedbackDescription')}</p>
      </div>
      <div className="text-center">
        <div className="flex justify-between mb-6">
          <div className="w-[calc(50%-8px)]">
            <Button title={t('additional.rateUs')} onClick={rate} smallSize />
          </div>
          <div className="w-[calc(50%-8px)]">
            <Button title={t('additional.feedback')} onClick={feedback} smallSize />
          </div>
        </div>
        <button className="cursor-pointer font-bold text-base	py-1 px-4" onClick={closeModal}>
          {t('basics.dismiss')}
        </button>
      </div>
    </ModalsBackground>
  ) : null;
};
