import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export const NavigationLink: React.FunctionComponent<{
  link: string;
  icon: string;
  activeIcon: string;
  title: string;
}> = ({ link, icon, activeIcon, title }) => {
  const { pathname } = useLocation();
  const isActive = pathname.startsWith(link);

  return (
    <Link to={link} className="lg:py-2 w-16 lg:w-21 font-bold lg:mb-2 last:mb-0">
      <div
        className={`flex flex-col items-center py-2 text-xs ${
          isActive ? 'text-white shadow-nav-top lg:shadow-nav-left scale-100 duration-200' : 'text-gray'
        }`}
      >
        <img src={isActive ? activeIcon : icon} className="w-6 h-auto mb-2 lg:mb-3" />
        {title}
      </div>
    </Link>
  );
};
