import React from 'react';

interface DropDownProps {
  name: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLElement>) => void;
  value: string | number;
  error: string | undefined;
  touched: boolean | undefined;
  options: { value: string | number; title: string | number }[];
}

export const DropDown: React.FunctionComponent<DropDownProps> = ({
  name,
  placeholder,
  onChange,
  onBlur,
  value,
  error,
  touched,
  options,
}) => {
  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={`focus:outline-none rounded-md h-12 px-4 text-sm w-full appearance-none bg-arrow-down bg-no-repeat bg-right-3
      ${error && touched ? 'text-error border border-error' : 'text-gray-base'}  ${value === '' && 'text-placeholder'}`}
    >
      <option value="" disabled hidden>
        {placeholder}
      </option>
      {options.map((option, index) => (
        <option
          key={`${index}-${option.title}`}
          className="text-gray-base"
          disabled={option.value === ''}
          value={option.value}
        >
          {option.title}
        </option>
      ))}
    </select>
  );
};
