import React from 'react';
import './styles.scss';

export const ModalsBackground: React.FunctionComponent = ({ children }) => {
  return (
    <div className="fixed left-0 top-0 w-screen h-screen z-[30]">
      <div className="fixed w-screen h-screen bg-main-opacity"></div>
      <div className="absolute inset-0 m-auto w-[calc(100%-40px)] md:max-w-[380px] max-h-[300px] bg-main rounded-lg overflow-hidden p-4 pt-6 md:p-6 flex flex-col justify-between">
        <div className="round round-top absolute -right-6 -top-6"></div>
        <div className="round round-down absolute -bottom-10 -left-10"></div>
        {children}
      </div>
    </div>
  );
};
