import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from 'features/auth/types';
import { StripeCheckoutSession, ConfirmPaymentResponse, ProgramDetails } from 'features/payments/types';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      const { authSlice } = getState() as { authSlice: AuthState };
      if (authSlice.jwt) headers.set('Authorization', `Bearer ${authSlice.jwt}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProgramDetails: builder.query<ProgramDetails, void>({
      query: () => {
        return {
          url: 'programs',
        };
      },
    }),
    createStripeCheckoutSession: builder.query<StripeCheckoutSession, void>({
      query: () => {
        return {
          method: 'POST',
          url: 'payment/stripe',
        };
      },
    }),
    createPaypalCheckoutSession: builder.query<{ id: string }, void>({
      query: () => {
        return {
          method: 'POST',
          url: 'payment/paypal',
        };
      },
    }),
    createMobileCheckoutSession: builder.query<{ id: string }, void>({
      query: () => {
        return {
          method: 'POST',
          url: 'payment/mobile',
        };
      },
    }),
    confirmPayment: builder.mutation<
      ConfirmPaymentResponse,
      { provider: 'stripe' | 'paypal' | 'mobile'; orderId: string }
    >({
      query: ({ provider, orderId }) => ({
        method: 'POST',
        url: 'payment/confirm',
        params: { provider },
        body: { orderId },
      }),
    }),
  }),
});

export const {
  useGetProgramDetailsQuery,
  useLazyCreateStripeCheckoutSessionQuery,
  useLazyCreatePaypalCheckoutSessionQuery,
  useLazyCreateMobileCheckoutSessionQuery,
  useConfirmPaymentMutation,
} = paymentsApi;
