import { isPlatform } from '@ionic/core';
import { IonContent } from '@ionic/react';
import React, { useEffect } from 'react';
import { t } from 'i18next';
import { format } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ProgressItem } from '../../types';
import { Button, ErrorMsg, LoadingOverlay } from 'common/components';
import { useDeleteProgressMutation } from 'features/progress/progressAPI';
import { useAppSelector } from 'app/hooks';
import { nutritionSelector } from 'features/nutrition/nutritionSlice';
import { progressesSelector } from 'features/progress/progressSlice';
import { PhasesProps } from 'features/nutrition/types';
import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';

import ArrowBackIcon from 'assets/icons/ArrowBack.svg';
import CloseIcon from 'assets/icons/Close.svg';
import WeightIcon from 'assets/icons/Weight.svg';
import ChevronLeftIcon from 'assets/icons/ChevronLeft.svg';
import ChevronRightIcon from 'assets/icons/ChevronRight.svg';

interface PhotoProps {
  progress: ProgressItem | null;
  handleClose: () => void;
  handleChange: (index: number) => void;
}

export const Photo: React.FunctionComponent<PhotoProps> = ({ progress, handleClose, handleChange }) => {
  const [deleteProgress, { isSuccess, isError, isLoading }] = useDeleteProgressMutation();
  const { platform } = useCheckPlatform();
  const { nutritionProgram } = useAppSelector(nutritionSelector);
  const { progresses } = useAppSelector(progressesSelector);

  const progressWithImage = progresses
    .filter((progress: ProgressItem) => progress.image)
    .sort((a: ProgressItem, b: ProgressItem) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

  const indexOfCurrentPhoto = progressWithImage.indexOf(progress);
  const imagesCount = progressWithImage.length;

  const phaseNumber = nutritionProgram?.phases.find(
    (phase: PhasesProps) => phase.phase.fromDay === progress?.phase.fromDay
  )?.phaseNumber;

  const deleteEntry = async () => {
    if (progress) await deleteProgress(progress.id);
  };

  const renderImageView = (progress: ProgressItem) => {
    return (
      <>
        <div
          className={`fixed inset-0 ${
            isPlatform('ios') ? 'bottom-20' : 'bottom-16'
          } md:bottom-0 md:left-[84px] z-20 bg-main`}
        >
          <img src={progress.image.url} className="h-screen w-screen object-contain" />
        </div>

        <div
          className={`fixed left-0 ${
            isPlatform('ios') ? 'bottom-20' : 'bottom-16'
          } md:bottom-4 w-full flex justify-between items-center z-[25] py-[15px] px-5 md:px-[165px]`}
        >
          <div className="w-full flex justify-between items-end">
            <div className="bg-main-opacity p-2 rounded-sm w-[133px]">
              <div className="flex justify-between text-sm mb-1">
                <span>{format(new Date(progress.createdAt), 'dd/MM/yy')}</span>
                <span>
                  {t('nutrition.phase')} {phaseNumber}
                </span>
              </div>
              <div className="flex items-center">
                <img src={WeightIcon} className="w-6 mr-1" />
                <span className="text-lg font-bold">{progress.weight} Kg</span>
              </div>
            </div>

            <div className="w-[136px]">
              <Button title={t('progress.deleteEntry')} onClick={deleteEntry} />
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  return progress ? (
    <IonContent className="fixed inset-0 z-20">
      {isLoading && <LoadingOverlay />}
      {isError && <ErrorMsg text={t('errors.wentWrong')} />}
      <div
        className="fixed bg-gray-base opacity-75 rounded-full h-8 w-8 left-40 top-[calc(50%-16px)] z-[25] hidden xl:flex items-center justify-center cursor-pointer"
        onClick={() => handleChange(indexOfCurrentPhoto - 1)}
      >
        <img src={ChevronLeftIcon} className="w-5" />
      </div>
      <div
        className="fixed bg-gray-base opacity-75 rounded-full h-8 w-8 right-40 top-[calc(50%-16px)] z-[25] hidden xl:flex items-center justify-center cursor-pointer"
        onClick={() => handleChange(indexOfCurrentPhoto + 1)}
      >
        <img src={ChevronRightIcon} className="w-5" />
      </div>
      <div className="fixed inset-0 bg-main-opacity h-20 w-full flex justify-between items-center z-[25] py-[15px] px-5 md:px-[165px] safe-top">
        <div className="flex items-center cursor-pointer" onClick={handleClose}>
          <img className="w-4 h-auto mr-1" src={ArrowBackIcon} />
          <span>{t('basics.back')}</span>
        </div>
        <div className="text-center">
          <p className="font-bold">{t('progress.gallery')}</p>
          <p className="text-sm">
            {indexOfCurrentPhoto + 1}/{imagesCount}
          </p>
        </div>
        <img src={CloseIcon} className="w-8 cursor-pointer" onClick={handleClose} />
      </div>

      {platform === 'desktop' && renderImageView(progress)}

      {platform === 'non-desktop' && (
        <Swiper
          className="xl:hidden inset-0"
          style={{ position: 'fixed', overflow: 'auto', zIndex: 2 }}
          loop
          initialSlide={indexOfCurrentPhoto}
          onActiveIndexChange={(swiper) => {
            console.log(`swiper.realIndex: ${swiper.realIndex}`);
            console.log(`indexOfCurrentPhoto: ${indexOfCurrentPhoto}`);
            handleChange(platform === 'non-desktop' ? swiper.realIndex : 0);
          }}
        >
          {progressWithImage?.length &&
            progressWithImage.map((progress: ProgressItem) => (
              <SwiperSlide key={`progresses-${progress.id}`}>{renderImageView(progress)}</SwiperSlide>
            ))}
        </Swiper>
      )}
    </IonContent>
  ) : null;
};
