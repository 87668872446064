import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store/store';
import { paymentsApi } from './paymentsAPI';
import { StripeCheckoutSession, WorkoutProgramPrice } from './types';

const initialState: { program: WorkoutProgramPrice | null; checkoutSession: StripeCheckoutSession | null } = {
  program: null,
  checkoutSession: JSON.parse(localStorage.getItem('checkout-session') || '0') || null,
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    resetCheckoutSession: (state) => {
      localStorage.removeItem('checkout-session');
      state.checkoutSession = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(paymentsApi.endpoints.getProgramDetails.matchFulfilled, (state, { payload }) => {
        state.program = payload.workoutProgram;
      })
      .addMatcher(paymentsApi.endpoints.createStripeCheckoutSession.matchFulfilled, (state, { payload }) => {
        localStorage.setItem('checkout-session', JSON.stringify(payload));
        state.checkoutSession = payload;
      });
  },
});

// export const {} = paymentsSlice.actions;
export const paymentsSelector = (state: RootState) => state.paymentsSlice;
