import React from 'react';
import { RegisterPage } from 'features/auth/RegisterPage';
import { LoginPage } from 'features/auth/LoginPage';
import { ForgotPasswordPage } from 'features/auth/ForgotPasswordPage';
import { ResetPassword } from 'features/auth/ResetPassword';
import { EmailConfirmationPage } from 'features/auth/EmailConfirmation';
import { RegisterConfirmationPage } from 'features/auth/RegisterConfirmationPage';
import { MeetTheCoachPage } from 'features/onboarding/MeetTheCoachPage';
import { OnboardingPage } from 'features/onboarding/OnboardingPage';
import { PaymentsPage } from 'features/payments/PaymentsPage';
import { SetStartDay } from 'features/onboarding/SetStartDay';
import { WorkoutPage } from 'features/workout/WorkoutPge';
import { NutritionPage } from 'features/nutrition/NutritionPage';
import { ProgressPage } from 'features/progress/ProgressPage';
import { TipsPage } from 'features/tips/TipsPage';
import { ProfilePage } from 'features/profile/ProfilePage';
import { DataSize } from 'features/profile/DataSize';
import { GalleryPage } from 'features/progress/GalleryPage';
import { InformationPage } from 'features/Information/Information';
import { LegalPage } from 'features/legal/LegalPage';

import { Route } from './types';
import { AdditionalLayout } from 'layouts/additional-layout';
import { AuthLayout } from 'layouts/auth-layout';
import { OnboardingLayout } from 'layouts/onboarding-layout';
import { MainLayout } from 'layouts/main-layout';

export const allowedPublicRoutes: string[] = ['/reset-password', '/legal'];
export const privateFallbackRoute = '/workout';
export const publicFallbackRoute = '/login';

export const routeConfig: Route[] = [
  {
    path: '/login',
    type: 'public',
    element: <LoginPage />,
    layout: AuthLayout,
  },
  {
    path: '/register',
    type: 'public',
    element: <RegisterPage />,
    layout: AuthLayout,
  },
  {
    path: '/terms',
    type: 'public',
    element: <LegalPage page='terms' />,
    layout: AdditionalLayout,
  },
  {
    path: '/privacy-policy',
    type: 'public',
    element: <LegalPage page='privacy-policy' />,
    layout: AdditionalLayout,
  },
  {
    path: '/imprint',
    type: 'public',
    element: <LegalPage page='imprint' />,
    layout: AdditionalLayout,
  },
  {
    path: '/email-confirmation',
    element: <EmailConfirmationPage />,
    type: 'public',
    layout: AuthLayout,
  },
  {
    path: '/forgot-password',
    type: 'public',
    element: <ForgotPasswordPage />,
    layout: AuthLayout,
  },
  {
    path: '/reset-password',
    type: 'public',
    element: <ResetPassword />,
    layout: AuthLayout,
  },
  {
    path: '/register-confirmation',
    type: 'public',
    element: <RegisterConfirmationPage />,
    layout: AuthLayout,
  },
  {
    path: '/the-coach',
    type: 'private',
    element: <MeetTheCoachPage />,
    layout: AdditionalLayout,
  },
  {
    path: '/onboarding',
    type: 'private',
    element: <OnboardingPage />,
    layout: OnboardingLayout,
  },
  {
    path: '/payments',
    type: 'private',
    element: <PaymentsPage />,
    layout: AdditionalLayout,
  },
  {
    path: '/set-start-date',
    type: 'private',
    element: <SetStartDay />,
    layout: OnboardingLayout,
  },
  {
    path: '/workout',
    type: 'private',
    element: <WorkoutPage />,
    layout: MainLayout,
  },
  {
    path: '/nutrition',
    type: 'private',
    element: <NutritionPage />,
    layout: MainLayout,
  },
  {
    path: '/progress',
    type: 'private',
    element: <ProgressPage />,
    layout: MainLayout,
  },
  {
    path: '/information',
    type: 'private',
    element: <InformationPage />,
    layout: OnboardingLayout,
  },
  {
    path: '/progress/gallery',
    type: 'private',
    element: <GalleryPage />,
    layout: MainLayout,
  },
  {
    path: '/tips',
    type: 'private',
    element: <TipsPage />,
    layout: MainLayout,
  },
  {
    path: '/profile',
    type: 'private',
    element: <ProfilePage />,
    layout: MainLayout,
  },
  {
    path: '/profile/offline-mode',
    type: 'private',
    element: <DataSize />,
    layout: MainLayout,
  },
  {
    path: '*',
    type: 'fallback',
  },
];
