import React, { useEffect, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2';
import { useTranslation } from 'react-i18next';
import { PayPalButtons, SCRIPT_LOADING_STATE, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, ErrorMsg, LoadingOverlay, Radio } from 'common/components';
import { useCreateMobileCheckoutSessionQueryWithState } from 'common/helpers/hooks/useCreateMobileCheckoutSessionQuery';
import { useCreatePaypalCheckoutSessionQueryWithState } from 'common/helpers/hooks/useCreatePaypalCheckoutSessionQueryWithState';
import { useCreateStripeCheckoutSessionQueryWithState } from 'common/helpers/hooks/useCreateStripeCheckoutSessionQuery';
import { useGetProgramDetailsQueryWithState } from 'common/helpers/hooks/useGetProgramDetailsQuery';
import { Product } from '../../common/constants/product';
import { useConfirmPaymentMutation } from './paymentsAPI';

import MasterCardImg from 'assets/images/MasterCard.png';
import PaypalImg from 'assets/images/Paypal.png';
import VisaImg from 'assets/images/Visa.png';
import { replaceDataUrl } from '../offline-mode/queries/getData';

export const PaymentsPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation();
  const {
    refetch: refetchStripeSession,
    checkoutSession: stripeCheckoutSession,
    isLoading: isStripeCheckoutSessionLoading,
    isSuccess: isStripeCheckoutSessionSuccess,
  } = useCreateStripeCheckoutSessionQueryWithState();
  const {
    refetch: refetchPaypalSession,
    checkoutSession: paypalCheckoutSession,
    isLoading: isPaypalCheckoutSessionLoading,
    isSuccess: isPaypalCheckoutSessionSuccess,
  } = useCreatePaypalCheckoutSessionQueryWithState();
  const {
    refetch: refetchMobileSession,
    orderId,
    isLoading: isMobileCheckoutSessionLoading,
    isSuccess: isMobileCheckoutSessionSuccess,
  } = useCreateMobileCheckoutSessionQueryWithState();

  const { data: program, isFetching } = useGetProgramDetailsQueryWithState();
  const [confirm, { isSuccess: isConfirmed, isLoading: isConfirmingLoad, isError: isConfirmedError }] =
    useConfirmPaymentMutation();

  const [image, setImage] = useState(program?.image?.url || '');

  useEffect(() => {
    const getImageFromStorage = async (image: string) => {
      return replaceDataUrl(image);
    };
    if (program?.image?.url) {
      getImageFromStorage(program?.image?.url).then((url: string) => {
        setImage(url);
      });
    }
  }, [program?.image?.url]);

  const [{ isPending, isInitial }, dispatch] = usePayPalScriptReducer();
  const [mobilePurchaseIsPendig, setMobilePurchaseIsPendig] = useState<boolean>(false);

  const appId = `${process.env.REACT_APP_APP_ID}`;
  const [paymentMethod, setPaymentMethod] = useState<string>('stripe');
  const isPaymentSuccess = new URLSearchParams(search).get('success');

  const payProgram = async () => {
    if (isPlatform('mobile') && !isPlatform('mobileweb')) {
      await refetchMobileSession();
    } else {
      if (paymentMethod === 'stripe') await refetchStripeSession();
      else if (paymentMethod === 'paypal') await refetchPaypalSession();
    }
  };

  useEffect(() => {
    const confirmPayment = async () => {
      if (isPaymentSuccess && stripeCheckoutSession)
        await confirm({ provider: 'stripe', orderId: stripeCheckoutSession?.orderId });
    };

    confirmPayment();
  }, [isPaymentSuccess]);

  useEffect(() => {
    if (isConfirmed) navigate('/set-start-date');
  }, [isConfirmed]);

  useEffect(() => {
    if (isPlatform('mobile') && !isPlatform('mobileweb'))
      InAppPurchase2.when(appId).owned(async () => {
        await refetchMobileSession();
      });
  });

  useEffect(() => {
    if (isStripeCheckoutSessionSuccess) window.location.href = stripeCheckoutSession?.url || '/';
    if (isPaypalCheckoutSessionSuccess) window.location.href = paypalCheckoutSession?.url || '/';
    if (isStripeCheckoutSessionSuccess || isPaypalCheckoutSessionSuccess || isMobileCheckoutSessionSuccess)
      dispatch({
        type: 'setLoadingStatus',
        value: SCRIPT_LOADING_STATE.INITIAL,
      });

    if (isMobileCheckoutSessionSuccess) {
      setMobilePurchaseIsPendig(true);
      const currentProduct = Product;
      if (currentProduct.id) {
        const productReference = currentProduct.id;
        const productInfo = InAppPurchase2.get(productReference);
        console.log(productInfo);

        InAppPurchase2.when(productReference).registered((e: any) => {
          console.log('Product registered:', e);
        });

        InAppPurchase2.when(productReference).invalid((e: any) => {
          console.log('invalid app id', e);
          setMobilePurchaseIsPendig(false);
        });

        InAppPurchase2.when(productReference).cancelled(() => {
          console.log('cancelled');
          setMobilePurchaseIsPendig(false);
        });

        InAppPurchase2.when(productReference).error((e: any) => {
          console.log(e);
        });

        InAppPurchase2.when(productReference).approved(async (product: any) => {
          console.log('approved');
          product.verify();
        });

        InAppPurchase2.when(productReference).verified(async (product: any) => {
          console.log('verified');
          product.finish();
          setMobilePurchaseIsPendig(false);
          if (orderId) await confirm({ provider: 'mobile', orderId });
        });

        InAppPurchase2.refresh();
        InAppPurchase2.ready(() => {
          const checkProduct = InAppPurchase2.get(productReference);
          console.log(checkProduct);
          InAppPurchase2.order(productReference)
            .error((e: any) => {
              console.log('failed');
              console.error(e);
            })
            .then((p: any) => {
              console.log('executed', p);
            });
        });
      }
    }
  }, [isStripeCheckoutSessionSuccess, paypalCheckoutSession, isMobileCheckoutSessionSuccess, orderId]);

  useEffect(() => {
    if (isInitial)
      dispatch({
        type: 'setLoadingStatus',
        value: SCRIPT_LOADING_STATE.PENDING,
      });
  }, []);

  return isFetching ? (
    <LoadingOverlay />
  ) : (
    <>
      {(isConfirmingLoad ||
        isStripeCheckoutSessionLoading ||
        isPaypalCheckoutSessionLoading ||
        isMobileCheckoutSessionLoading ||
        mobilePurchaseIsPendig ||
        isPending) && <LoadingOverlay />}
      {((!isPaymentSuccess && isPaymentSuccess !== null) || isConfirmedError) && (
        <ErrorMsg text={t('errors.wentWrong')} />
      )}
      <h1 className="mb-6 md:mb-9">{t('payments.title')}</h1>
      <div className="flex flex-1 flex-col md:flex-row md:justify-between h-[calc(100%-68px)]">
        <img src={image} className="object-cover mb-4 rounded-lg md:w-1/2 md:mb-0" />
        <div className="md:w-[45%] flex flex-col flex-1 md:flex-initial justify-between h-auto">
          <div className="mb-5 bb-coach-info" dangerouslySetInnerHTML={{ __html: program?.introduction || '' }}></div>
          <div>
            <h3>{t('payments.payment')}</h3>
            <div className="flex items-center justify-between mb-5 md:mb-9">
              <p>{program?.stripePrice.name}</p>
              <p className="text-2xl font-bold">{program?.stripePrice.amount}€</p>
            </div>
            {!(isPlatform('ios') || isPlatform('android')) && (
              <>
                <div className="flex items-center justify-between mb-2">
                  <Radio
                    name="paymentMethod"
                    id="stripe"
                    checked={paymentMethod === 'stripe'}
                    value="stripe"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPaymentMethod(e.target.value)}
                  />
                  <label htmlFor="stripe" className="w-[95%] cursor-pointer text-font-secondary flex items-center">
                    {t('payments.with')} Mastercard, Visa
                    <img src={MasterCardImg} className="w-10 h-auto ml-1.5" />
                    <img src={VisaImg} className="w-10 h-auto ml-1.5" />
                  </label>
                </div>
                <div className="flex items-center justify-between mb-4">
                  <Radio
                    name="paymentMethod"
                    id="paypal"
                    checked={paymentMethod === 'paypal'}
                    value="paypal"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPaymentMethod(e.target.value)}
                  />
                  <label htmlFor="paypal" className="w-[95%] cursor-pointer text-font-secondary flex items-center">
                    PayPal
                    <img src={PaypalImg} className="w-10 h-auto ml-3" />
                  </label>
                </div>
              </>
            )}
          </div>
          {paymentMethod === 'stripe' ? (
            <Button
              title={t('payments.getAccess', { amount: program?.stripePrice.amount })}
              onClick={payProgram}
              responsive
            />
          ) : (
            <div className="w-1/2">
              <PayPalButtons
                style={{ layout: 'horizontal', height: 48 }}
                fundingSource={undefined}
                createOrder={async () => {
                  const data = await refetchPaypalSession().unwrap();
                  return `${data.id}`;
                }}
                onApprove={async (data) => {
                  await confirm({ provider: 'paypal', orderId: data.orderID });
                }}
                onError={() => {
                  console.log('paypal fail');
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
