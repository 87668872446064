import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store/store';
import { profileApi } from './profileAPI';
import { MediasTypes } from './types';

interface initialStateProps {
  dataSize: number | null;
  media: {
    videos: MediasTypes[];
    images: MediasTypes[];
  } | null;
  isOfflineMode: boolean;
  toBeDownloaded: number | null;
  alreadyDownloaded: number | null;
}

const initialState: initialStateProps = {
  dataSize: null,
  media: null,
  isOfflineMode: false,
  toBeDownloaded: null,
  alreadyDownloaded: null,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setDownloaded: (state, { payload }) => {
      debugger;
      console.log(payload);
      const { toBeDownloaded, alreadyDownloaded } = payload;
      state.toBeDownloaded = toBeDownloaded;
      state.alreadyDownloaded = alreadyDownloaded;
    },
    setOfflineMode: (state, { payload }) => {
      state.isOfflineMode = payload;
    },
    setDataSize: (state, { payload }) => {
      state.dataSize = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getInfoForOffline.matchFulfilled, (state, { payload }) => {
      state.dataSize = payload.totalSize;
      state.media = { videos: payload.videos, images: payload.images };
    });
  },
});

export const { setOfflineMode, setDataSize, setDownloaded } = profileSlice.actions;
export const profileSelector = (state: RootState) => state.profileSlice;
