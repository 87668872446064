import React from 'react';

export const SlideDot: React.FunctionComponent<{ isActive: boolean }> = ({ isActive }) => {
  return (
    <button
      className={`w-3 h-3 rounded-ms mr-2 last:mr-0 cursor-auto
        ${isActive ? 'bg-main-red' : 'bg-gray'}`}
    />
  );
};
