import { isPlatform } from '@ionic/core';
import React from 'react';
import { Navigation } from 'common/components';

export const MainLayout: React.FunctionComponent = ({ children }) => {
  return (
    <div className="min-h-screen ios-height">
      <div className="fixed right-top-round left-[80%] -top-8 lg:left-[90%] lg:-top-36 -z-[1]  safe-top safe-left safe-right"></div>
      <div className="fixed left-down-round top-[38%] right-[60%] lg:top-[50%] lg:left-[-30px] -z-[1]  safe-top safe-left safe-right"></div>
      <div className="flex flex-1">
        <Navigation />
        <div
          className={`py-5 pb-[63px] px-5 lg:pb-0 lg:px-40 w-full ${
            isPlatform('ios') && !isPlatform('mobileweb') ? 'h-[calc(100vh-63px)]' : 'h-screen'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
