import React from 'react';
import { t } from 'i18next';
import { MealTimes } from 'features/nutrition/NutritionPage';

export const MealTime: React.FunctionComponent<{
  mealTime: MealTimes;
  onClick: (time: MealTimes) => void;
}> = ({ mealTime, onClick }) => {
  return (
    <div className="flex justify-around mb-8">
      <span
        className={`meal-filter w-auto px-3 md:w-full ${
          mealTime === 'breakfast' ? 'bg-white-opacity shadow-box' : ''
        } `}
        onClick={() => onClick(MealTimes.BREAKFAST)}
      >
        {t('nutrition.breakfast')}
      </span>
      <span
        className={`meal-filter w-auto px-3 md:w-full ${mealTime === 'lunch' ? 'bg-white-opacity shadow-box' : ''} `}
        onClick={() => onClick(MealTimes.LUNCH)}
      >
        {t('nutrition.lunch')}
      </span>
      <span
        className={`meal-filter w-auto px-3 md:w-full ${mealTime === 'dinner' ? 'bg-white-opacity shadow-box' : ''} `}
        onClick={() => onClick(MealTimes.DINNER)}
      >
        {t('nutrition.dinner')}
      </span>
      <span
        className={`meal-filter w-auto px-3 md:w-full ${mealTime === 'snacks' ? 'bg-white-opacity shadow-box' : ''} `}
        onClick={() => onClick(MealTimes.SNACKS)}
      >
        {t('nutrition.snacks')}
      </span>
    </div>
  );
};
