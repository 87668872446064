import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import { PhysicalActivityItems } from './config';
import { PhysicalActivityItem } from '../components/PhysicalActivityItem/PhysicalActivityItem';
import 'swiper/css';

export const PhysicalActivity: React.FunctionComponent<{
  handleClick: (value: string) => void;
  selectedValue: string;
}> = ({ handleClick, selectedValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="mb-6 md:mb-9">{t('onboarding.physicalActivity')}</h1>
      <Swiper
        breakpoints={{ 640: { slidesPerView: 2.1 }, 1000: { slidesPerView: 4 } }}
        slidesPerView={1.1}
        spaceBetween={25}
        className="h-[calc(100%-160px)]"
      >
        {PhysicalActivityItems.map((activity) => (
          <SwiperSlide key={`${activity.title}-activity`} defaultValue={activity.value}>
            <PhysicalActivityItem
              {...activity}
              handleClick={(value: string) => handleClick(value)}
              isActive={selectedValue === activity.value}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
