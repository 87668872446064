import { Directory } from '@capacitor/filesystem';
import write_blob from 'capacitor-blob-writer';
import PQueue from 'p-queue';
import { OfflineModeData } from 'features/profile/types';
import { WorkoutProgram } from 'features/workout/types';
import { NutritionProgram } from 'features/nutrition/types';
import { storeData } from './queries/storeData';
import { dbName } from './constants';
import { setDownloaded } from 'features/profile/profileSlice';

import { store } from '../../app/store/store';
interface OfflineData {
  workout: WorkoutProgram;
  nutrition: NutritionProgram;
}

export const saveOfflineData = async (
  data: OfflineModeData,
  offlineSettings: { isOfflineMode: boolean; dataSize: number }
) => {
  debugger;
  store.dispatch(
    setDownloaded({
      toBeDownloaded: data.images.length + data.videos.length,
      alreadyDownloaded: 0,
    })
  );
  const videoQueue = new PQueue({ concurrency: 3 });
  const imageQueue = new PQueue({ concurrency: 10 });
  const processImage = async (image: any) => {
    try {
      const imageData = await fetch(image.url.replace('https', 'http'));
      const blob = await imageData.blob();
      const extension = image.url.split('.').pop();
      await write_blob({
        path: `${dbName}/images/${image.id}.${extension}`,
        blob,
        directory: Directory.Data,
        recursive: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const processVideo = async (video: any) => {
    try {
      const videoData = await fetch(video.url.replace('https', 'http'));
      const blob = await videoData.blob();
      const extension = video.url.split('.').pop();
      await write_blob({
        path: `${dbName}/videos/${video.id}.${extension}`,
        blob,
        directory: Directory.Data,
        recursive: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const setDownloadedNumbers = () => {
    console.log('COMPLETED');
    store.dispatch(
      setDownloaded({
        toBeDownloaded: data.images.length + data.videos.length,
        alreadyDownloaded: imageQueue.size + imageQueue.pending + videoQueue.size + videoQueue.pending,
      })
    );
  };
  imageQueue.on('completed', setDownloadedNumbers);
  videoQueue.on('completed', setDownloadedNumbers);
  await Promise.all(
    data.images.map(async (image) => {
      await imageQueue.add(() => processImage(image));
    })
  );
  await Promise.all(
    data.videos.map(async (video) => {
      await videoQueue.add(() => processVideo(video));
    })
  );

  await imageQueue.onEmpty();
  await videoQueue.onEmpty();

  const result = await addIdUrls({
    workout: data.workoutProgram,
    nutrition: data.nutritionProgram,
  });
  try {
    await storeData(result, offlineSettings);
  } catch (err) {
    // isSuccess = false;
  }

  return { result, isSuccess: true };
};

const addIdUrls = (object: OfflineData) => {
  const newObject = JSON.parse(JSON.stringify(object));
  const objectKeys = Object.keys(newObject);
  const restKeys = objectKeys.filter((k) => k !== 'image' && k !== 'video');
  if (newObject?.image) {
    const extension = newObject.image.url.split('.').pop();
    newObject.image.url = `DB_DATA_IMG_${newObject.image.id}.${extension}`;
  }
  if (newObject?.video) {
    const extension = newObject.video.url.split('.').pop();
    newObject.video.url = `DB_DATA_VIDEO_${newObject.video.id}.${extension}`;
  }
  restKeys.forEach(async (key) => {
    if (typeof newObject[key] === 'object' && newObject[key]) newObject[key] = addIdUrls(newObject[key]);
  });
  return newObject;
};
