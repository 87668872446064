import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from 'features/auth/types';
import { OnboardingData, DefaultUserInformation, UserInformation } from './types';

export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/onboarding`,
    prepareHeaders: (headers, { getState }) => {
      const { authSlice } = getState() as { authSlice: AuthState };
      if (authSlice.jwt) headers.set('Authorization', `Bearer ${authSlice.jwt}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getContent: builder.query<OnboardingData, void>({
      query: () => {
        return {
          url: '',
        };
      },
    }),
    saveUserDetails: builder.mutation<UserInformation, DefaultUserInformation>({
      query: (body) => ({
        method: 'PUT',
        url: '/details',
        body,
      }),
    }),
    setStartData: builder.mutation<{ startDate: Date }, { date: number }>({
      query: (body) => ({
        method: 'PUT',
        url: '/date',
        body,
      }),
    }),
  }),
});

export const { useGetContentQuery, useSaveUserDetailsMutation, useSetStartDataMutation } = onboardingApi;
