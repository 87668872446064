import { store } from 'app/store/store';

import { setNutritionProgram } from 'features/nutrition/nutritionSlice';
import { saveOfflineData } from 'features/offline-mode/saveOfflineData';
import { setDataSize, setOfflineMode } from 'features/profile/profileSlice';
import { setWorkoutProgram } from 'features/workout/workoutSlice';
import { OfflineModeData } from 'features/profile/types';

export const dispatchSavedData = async (fetchedData: OfflineModeData, dataSize: number) => {
  const { result } = await saveOfflineData(fetchedData, { isOfflineMode: true, dataSize });

  store.dispatch(setOfflineMode(true));
  store.dispatch(setDataSize(dataSize));

  store.dispatch(setWorkoutProgram(result.workout));
  store.dispatch(setNutritionProgram(result.nutrition));

  return { result };
};
