import { de, uk } from 'date-fns/locale';
import React, { FunctionComponent, useEffect, useState } from 'react';
import i18next, { t } from 'i18next';
import { format } from 'date-fns';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppSelector } from 'app/hooks';
import 'swiper/css';

import { PhasesProps } from 'features/nutrition/types';
import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';
import { ProgressItem } from 'features/progress/types';
import { onlineStatus } from 'common/helpers/onlineStatus';
import { progressesSelector } from 'features/progress/progressSlice';
import { ImageSlide } from '../ImageSlide/ImageSlide';
import { SlideDot } from 'common/components';

import LockIcon from 'assets/icons/Lock.svg';
import AddCircleIcon from 'assets/icons/AddCircle.svg';
import ChevronLeftIcon from 'assets/icons/ChevronLeft.svg';
import ChevronRightIcon from 'assets/icons/ChevronRight.svg';

interface PhaseElementProps {
  phase: PhasesProps;
  currentDay: number;
  isCurrentPhase: boolean;
  isFuturePhase: boolean;
  startDate: Date;
  onClick: () => void;
  setEntry: (entry: ProgressItem | null) => void;
}

export const PhaseElement: FunctionComponent<PhaseElementProps> = ({
  phase,
  currentDay,
  isCurrentPhase,
  isFuturePhase,
  startDate,
  onClick,
  setEntry,
}) => {
  const { progresses } = useAppSelector(progressesSelector);
  const { isOnline } = onlineStatus();
  const { platform } = useCheckPlatform();

  const [activeIndex, setActiveIndex] = useState(0);

  const { phaseNumber, phase: phaseData } = phase;
  const { fromDay, untilDay } = phaseData;

  const progressesForPhase = progresses
    ?.filter((progress: ProgressItem) => progress.phase.fromDay === fromDay && progress.phase.untilDay === untilDay)
    .sort((a: ProgressItem, b: ProgressItem) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    .sort((a: ProgressItem, b: ProgressItem) => a.phase.fromDay - b.phase.fromDay);

  const progress = !isFuturePhase ? (100 * (currentDay - fromDay + 1)) / (untilDay - fromDay + 1) : 0;
  const firstDate = new Date(new Date(startDate).getTime() + 1000 * 3600 * 24 * (fromDay - 1));
  const lastDate = new Date(new Date(startDate).getTime() + 1000 * 3600 * 24 * (untilDay - 1));

  useEffect(() => {
    setActiveIndex(0);
  }, [progresses.length]);

  return (
    <>
      <div className="bg-progresbarbg rounded-lg p-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center text-font-secondary text-base md:text-lg font-main font-bold text-left">
            {isFuturePhase && <img src={LockIcon} className="w-5 mr-2" />}
            <span>
              {t('nutrition.phase')} {phaseNumber}
            </span>
          </div>

          <p className="text-center rounded-ms px-2 py-0.5 bg-white-opacity text-sm">
            {format(firstDate, 'dd/MM')} - {format(lastDate, 'dd/MM')}
          </p>
        </div>

        <div className="mt-[14px] w-full bg-gray rounded-full h-1">
          <div
            className="bg-progresyewoll h-1 rounded-full"
            style={{ width: `${progress <= 100 ? progress : 100}%` }}
          />
        </div>

        <p className="mt-1 text-xs md:text-base text-font-secondary">
          {!isFuturePhase
            ? `${untilDay - currentDay < 0 ? 0 : untilDay - currentDay} ${t('nutrition.daysLeftInPhase')}`
            : t('progress.phaseStartsOn', {
                phaseNumber,
                date: format(firstDate, 'dd. MMMM', { locale: i18next.language === 'de' ? de : uk }),
              })}
        </p>

        {((platform === 'non-desktop' && isCurrentPhase) || !!progressesForPhase?.length) && (
          <p className="text-sm font-bold mt-6 mb-3 ">{t('progress.weightAndPhoto')}</p>
        )}

        <Swiper
          spaceBetween={25}
          className="xl:hidden"
          loop
          onActiveIndexChange={(swiper) => setActiveIndex(platform === 'non-desktop' ? swiper.realIndex : 0)}
        >
          {!!progressesForPhase?.length &&
            isOnline &&
            progressesForPhase.map((progress: ProgressItem) => (
              <SwiperSlide key={`progress-${progress.id}`} onClick={() => setEntry(progress.image ? progress : null)}>
                <ImageSlide progress={progress} phaseNumber={phaseNumber} onClick={() => null} />
              </SwiperSlide>
            ))}
          {isCurrentPhase && (
            <SwiperSlide>
              <div
                className={`border border-2 border-white-opacity bg-white bg-opacity-[0.15] rounded-ms flex items-center justify-center text-base font-bold
                ${progressesForPhase?.length && isOnline ? 'h-50' : 'h-11'}
              `}
                onClick={onClick}
              >
                <img className="w-[24px] mr-1" src={AddCircleIcon} />
                <span>{t('progress.addEntry')}</span>
              </div>
            </SwiperSlide>
          )}
        </Swiper>

        {!!progressesForPhase.length && platform === 'desktop' && (
          <div className="relative hidden xl:block">
            {progressesForPhase.length > 1 && (
              <>
                <div
                  className="absolute bg-gray-base opacity-75 rounded-full h-8 w-8 left-4 top-[calc(50%-16px)] z-[15] flex items-center justify-center cursor-pointer"
                  onClick={() => setActiveIndex(activeIndex - 1 < 0 ? progressesForPhase.length - 1 : activeIndex - 1)}
                >
                  <img src={ChevronLeftIcon} className="w-5" />
                </div>
                <div
                  className="absolute bg-gray-base opacity-75 rounded-full h-8 w-8 right-4 top-[calc(50%-16px)] z-[15] flex items-center justify-center cursor-pointer"
                  onClick={() => setActiveIndex(activeIndex + 1 < progressesForPhase.length ? activeIndex + 1 : 0)}
                >
                  <img src={ChevronRightIcon} className="w-5" />
                </div>
              </>
            )}
            <ImageSlide
              progress={progressesForPhase[activeIndex]}
              phaseNumber={phaseNumber}
              onClick={(entry) => setEntry(entry)}
            />
          </div>
        )}

        {!!progressesForPhase?.length && isOnline && (
          <div className="flex justify-center mt-3">
            {progressesForPhase.map((progress: ProgressItem, index: number) => (
              <SlideDot key={`dot=${index}`} isActive={activeIndex === index} />
            ))}
            {platform === 'non-desktop' && isCurrentPhase && (
              <SlideDot isActive={activeIndex >= progressesForPhase?.length} />
            )}
          </div>
        )}
      </div>
    </>
  );
};
