import React, { useState } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';

import { progressesSelector } from './progressSlice';
import { Progresses, ProgressItem } from './types';
import { useGetProgressQueryWithState } from 'common/helpers/hooks/useGetProgressQuery';
import { LoadingOverlay } from 'common/components';
import { Photo } from './components/Photo/Photo';
import { nutritionSelector } from 'features/nutrition/nutritionSlice';
import { PhasesProps } from 'features/nutrition/types';
import { PhaseProgressGallery } from './components/PhaseProgressGallery/PhaseProgressGallery';
import { authSelector } from 'features/auth/authSlice';
import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';

import ArrowBackIcon from 'assets/icons/ArrowBack.svg';

export const GalleryPage = () => {
  const navigate = useNavigate();
  const { isLoading } = useGetProgressQueryWithState();
  const { user } = useAppSelector(authSelector);
  const { progresses } = useAppSelector(progressesSelector);
  const { nutritionProgram } = useAppSelector(nutritionSelector);
  const { platform } = useCheckPlatform();

  const [entry, setEntry] = useState<ProgressItem | null>(null);

  const currentDay = Math.floor((new Date().getTime() - new Date(user.startDate).getTime()) / (1000 * 3600 * 24)) + 1;
  const progressWithImage = progresses
    .filter((progress: ProgressItem) => progress.image)
    .sort((a: ProgressItem, b: ProgressItem) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

  const orderProgresses = () => {
    const orderedProgresses: Progresses[] = [];

    nutritionProgram?.phases.map((phase: PhasesProps) => {
      const { fromDay, untilDay } = phase.phase;

      const phaseProgress = progressWithImage.filter(
        (progress: ProgressItem) => progress.phase.fromDay === fromDay && progress.phase.untilDay === untilDay
      );

      orderedProgresses.push(phaseProgress);
    });

    return orderedProgresses;
  };

  const handleChange = (index: number) => {
    if (index < 0) setEntry(progressWithImage[progressWithImage.length - 1]);
    if (index > progressWithImage.length - 1) setEntry(progressWithImage[0]);
    if (index >= 0 && index < progressWithImage.length) setEntry(progressWithImage[index]);
  };

  return entry ? (
    <Photo progress={entry} handleClose={() => setEntry(null)} handleChange={handleChange} />
  ) : (
    <>
      {isLoading && <LoadingOverlay />}
      {nutritionProgram?.days < currentDay && platform === 'desktop' ? (
        <h3 className="mb-6 mt-[140px]">{t('progress.yourGallery')}</h3>
      ) : (
        <div className="text-center relative mb-8">
          <div className="absolute flex items-center h-6 cursor-pointer" onClick={() => navigate(-1)}>
            <img className="w-4 h-auto mr-1" src={ArrowBackIcon} />
            <span>{t('basics.back')}</span>
          </div>
          <h4 className="first-letter:uppercase">{t('progress.gallery')}</h4>
        </div>
      )}

      {progressWithImage.length ? (
        orderProgresses().map((phaseProgress, index) => (
          <PhaseProgressGallery
            key={`phase-progress-${index}`}
            phaseNumber={index + 1}
            phaseProgress={phaseProgress}
            setEntry={setEntry}
            programDone={nutritionProgram?.days < currentDay}
          />
        ))
      ) : (
        <p>{t('progress.uploadProgress')}</p>
      )}
    </>
  );
};
