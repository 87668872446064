import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en, de } from './translations';

i18n.use(initReactI18next).init({
  fallbackLng: 'de',
  resources: {
    de,
    en,
  },
});

export default i18n;
