import EctomorphMaleImage from 'assets/images/Ectomorph-male.png';
import MesomorphMaleImage from 'assets/images/Mesomorph-male.png';
import EndomorphMaleImage from 'assets/images/Endomorph-male.png';

import EctomorphFemaleImage from 'assets/images/Ectomorph-female.png';
import MesomorphFemaleImage from 'assets/images/Mesomorph-female.png';
import EndomorphFemaleImage from 'assets/images/Endomorph-female.png';

export const BodyTypeItems = [
  {
    title: 'Ektomorph',
    description:
      'Als ektomorpher Typ bist Du oft lang und schlank, hast wenig Körperfett und wenig Muskeln. Es fällt dir schwer, Gewicht zuzulegen.',
    male: EctomorphMaleImage,
    female: EctomorphFemaleImage,
    value: 'ectomorph',
  },
  {
    title: 'Mesomorph',
    description:
      'Als mesomorpher Typ bist Du oft sportlich, kräftig und stark. Außerdem nimmst du sowohl zu als auch ab, ohne dich allzu sehr anstrengen zu müssen.',
    male: MesomorphMaleImage,
    female: MesomorphFemaleImage,
    value: 'mesomorph',
  },
  {
    title: 'Endomorph',
    description: 'Als endomorpher Typ hast Du oft viel Körperfett, viele Muskeln und nimmst leicht zu.',
    male: EndomorphMaleImage,
    female: EndomorphFemaleImage,
    value: 'endomorph',
  },
];
