import React from 'react';
import './styles.scss';

export const AdditionalLayout: React.FunctionComponent = ({ children }) => {
  return (
    <div className="max-w-6xl min-h-screen m-auto py-11 px-5 md:px-10">
      <div className="fixed right-top-round left-[80%] bottom-[75%] md:left-[90%] md:bottom-[80%] z-[-1]"></div>
      <div className="fixed left-down-round top-[38%] right-[60%] md:top-[50%] md:right-[90%] z-[-1]"></div>
      <div className="flex flex-1 flex-col min-h-[calc(100vh-88px)]">{children}</div>
    </div>
  );
};
