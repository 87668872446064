import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, ErrorMsg, LoadingOverlay } from 'common/components';
import { useSetStartDataMutation } from './onboardingAPI';

import CoachImage from 'assets/images/meet-the-coach-sq.jpg';

export const SetStartDay = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setStartDay, { isLoading, isSuccess, isError }] = useSetStartDataMutation();
  const [startDate, setStartDate] = useState('');

  const sendStartDay = async () => {
    await setStartDay({ date: new Date(startDate).getTime() });
  };

  useEffect(() => {
    if (isSuccess) navigate('/workout');
  }, [isSuccess]);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {isError && <ErrorMsg text={t('errors.wentWrong')} />}
      <h1 className="mb-6 md:mb-9">{t('onboarding.getStarted')}</h1>
      <div className="flex flex-col md:flex-row justify-between h-[calc(100%-68px)]">
        <img src={CoachImage} className="md:w-1/2 rounded-lg object-cover mb-4 md:mb-0" />
        <div className="md:w-[45%] flex flex-col justify-between h-full">
          <div>
            <p className="mb-8 md:mb-12">{t('onboarding.programDescription')}</p>
            <h3 className="mb-4">{t('onboarding.selectDate')}</h3>
            <div className="flex justify-between items-center md:w-2/3">
              <input
                type="date"
                min={new Date().toISOString().split('T')[0]}
                className="focus:outline-none appearance-none rounded-md h-12 px-4 mb-3 text-sm text-gray-base w-full "
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
          </div>
          <Button title={t('onboarding.beginJourney')} onClick={sendStartDay} disabled={!startDate} responsive />
        </div>
      </div>
    </>
  );
};
