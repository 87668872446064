import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

import { Button } from 'common/components';

import ArrowBackIcon from 'assets/icons/ArrowBack.svg';
import PlusIcon from 'assets/icons/Plus.svg';
import MinusIcon from 'assets/icons/Minus.svg';
import WorkoutIcon from 'assets/icons/Workout.svg';
import WorkoutActiveIcon from 'assets/icons/Workout-active.svg';
import HourglassIcon from 'assets/icons/Hourglass.svg';
import HourglassDarkIcon from 'assets/icons/Hourglass-dark.svg';
import PlayArrowIcon from 'assets/icons/PlayArrow.svg';
import ShortBeep from 'assets/sounds/beep.mp3';
import LongBeep from 'assets/sounds/longer-beep.mp3';

interface TimerProps {
  duration: number;
  name: string;
  unit: string;
  type: string;
  exerciseBreak: number;
  sets: number;
  onClick: () => void;
}

export const Timer: React.FunctionComponent<TimerProps> = ({ duration, name, unit, exerciseBreak, sets, onClick }) => {
  const [exerciseDuration, setExerciseDuration] = useState(duration);
  const [breakDuration, setBreakDuration] = useState(exerciseBreak);
  const [currentTime, setCurrentTime] = useState(duration);
  const [step, setStep] = useState(0);
  const [timeRun, setTimeRun] = useState(false);

  const shortBeep = new Audio(ShortBeep);
  const longBeep = new Audio(LongBeep);

  const formatDuration = (duration: number) => {
    // const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor((duration % 3600) % 60);

    return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
  };

  const decreaseTime = () => {
    let durationInSeconds = duration;
    if (unit === 'minutes') durationInSeconds = duration * 60;

    if (durationInSeconds < exerciseDuration) setExerciseDuration(exerciseDuration - 5);
  };

  const startTimer = () => {
    setTimeRun(true);
    setCurrentTime(5);
  };

  const displayTimes = () => {
    const setsArr = [];
    for (let i = 0; i < sets * 2 + 1; i++) {
      const isPause = i === 0;
      const isExercise = i % 2 === 1;
      const isBreak = i % 2 === 0 && i !== 0;
      const isActive = step === i;

      setsArr.push(
        <div key={`time-${i}`} className="flex items-center justify-center mb-3">
          <p className={`text-[32px] ${isActive ? 'text-white' : 'text-gray'}`}>
            {isPause ? formatDuration(5) : null}
            {isExercise ? formatDuration(exerciseDuration) : null}
            {isBreak ? formatDuration(exerciseBreak) : null}
          </p>
          {isPause ? <img className="h-8 ml-1" src={isActive ? HourglassIcon : HourglassDarkIcon} /> : null}
          {isExercise ? <img className="h-8 ml-1" src={isActive ? WorkoutActiveIcon : WorkoutIcon} /> : null}
          {isBreak ? <img className="h-8 ml-1" src={isActive ? HourglassIcon : HourglassDarkIcon} /> : null}
        </div>
      );
    }

    return setsArr;
  };

  useEffect(() => {
    setCurrentTime(exerciseDuration);
  }, [exerciseDuration]);

  useEffect(() => {
    if (unit === 'minutes') {
      setExerciseDuration(exerciseDuration * 60);
      setBreakDuration(exerciseBreak * 60);
    }
  }, [unit]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (timeRun && (step < sets * 2 + 1 || !sets)) {
      let time = currentTime;

      timer = setInterval(() => {
        setCurrentTime(time - 1);
        time = time - 1;

        if (time === 0) {
          clearInterval(timer);
          longBeep.play().then(() => {
            if (step >= sets * 2) {
              onClick();
              setTimeRun(false);
            } else {
              setCurrentTime((step - 1) % 2 !== 0 ? exerciseDuration : breakDuration);
              setStep(step + 1);
            }
          });
        }

        if (time <= 3 && time !== 0) {
          shortBeep.play();
        }
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeRun, currentTime]);

  return (
    <div className="pb-[90px]">
      <div className="mb-8 flex justify-between items-center">
        <div className="flex items-center h-6 cursor-pointer" onClick={onClick}>
          <img className="w-4 h-auto mr-1" src={ArrowBackIcon} />
          <span>{t('basics.back')}</span>
        </div>
      </div>
      <p className="text-4xl font-bold text-center mb-6">{name}</p>
      <div className="flex items-center justify-evenly mb-6">
        {!timeRun && <img className="h-10 cursor-pointer" src={MinusIcon} onClick={decreaseTime} />}
        <p className="text-[56px]">{formatDuration(currentTime)}</p>
        {!timeRun && (
          <img
            className="h-10 cursor-pointer"
            src={PlusIcon}
            onClick={() => setExerciseDuration(exerciseDuration + 5)}
          />
        )}
      </div>
      <p className="text-center">{t('workout.timerRunsOut', { name })}</p>
      <div className="mt-9">{displayTimes()}</div>
      <div className="w-2/3 xl:w-1/2 mt-12 m-auto">
        {!timeRun && <Button title={t('workout.beginExercise')} onClick={startTimer} image={PlayArrowIcon} />}
      </div>
    </div>
  );
};
