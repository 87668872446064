import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '../../common/components';

export const EmailConfirmationPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (localStorage.getItem('email')) localStorage.removeItem('email');

  return (
    <div className="text-left h-[calc(100%-152px)] flex flex-col justify-between">
      <div>
        <h1 className="mt-7 md:mt-3 md:mb-6 mb-8">{t('emailConfirmation.title')}</h1>
        <p className="mb-6 font-bold">{t('emailConfirmation.thanks')}</p>
        <p>{t('emailConfirmation.msg')}</p>
      </div>
      <Button title="Back to login" onClick={() => navigate('/login')} />
    </div>
  );
};
