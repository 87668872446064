import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { notificationApi } from 'features/notification/notificationAPI';
import { nutritionSlice, resetNutritionProgram } from 'features/nutrition/nutritionSlice';
import { onboardingApi } from 'features/onboarding/onboardingAPI';
import { paymentsApi } from 'features/payments/paymentsAPI';
import { paymentsSlice } from 'features/payments/paymentsSlice';
import { profileApi } from 'features/profile/profileAPI';
import { profileSlice } from 'features/profile/profileSlice';
import { progressApi } from 'features/progress/progressAPI';
import { progressSlice, resetProgresses } from 'features/progress/progressSlice';
import { resetWorkoutProgram, workoutSlice } from 'features/workout/workoutSlice';

import { authApi } from '../../features/auth/authAPI';
import { authSlice, resetUser } from '../../features/auth/authSlice';

const rtkRejectQueryMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      api.dispatch(resetUser());
      api.dispatch(resetWorkoutProgram());
      api.dispatch(resetNutritionProgram());
      api.dispatch(resetProgresses());
    }
  }
  return next(action);
};

export const store = configureStore({
  reducer: combineReducers({
    authSlice: authSlice.reducer,
    authApi: authApi.reducer,
    onboardingApi: onboardingApi.reducer,
    paymentsSlice: paymentsSlice.reducer,
    paymentsApi: paymentsApi.reducer,
    nutritionSlice: nutritionSlice.reducer,
    profileSlice: profileSlice.reducer,
    profileApi: profileApi.reducer,
    workoutSlice: workoutSlice.reducer,
    progressApi: progressApi.reducer,
    progressSlice: progressSlice.reducer,
    notificationApi: notificationApi.reducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkRejectQueryMiddleware)
      .concat(authApi.middleware)
      .concat(onboardingApi.middleware)
      .concat(paymentsApi.middleware)
      .concat(profileApi.middleware)
      .concat(progressApi.middleware)
      .concat(notificationApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
