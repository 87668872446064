import TrainGym from 'assets/images/TrainGym.JPG';
import TrainHome from 'assets/images/TrainHome.JPG';
import TrainStreet from 'assets/images/TrainStreet.jpg';

export const LocationItems = [
  {
    title: 'Gym',
    description: 'Du möchtest in einem Fitnesscenter trainieren!',
    image: TrainGym,
    value: 'gym',
  },
  {
    title: 'Zuhause',
    description: 'Du möchtest von zuhause aus trainieren!',
    image: TrainHome,
    value: 'home',
  },
  {
    title: 'Urban',
    description: 'Du möchtest im Park oder in Deiner Innenstadt trainieren!',
    image: TrainStreet,
    value: 'urban',
  },
];
