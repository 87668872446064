import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import { t } from 'i18next';

import { nutritionSelector } from 'features/nutrition/nutritionSlice';
import { workoutSelector } from 'features/workout/workoutSlice';
import { Button } from 'common/components';

import Close from 'assets/icons/Close.svg';
import Logo from 'assets/Logo-192x192.png';
import { replaceDataUrl } from '../offline-mode/queries/getData';
import { isPlatform } from '@ionic/core';

interface InformationProps {
  video: { url: string };
  description: string;
}

export const InformationPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const typeSearchParam = searchParams.get('type');
  const [activateControls, setActivateControls] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { nutritionProgram } = useAppSelector(nutritionSelector);
  const { workoutProgram } = useAppSelector(workoutSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const information: InformationProps = typeSearchParam === 'workout' ? workoutProgram : nutritionProgram;

  const [storedVideo, setVideo] = useState(information?.video.url || '');

  useEffect(() => {
    const getImageFromStorage = async (image: string) => {
      return replaceDataUrl(image);
    };
    if (information?.video.url) {
      getImageFromStorage(information.video.url).then((url: string) => {
        console.log(url);
        setVideo(url);
      });
    }
  }, [information?.video.url]);

  useEffect(() => {
    if (isPlatform('mobile')) {
      window.screen.orientation.unlock();
      return () => {
        window.screen.orientation.lock('portrait');
      };
    }
  }, []);

  useEffect(() => {
    if (storedVideo !== '') {
      setIsLoading(true);
      videoRef?.current?.load();
      return videoRef?.current?.addEventListener(
        'canplaythrough',
        function () {
          setIsLoading(false);
          setActivateControls(true);
        },
        false
      );
    }
  }, [storedVideo]);

  return (
    <div className="justify-between min-h-full pb-8 xl:pb-12">
      <img src={Close} className="md:hidden absolute right-5 w-8" onClick={() => navigate(-1)} />
      <div>
        <h1 className="mb-8">
          {typeSearchParam === 'workout' ? t('workout.information') : t('nutrition.information')}
        </h1>

        <div className={'relative'}>
          {isLoading && (
            <div className="absolute inset-0 flex justify-center items-center bg-loading z-[80]">
              <div className="animate-spin rounded-full h-16 w-16 border-4 border-b-red border-r-red border-t-red border-l-transparent"></div>
            </div>
          )}
          <video
            className="rounded-lg w-full h-[220px] md:h-[550px] object-cover"
            poster={Logo}
            ref={videoRef}
            preload="none"
            controls={activateControls}
            key={storedVideo}
          >
            <source src={storedVideo} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="min-h-[calc(100vh-372px)] md:min-h-0 flex flex-col justify-between">
        <div className="my-4 md:my-16 bb-inner-html" dangerouslySetInnerHTML={{ __html: information?.description }} />
        <Button title={t('onboarding.beginJourney')} onClick={() => navigate(-1)} responsive />
      </div>
    </div>
  );
};
