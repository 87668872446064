import React from 'react';

interface CheckboxProps {
  name: string;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  checked: boolean;
  error?: string | undefined;
  touched?: boolean | undefined;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  name,
  id,
  onChange,
  onBlur,
  checked,
  error,
  touched,
}) => {
  return (
    <input
      name={name}
      id={id}
      onChange={onChange}
      onBlur={onBlur}
      checked={checked}
      type="checkbox"
      className={`appearance-none w-5 h-5 focus:outline-none bg-secondary checked:bg-checkbox rounded-sm cursor-pointer
      ${error && touched ? 'border border-red' : ''}`}
    />
  );
};
