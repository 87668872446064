import { Button, LoadingOverlay, ModalsBackground } from 'common/components';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useUpdateUserMutation } from '../profileAPI';

export const DeletePopup: React.FunctionComponent<{ handleClose: () => void }> = ({ handleClose }) => {
  const [updateUser, { isSuccess, isLoading }] = useUpdateUserMutation();

  const confirmDeletion = async () => {
    const deletionDate = new Date();
    deletionDate.setDate(deletionDate.getDate() + 30);

    await updateUser({ deletionDate });
  };

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  return (
    <ModalsBackground>
      {isLoading && <LoadingOverlay />}
      <h1>{t('profile.deletingPopupTitle')}</h1>
      <h3>{t('profile.deletingPopupMessage')}</h3>
      <div className="flex">
        <Button title={t('basics.delete')} onClick={confirmDeletion} />
        <button className="w-full" onClick={handleClose}>
          {t('basics.dismiss')}
        </button>
      </div>
    </ModalsBackground>
  );
};
