import React from 'react';
import './styles.scss';

export const Terms = () => {
  return (
    <div>
      <div className="text-center">
        <p className="mb-2 text-lg">
          <strong>Urbanathletix GmbH</strong>
        </p>
        <p className="mb-2 text-lg">
          <strong>Kurfürstendamm 11</strong>
        </p>
        <p className="mb-2 text-lg">
          <strong>10719 Berlin</strong>
        </p>
        <p className="mb-2 text-lg">
          <strong>
            <a href="mailto: kontakt@urbanathletix.de" className="underline">
              kontakt@urbanathletix.de
            </a>
          </strong>
        </p>
        <p className="mb-2 text-lg">vertreten durch: Kenney Sey</p>
        <p className="mb-2 text-lg">Amtsgericht Charlottenburg: HRB 242749 B</p>
        <p className="mb-2 text-lg">
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE354710087
        </p>
        <p className="mb-2 text-lg">für Nutzer des Angebots von Urbanathletix.de</p>
        <p className="mb-2 text-lg">Stand: Juli 2022</p>
      </div>
      <div className="mt-10">
        <div className="bb-legal-formatting">
          <h2>Präambel</h2>
          <p>
            Die Urbanathletix GmbH, vertreten durch ihren Geschäftsführer Kenney Sey stellt über ihr Internetportal
            www.ubanathletix.de (im Folgenden: Datenbank) Online-Trainings und Ernährungspläne (im Folgenden: Inhalte)
            zur Verfügung. Diese können entgeltlich von jedermann angefordert und heruntergeladen werden. Des Weiteren
            werden Gutscheine (im Folgenden: Waren) für diese Inhalte über dritte Internetportale angeboten.
          </p>
          <p>
            Als Geschäftsbedingungen gelten die Bestimmungen des BGB. Der Richter wertet im Einzelfall. Die folgenden
            allgemeinen Geschäftsbedingungen regeln das Rechtsverhältnis zwischen dem Nutzer und der Urbanathletix GmbH
            und bilden die Grundlage für sämtliche Leistungen der Urbanathletix GmbH. Entgegenstehende kundenseitige
            Bedingungen werden nicht anerkannt, es sei denn die Urbanathletix GmbH hat diesen ausdrücklich schriftlich
            zugestimmt.
          </p>
        </div>
        <div className="bb-legal-formatting">
          <h2>Vertragsschluss</h2>
          <p>
            Die von der Urbanathletix GmbH angebotenen Inhalte und Waren stellen ein rechtsverbindliches Angebot an den
            Nutzer dar, diese Inhalte und Waren, in dem jeweils angegebenen Dateiformat, zu kaufen und gegebenenfalls
            herunterzuladen. Der Kaufvertrag kommt zustande, sobald der Nutzer dieses Angebot durch Abgabe seiner
            Bestellung annimmt. Die Inhalte sind ausschließlich online zu beziehen. Ein Anspruch auf Lieferung per Post,
            Fax, etc. besteht nicht. Technische und gestalterische Abweichungen von Beschreibungen und Angaben in
            Prospekten, Katalogen und schriftlichen Unterlagen bleiben vorbehalten, ohne, dass der Nutzer hieraus Rechte
            gegen die Urbanathletix GmbH herleiten kann. Nach Abschluss der Bestellung kann der Nutzer eine
            Online-Rechnung per E-Mail anfordern und darin seine Bestelldaten einsehen.
          </p>
          <p>
            Der Nutzer bestätigt die Richtigkeit und Vollständigkeit seiner angegebenen Daten. Ebenso hat er
            sicherzustellen, dass auf seiner Seite Voraussetzungen für den Empfang der Dateien und Inhalte gegeben sind.
            Urbanathletix GmbH nutzt für seinen Service die aktuell und allgemein verwendetenTechnologien. Um das
            Angebot von Urbanathletix GmbH in vollem Umfang nutzen zu können, muss der Nutzer ebenfalls diese
            Technologien (z. B. aktuelle Browsertechnologien, Adobe Flash) verwenden oder deren Verwendung auf seinem
            Computer ermöglichen (z.B. Aktivierung von Java Skript, Cookies, Pop-ups). Bei Benutzung älterer oder nicht
            allgemein gebräuchlicher Technologien kann es sein, dass der Nutzer die Leistungen von Urbanathletix GmbH
            nur eingeschränkt nutzen kann. Sind diese Voraussetzungen nicht erfüllt, ist dies ohne Einfluss auf das
            Zustandekommen des Vertrages zwischen dem Nutzer und der Urbanathletix GmbH.
          </p>
          <p>
            Abgesehen von den Screens vor dem Bezahlprozess ist die Nutzung der Inhalte für den Nutzer kostenpflichtig.
            Anfallende Verbindungskosten für den Zugang zur Datenbank trägt der Nutzer in jedem Fall selbst;
            insbesondere trägt er die anfallenden Telekommunikations- und Internetkosten. Entstehende Kosten aus
            Sonderleistungen sowie Leistungen aufgrund unrichtiger oder unvollständiger Nutzerangaben oder nicht
            nachprüfbarer Mängelrügen oder unsachgemäßen Systemgebrauchs sind in vollem Umfang vom Kunden zu tragen.
          </p>
          <p>
            Urbanathletix GmbH bietet bei technischen Problemen mit Inhalten in der Regel Support - der nicht zwingend
            durch Kenney Sey selbst erfolgen muss. Hierfür stellt die Urbanathletix GmbH die E-Mail-Adresse{' '}
            <a href="mailto: kontakt@urbanathletix.de" className="underline">
              kontakt@urbanathletix.de
            </a>{' '}
            zur Verfügung. Ein rechtlicher Anspruch auf Support kann daraus nicht abgeleitet werden. Da es sich um
            Online-Services handelt, kann dieser in Ausnahmefällen auch offline sein.
          </p>
        </div>
        <div className="bb-legal-formatting">
          <h2>Vertragslaufzeit, Kündigung</h2>
          <p>
            Die Laufzeit des Vertrages für die Waren von Urbanathletix GmbH betragen 14 Wochen, sofern nicht anders in
            den Produktbeschreibungen vermerkt ist, werden Verträge über die Nutzung der Datenbank auf unbestimmte Zeit
            geschlossen. Eine Kündigung des Vertrages seitens des Nutzers ist jederzeit ohne Angabe von Gründen zwei
            Wochen zum Monatsende möglich. Eine Rückerstattung des Kaufpreises erfolgt jedoch nur im Rahmen des
            Paragraphen &apos;Belehrung zum Widerrufsrecht&apos;
          </p>
          <p>
            Die Urbanathletix GmbH behält sich das Recht vor, bei erwiesenem Missbrauch oder Weitergabe der Zugangsdaten
            den Vertrag fristlos zu kündigen, den Nutzer zu sperren und entsprechende Schadensersatzansprüche geltend zu
            machen. Bei Lizenzmissbrauch hat die Urbanathletix GmbH das Recht Vertragsstrafen in Höhe des vierfachen
            Verkaufspreises geltend zu machen, wovon die Schadensersatzansprüche unberührt bleiben. Ferner ist die
            Urbanathletix GmbH berechtigt, Konten von Nutzern, die die Datenbank länger als 6 Monate nicht genutzt und
            keine Inhalte erworben haben, zu löschen. Auf Antrag des Betroffenen können Konten auch vorab gelöscht
            werden.
          </p>
          <p>
            Die Urbanathletix GmbH weist ausdrücklich darauf hin, dass je nach Vertragsabschluss die angegebenen Preise
            eine einmalige Zahlung oder keine einmalige Zahlung darstellen. Ist es keine einmalige Zahlung, handelt es
            sich um ein Abonnement.
          </p>
          <p>
            Bei einem Kauf über einen Appstore erhalten Privatkunden automatisch die Berechtigung ihre zeitlich
            fixierten Lizenzzugänge auf maximal 3 Endgeräten zu nutzen.
          </p>
        </div>
        <div className="bb-legal-formatting">
          <h2>Zahlungsbedingungen</h2>
          <p>
            Die Preise ergeben sich aus der jeweiligen Produktbeschreibungsseite und / oder dem jeweiligen
            Bestellformular. Alle angegebenen Preise sind inklusive der jeweils zum Zeitpunkt des Vertragsschlusses
            gesetzlich gültigen Mehrwertsteuer. Soweit laufende Leistungen geschuldet sind, ist der im Zeitpunkt der
            Fälligkeit der jeweiligen Forderung geltende Mehrwertsteuersatz entscheidend. Erfüllung erfolgt spätestens
            zwei Werktage nach Zahlungseingang.
          </p>
          <p>
            Der Nutzer hat bei der Bestellung dafür Sorge zu tragen, dass die Angaben sämtlicher Daten mit den
            gewünschten Daten auf der Rechnung übereinstimmen. Nachträgliche Änderungen auf Rechnungen können nicht
            erfolgen.
          </p>
          <p>
            Die Urbanathletix GmbH kann sich Diensten von Drittunternehmen bedienen, um Zahlungsvorgänge abzuwickeln.
            Sofern dies geschieht, wird das jeweils beauftragte Unternehmen im Bestellprozess bezeichnet. Zusätzlich zu
            den allgemeinen Geschäftsbedingungen von Urbanathletix GmbH gelten dann die allgemeinen Geschäftsbedingungen
            des beauftragten Unternehmens.
          </p>
          <p>
            Beim Kauf von Inhalten kann mit einem der angebotenen Zahlungsanbieter der Inhalt bezahlt werden. Die
            Belastung erfolgt in der Regel mit Abschluss der Bestellung.
          </p>
          <p>
            Alle Zahlungen sind sofort fällig. Es tritt Verzug ein, wenn 14 Tage nach Zustellung oder Fälligkeit der
            Rechnung oder wenn eine Abbuchung des Kaufpreises fehlschlägt oder die Abbuchung vom Nutzer ohne Rechtsgrund
            storniert wird. Ferner kann Urbanathletix GmbH unbeschadet aller sonstigen Rechte den Zugriff auf Inhalte
            unterbinden, solange der Nutzer sich im Verzug befindet. Ist der Nutzer im Verzug, ist die Urbanathletix
            GmbH berechtigt, den Kauf zu stornieren und die beiderseits empfangenen Leistungen zurück zu gewähren.
          </p>
          <p>
            Die Urbanathletix GmbH gerät erst dann in Verzug, wenn der Nutzer schriftlich eine Nachfrist von mindestens
            7 Tagen gesetzt hat. In diesem Fall hat der Nutzer höchstens einen Anspruch auf die Rückerstattung des
            gesamten Rechnungsbetrags der vom Verzug betroffenen Leistung. Darüberhinausgehende Ansprüche, insbesondere
            Schadensersatz- ansprüche sind ausgeschlossen. Der Nutzer ist nicht berechtigt, Zahlungen zurückzuhalten,
            sofern ihm nicht ein gesetzliches Zurückbehaltungsrecht zusteht.
          </p>
        </div>
        <div className="bb-legal-formatting">
          <h2>Belehrung zum Widerrufsrecht</h2>
          <p>
            <span>
              Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
            </span>
            <span>
              Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Urbanathletix GmbH, Kurfürstendamm
              11, D-10719 Berlin, E-Mail: kontakt@urbanathletix.de) mittels einer eindeutigen Erklärung (z. B. ein mit
              der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.
              Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
            </span>
            <span>
              Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
              Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </span>
          </p>
          <p>
            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
            einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie
            eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben),
            unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
            Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
            Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde
            ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte
            berechnet.
          </p>
          <p>
            Ihr Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von beiden Seiten erfüllt ist, bevor Sie Ihr
            Widerrufsrecht ausgeübt haben.
          </p>
          <p>
            Das Widerrufsrecht besteht nicht, wenn Sie Unternehmer gemäß § 14 Abs. 1 BGB sind und Sie in Ausübung ihrer
            gewerblichen oder selbständigen Tätigkeit handeln.
          </p>
          <p>
            Wenn Sie den Vertrag widerrufen wollen, dann können Sie dieses Formular ausfüllen und es z.B. per Post oder
            E- Mail an uns zurücksenden. Sie können aber auch ganz formlos z.B. per Post oder E-Mail widerrufen.
          </p>
          <p>
            <strong>Urbanathletix GmbH</strong>
          </p>
          <p>
            <strong>Kurfürstendamm 11</strong>
          </p>
          <p>
            <strong>10719 Berlin</strong>
          </p>
          <p className="mb-4">
            <strong>
              E-Mail:{' '}
              <a href="mailto: kontakt@urbanathletix.de" className="underline">
                kontakt@urbanathletix.de
              </a>
            </strong>
          </p>
          <p className="max-w-[550px]">
            <span>
              Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden
              Waren (*)/die Erbringung der folgenden Dienstleistung (*)
            </span>
            <span>Bestellt am ………………… /erhalten am …………………</span>
            <span>Name des/der Verbraucher(s)</span>
            <span>Anschrift des/der Verbraucher(s)</span>
            <span>Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier) Datum</span>
            <span>(*) Unzutreffendes streichen.</span>
          </p>
        </div>
        <div className="bb-legal-formatting">
          <h2>Partnerangebote und Links</h2>
          <p>
            Urbanathletix GmbH stellt dem Nutzer möglicherweise Links auf bzw. Inhalte von Drittseiten und / oder
            Partnerangebote zur Verfügung. Für die entsprechenden Inhalte, deren Rechtmäßigkeit und Richtigkeit ist
            Urbanathletix GmbH nicht verantwortlich. Insofern distanziert sich Urbanathletix GmbH vorsorglich von den
            dort angebotenen Inhalten. Im Übrigen gelten die jeweiligen Geschäftsbedingungen der Betreiber der
            verlinkten Drittseiten / Partnerangebote.
          </p>
        </div>
        <div className="bb-legal-formatting">
          <h2>Copyright</h2>
          <p>
            Alle Urheber-, Nutzungs- und sonstigen Schutzrechte an den Inhalten verbleiben bei Urbanathletix GmbH. Der
            Nutzer darf die Inhalte nur zu eigenen Zwecken nutzen und ist nicht berechtigt, sie im Internet oder auf
            sonstige Weise Dritten zur Verfügung zu stellen, sei es entgeltlich oder unentgeltlich, soweit nicht der
            Zweck der Inhalte dies gebietet. Die gewerbliche Vervielfältigung und der Weiterverkauf der Inhalte weder im
            Ganzen noch in Teilen sind ausgeschlossen. Dem Nutzer ist es nicht gestattet, vorhandene Kennzeichnungen,
            Schutzrechtvermerke oder Eigentumshinweise des Anbieters in der Software und in gespeicherten Daten zu
            beseitigen. Urbanathletix GmbH ist berechtigt, auf eigene Kosten notwendige Änderungen aufgrund der
            Schutzrechtsbehauptungen Dritter durchzuführen. Der Nutzer kann hieraus keine vertraglichen Rechte ableiten.
          </p>
          <p>
            Die Datenbank enthält Inhalte von Drittanbietern. Diese sind gesondert gekennzeichnet und unterliegen den
            Copyrightbestimmungen des jeweiligen Anbieters, die unter den angegebenen Links abgerufen werden können.
            Zusätzlich zu den Copyrightbestimmungen der Urbanathletix GmbH gelten dann die Bestimmungen des jeweiligen
            Unternehmens.
          </p>
          <p>
            Der Nutzer haftet gegenüber Urbanathletix GmbH für alle Schäden, die sich aus der Verletzung der
            vorgenannten Verpflichtungen ergeben. Urbanathletix GmbH behält sich das Recht von stichprobenartigen
            Kontrollen zur Kontrolle der korrekten Verwendung bei unternehmerisch tätigen Kunden.
          </p>
        </div>
        <div className="bb-legal-formatting">
          <h2>Gewährleistung und Haftung</h2>
          <p>
            Urbanathletix GmbH wendet größte Sorgfalt bei der Erstellung und Pflege der Inhalte auf. Gleichwohl kann
            Urbanathletix GmbH keine Gewähr dafür übernehmen, dass die Inhalte für die konkreten Bedürfnisse des Nutzers
            passend und richtig sind. Der Nutzer wählt die Inhalte eigenverantwortlich aus und verwendet diese
            eigenverantwortlich. Die Haftung von Urbanathletix GmbH für die falsche Verwendung der Inhalte ist
            ausgeschlossen. Jegliches kopieren oder duplizieren der angebotenen Inhalte ist untersagt und bedarf der
            schriftlichen Zustimmung von Urbanathletix GmbH.
          </p>
          <p>
            Die angebotenen Inhalte dienen lediglich als Ergänzung des eigenen Sportmanagements. Der Nutzer wird deshalb
            aufgefordert Übungen sorgfältig, mit höchster Präzision und gesundheitsschonend auszuüben und bei der
            Verwendung der Ernährunspläne die eigenen gesundheitlichen Individualitäten (Allergien etc.) vorab dediziert
            zu prüfen, bestenfalls ärztlichen Rat hinzuzuziehen, um keine Gefahr für Leib und Leben zu erzeugen.
            Unterlässt der Nutzer dies, entsteht aufgrund dessen ein Schaden, ist die Haftung von Urbanathletix GmbH
            hierfür ausgeschlossen. Trainingserfolge liegen in der Verantwortung des Nutzers und können nicht von
            Urbanathletix GmbH garantiert werden.
          </p>
          <p>
            Die Haftung von Urbanathletix GmbH ist im Übrigen auf Vorsatz und grobe Fahrlässigkeit beschränkt. Davon
            ausgenommen ist die Haftung für Schäden an Körper, Leben und Gesundheit, für die die gesetzlichen
            Haftungsregeln uneingeschränkt gelten. Des Weiteren steht Urbanathletix GmbH nicht für Fehler, Störungen
            oder Schäden ein, die auf unsachgemäße Bedienung, Verwendung ungeeigneter Betriebsmittel oder unübliche
            Betriebsbedingungen zurückzuführen sind.
          </p>
          <p>
            Fehler in EDV Programmen können nach dem Stand der Technik und unter Aufwendung größter Sorgfalt niemals
            vollkommen ausgeschlossen werden. Der Nutzer nimmt von diesem Umstand hiermit Kenntnis.
          </p>
          <p>
            Sollten Mängel auftreten, unterrichtet der Nutzer Urbanathletix GmbH umgehend mit einer genauen
            Mängelbeschreibung. Wenn diese Mängel nicht auf fehlerhafte Bedienung oder Störungen zurückzuführen sind,
            die Urbanathletix GmbH nicht zu vertreten hat, wird Urbanathletix GmbH die Mängel anschließend in
            angemessener Zeit beseitigen.
          </p>
          <p>
            Im Übrigen gelten die gesetzlichen Gewährleistungsvorschriften. Die vertragliche Mängelhaftung ist mit
            Abschluss des Vertrages auf 6 Monate beschränkt. Für erfolgte Mängelbeseitigungsmaßnahmen gewährt
            Urbanathletix GmbH eine zusätzliche Mängelhaftung von sechs Monaten ab dem Zeitpunkt der Durchführung.
          </p>
        </div>
        <div className="bb-legal-formatting">
          <h2>Schlussbestimmungen</h2>
          <p>
            Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des internationalen
            Privatrechts und des UN-Kaufrechts. Die Angebote der Urbanathletix GmbH gelten ausschließlich für
            Deutschland. Vertragsabschlüsse mit Nutzern, die Ihren Sitz in anderen Ländern haben, behält sich
            Urbanathletix GmbH für den Einzelfall vor.
          </p>
          <p>
            Urbanathletix GmbH behält sich das Recht vor, Kundenanträge im Einzelfall ohne Angabe von Gründen
            abzulehnen.
          </p>
          <p>
            Urbanathletix GmbH kann diese Vertragsbedingungen jederzeit ändern. In diesem Fall könnten die jetzt auf
            Ihre Bestellung anwendbaren AGB zu einem späteren Zeitpunkt nicht mehr abrufbar sein. Sie haben die
            Möglichkeit die aktuellen AGB am Ende des Dokuments zu speichern.
          </p>
          <p>
            Die Nichtigkeit oder Unwirksamkeit einzelner Bestimmungen dieses Vertrags berühren die Gültigkeit der
            übrigen Bestimmungen nicht. Sie haben nicht die Nichtigkeit oder Unwirksamkeit des gesamten Vertrags zur
            Folge. Die nichtigen oder unwirksamen Bestimmungen sind so umzudeuten, dass der mit ihnen beabsichtigte
            wirtschaftliche Zweck erreicht wird. Ist eine Umdeutung nicht möglich, sind die Vertragschließenden
            verpflichtet, eine Vereinbarung zu treffen, die dem wirtschaftlichen Zweck der nichtigen oder unwirksamen
            Bestimmung möglichst nahekommt.
          </p>
        </div>
      </div>
    </div>
  );
};
