import React from 'react';

import CheckedIcon from 'assets/icons/Checked.svg';

interface BodyTypeItemProps {
  title: string;
  description: string;
  male: string;
  female: string;
  value: string;
  handleClick: (selectedValue: string) => void;
  isActive: boolean;
  gender: string;
}

export const BodyTypeItem: React.FunctionComponent<BodyTypeItemProps> = ({
  title,
  description,
  male,
  female,
  value,
  handleClick,
  isActive,
  gender,
}) => {
  return (
    <div className="relative h-full cursor-pointer" onClick={() => handleClick(value)}>
      {isActive && <img className="absolute right-5 top-5" src={CheckedIcon} />}
      <img
        src={gender === 'male' ? male : female}
        className={`rounded-lg h-full w-full object-cover ${isActive ? 'border-4 border-white' : ''}`}
      />
      <div className="absolute bottom-3 left-3 right-3 bg-label p-2.5 h-36 rounded-md">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};
