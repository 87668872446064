import { NutritionProgram } from 'features/nutrition/types';
import { WorkoutProgram } from 'features/workout/types';
import { dbName } from '../constants';
import { jsonSQLite } from '../types';

export const dataSchemes = (
  data: { workout: WorkoutProgram; nutrition: NutritionProgram },
  offlineSettings: { isOfflineMode: boolean; dataSize: number }
) => {
  const { version: workoutVersion, ...workoutRest } = data.workout;
  const { version: nutritionVersion, ...nutritionRest } = data.nutrition;

  const tables = [
    {
      name: 'workoutProgram',
      schema: [
        { column: 'workout', value: 'LONGTEXT' },
        { column: 'version', value: 'INTEGER' },
        // { column: 'last_modified', value: 'INTEGER' },
      ],
      values: [[JSON.stringify(workoutRest), workoutVersion]],
    },
    {
      name: 'nutritionProgram',
      schema: [
        { column: 'nutrition', value: 'LONGTEXT' },
        { column: 'version', value: 'INTEGER' },
        // { column: 'last_modified', value: 'INTEGER' },
      ],
      values: [[JSON.stringify(nutritionRest), nutritionVersion]],
    },
    {
      name: 'offlineMode',
      schema: [
        { column: 'isOfflineMode', value: 'TEXT' },
        { column: 'dataSize', value: 'NUMBER' },
      ],
      values: [[JSON.stringify(offlineSettings.isOfflineMode), offlineSettings.dataSize]],
    },
  ];

  const workoutDataJson: jsonSQLite = {
    database: dbName,
    version: 1,
    overwrite: true,
    encrypted: false,
    mode: 'full',
    tables,
  };

  return workoutDataJson;
};
