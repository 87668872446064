import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState } from 'features/auth/types';
import { Progresses, ProgressItem } from './types';

export const progressApi = createApi({
  reducerPath: 'progressApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/progresses`,
    prepareHeaders: (headers, { getState }) => {
      const { authSlice } = getState() as { authSlice: AuthState };
      if (authSlice.jwt) headers.set('Authorization', `Bearer ${authSlice.jwt}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProgress: builder.query<Progresses, void>({
      query: () => {
        return {
          url: '',
        };
      },
    }),
    createProgress: builder.mutation<ProgressItem, FormData>({
      query: (body) => ({
        method: 'POST',
        url: '',
        body,
      }),
    }),
    deleteProgress: builder.mutation<{ id: number }, number>({
      query: (id) => ({
        method: 'DELETE',
        url: `/${id}`,
      }),
    }),
  }),
});

export const { useGetProgressQuery, useCreateProgressMutation, useDeleteProgressMutation } = progressApi;
