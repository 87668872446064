import React from 'react';
import CheckedIcon from 'assets/icons/Checked.svg';

interface LocationItemProps {
  title: string;
  description: string;
  image: string;
  value: string;
  handleClick: (selectedValue: string) => void;
  isActive: boolean;
}

export const LocationItem: React.FunctionComponent<LocationItemProps> = ({
  title,
  description,
  image,
  value,
  handleClick,
  isActive,
}) => {
  return (
    <div className="relative w-full md:w-[32%] h-[32%] md:h-full cursor-pointer" onClick={() => handleClick(value)}>
      {isActive && <img className="absolute right-5 top-5" src={CheckedIcon} />}
      <img src={image} className={`rounded-lg h-full w-full object-cover ${isActive ? 'border-4 border-white' : ''}`} />
      <div className="absolute bottom-2 left-2 right-2 bg-label p-2.5 rounded-md">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};
