import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthState, User } from 'features/auth/types';
import { OfflineModeData, UpdateUserBody } from './types';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/`,
    prepareHeaders: (headers, { getState }) => {
      const { authSlice } = getState() as { authSlice: AuthState };
      if (authSlice.jwt) headers.set('Authorization', `Bearer ${authSlice.jwt}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    updateUser: builder.mutation<User, UpdateUserBody>({
      query: (body) => ({
        method: 'PUT',
        url: 'users/me',
        body,
      }),
    }),
    getInfoForOffline: builder.query<OfflineModeData, void>({
      query: () => {
        return {
          url: 'offline-mode',
        };
      },
    }),
  }),
});

export const { useUpdateUserMutation, useGetInfoForOfflineQuery, useLazyGetInfoForOfflineQuery } = profileApi;
