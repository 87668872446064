import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import App from './App';
import { store } from './app/store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://c7ec7771a6a34624b0cd3ebafd2dbd8b@o1252548.ingest.sentry.io/6554886',
  integrations: [new BrowserTracing()],
  release: 'urbanathletix@' + process.env.npm_package_version,
  dist: '1',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
