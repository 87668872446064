import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store/store';
import { NutritionProgram } from './types';

const initialState: { nutritionProgram: NutritionProgram | null } = {
  nutritionProgram: null,
};

export const nutritionSlice = createSlice({
  name: 'nutrition',
  initialState,
  reducers: {
    setNutritionProgram: (state, { payload }) => {
      state.nutritionProgram = payload;
    },
    resetNutritionProgram: (state) => {
      state.nutritionProgram = null;
    },
  },
});

export const { setNutritionProgram, resetNutritionProgram } = nutritionSlice.actions;
export const nutritionSelector = (state: RootState) => state.nutritionSlice;
