import { t } from 'i18next';
import React, { FunctionComponent } from 'react';

import { MealInformation } from 'common/components';

interface MealElementProps {
  image: string;
  title: string;
  duration: number;
  difficulty: string;
  onClick: () => void;
  isActive: boolean;
}

export const MealElement: FunctionComponent<MealElementProps> = ({
  image,
  title,
  duration,
  difficulty,
  onClick,
  isActive,
}) => {
  return (
    <div
      className={`relative w-full h-30 md:h-[144px] cursor-pointer ${
        isActive && 'border-4 border-main-red rounded-lg'
      }`}
      onClick={onClick}
    >
      <img className="w-full h-full rounded-[20px] object-cover" src={image} />
      <div className="absolute bottom-2 left-2 w-[calc(100%-16px)] bg-main-opacity rounded-ml p-2">
        <p className="text-left font-bold text-lg">{title}</p>
        <MealInformation duration={duration} difficulty={t(`basics.${difficulty}`)} />
      </div>
    </div>
  );
};
