import React from 'react';
import './styles.scss';

export const PrivacyPolicy = () => {
  return (
    <div className="bb-privacy-policy-formatting">
      <h1>Datenschutzerklärung</h1>
      <div>
        <h2>Einleitung</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen
          Daten (nachfolgend auch kurz als &quot;Daten&quot; bezeichnet) wir zu welchen Zwecken und in welchem Umfang
          verarbeiten. Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener
          Daten, sowohl im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in
          mobilen Applikationen sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile
          (nachfolgend zusammenfassend bezeichnet als &quot;Onlineangebot&quot;).
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 29. Juli 2022</p>
      </div>
      <div>
        <h2>Inhaltsübersicht</h2>
        <ul>
          <li>Einleitung</li>
          <li>Verantwortlicher</li>
          <li>Übersicht der Verarbeitungen</li>
          <li>Maßgebliche Rechtsgrundlagen</li>
          <li>Sicherheitsmaßnahmen</li>
          <li>Übermittlung von personenbezogenen Daten</li>
          <li>Datenverarbeitung in Drittländern</li>
          <li>Löschung von Daten</li>
          <li>Geschäftliche Leistungen</li>
          <li>Einsatz von Online-Plattformen zu Angebots und Vertriebszwecken</li>
          <li>Zahlungsverfahren</li>
          <li>Bereitstellung des Onlineangebotes und Webhosting</li>
          <li>Besondere Hinweise zu Applikationen (Apps)</li>
          <li>Bezug von Applikationen über Appstores</li>
          <li>Registrierung, Anmeldung und Nutzerkonto</li>
          <li>Kontakt- und Anfragenverwaltung</li>
          <li>Push-Nachrichten</li>
          <li>Cloud-Dienste</li>
          <li>Newsletter und elektronische Benachrichtigungen</li>
          <li>Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</li>
          <li>Kundenrezensionen und Bewertungsverfahren</li>
          <li>Änderung und Aktualisierung der Datenschutzerklärung</li>
          <li>Rechte der betroffenen Personen</li>
          <li>Begriffsdefinitionen</li>
        </ul>
      </div>
      <div>
        <h2>Verantwortlicher</h2>
        <p>
          <span>Urbanathletix GmbH</span>
          <span>Kurfürstendamm 11</span>
          <span>10719 Berlin</span>
        </p>
        <p>Vertretungsberechtigte Personen:</p>
        <p>Kenney Sey</p>
        <p>E-Mail-Adresse:</p>
        <p>
          <a href="mailto: kontakt@urbanathletix.de" className="underline">
            kontakt@urbanathletix.de
          </a>
        </p>
      </div>
      <div>
        <h2>Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen
          und verweist auf die betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>Bestandsdaten.</li>
          <li>Zahlungsdaten.</li>
          <li>Kontaktdaten.</li>
          <li>Inhaltsdaten.</li>
          <li>Vertragsdaten.</li>
          <li>Nutzungsdaten.</li>
          <li>Meta-/Kommunikationsdaten.</li>
          <li>Bild- und/oder Videoaufnahmen.</li>
        </ul>
        <h3>Besondere Kategorien von Daten</h3>
        <ul>
          <li>Gesundheitsdaten.</li>
          <li>Daten zum Sexualleben oder der sexuellen Orientierung.</li>
          <li>Religiöse oder weltanschauliche Überzeugungen.</li>
          <li>Daten, aus denen die rassische und ethnische Herkunft hervorgehen.</li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>Kunden.</li>
          <li>Beschäftigte.</li>
          <li>Interessenten.</li>
          <li>Kommunikationspartner.</li>
          <li>Nutzer.</li>
          <li>Geschäfts- und Vertragspartner.</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>Erbringung vertraglicher Leistungen und Kundenservice.</li>
          <li>Kontaktanfragen und Kommunikation.</li>
          <li>Sicherheitsmaßnahmen.</li>
          <li>Direktmarketing.</li>
          <li>Büro- und Organisationsverfahren.</li>
          <li>Verwaltung und Beantwortung von Anfragen.</li>
          <li>Feedback.</li>
          <li>Marketing.</li>
          <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
          <li>Informationstechnische Infrastruktur.</li>
        </ul>
        <h3>Maßgebliche Rechtsgrundlagen</h3>
        <p>
          Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO, auf deren Basis wir personenbezogene
          Daten verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten können. Sollten ferner im Einzelfall
          speziellere Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
        </p>
        <ul>
          <li>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die betroffene Person hat ihre
            Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen spezifischen Zweck
            oder mehrere bestimmte Zwecke gegeben.
          </li>
          <li>
            <strong>Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO)</strong> - Die
            Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.
          </li>
          <li>
            <strong>Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)</strong> - Die Verarbeitung ist zur
            Erfüllung einer rechtlichen Verpflichtung erforderlich, der der Verantwortliche unterliegt.
          </li>
          <li>
            <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)</strong> - Die Verarbeitung ist zur
            Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
            Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener
            Daten erfordern, überwiegen.
          </li>
        </ul>
        <p>
          Zusätzlich zu den Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum
          Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener
          Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
          Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung
          besonderer Kategorien personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur Übermittlung sowie
          automatisierten Entscheidungsfindung im Einzelfall einschließlich Profiling. Des Weiteren regelt es die
          Datenverarbeitung für Zwecke des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die
          Begründung, Durchführung oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von
          Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.
        </p>
      </div>
      <div>
        <h2>Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
          Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
          unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten
          natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
          Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
          Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden
          Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
          wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen
          auf die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten
          bereits bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
          Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot übermittelten Daten zu schützen, nutzen wir
          eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der
          Adresszeile Ihres Browsers.
        </p>
      </div>
      <div>
        <h2>Übermittlung von personenbezogenen Daten</h2>
        <p>
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen,
          Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen
          gegenüber offengelegt werden. Zu den Empfängern dieser Daten können z.B. mit IT-Aufgaben beauftragte
          Dienstleister oder Anbieter von Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören. In
          solchen Fall beachten wir die gesetzlichen Vorgaben und schließen insbesondere entsprechende Verträge bzw.
          Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
        </p>
      </div>
      <div>
        <h2>Datenverarbeitung in Drittländern</h2>
        <p>
          Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen
          Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
          oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet,
          erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
        </p>
        <p>
          Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung
          verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau,
          vertraglichen Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, beim Vorliegen von
          Zertifizierungen oder verbindlicher internen Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,
          Informationsseite der EU-Kommission:{' '}
          <a
            href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de"
            className="underline"
          >
            https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de
          </a>
          ).
        </p>
      </div>
      <div>
        <h2>Löschung von Daten</h2>
        <p>
          Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur
          Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B. wenn der
          Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind). Sofern die
          Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
          Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke
          verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen
          oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
          Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
        </p>
        <p>
          Im Rahmen unserer Datenschutzhinweise können wir den Nutzern weitere Informationen zu der Löschung sowie zu
          der Aufbewahrung von Daten mitteilen, die speziell für die jeweiligen Verarbeitungsprozesses gelten.
        </p>
      </div>
      <div>
        <h2>Geschäftliche Leistungen</h2>
        <p>
          Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und Interessenten (zusammenfassend
          bezeichnet als &quot;Vertragspartner&quot;) im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen
          sowie damit verbundenen Maßnahmen und im Rahmen der Kommunikation mit den Vertragspartnern (oder
          vorvertraglich), z.B., um Anfragen zu beantworten.
        </p>
        <p>
          Wir verarbeiten diese Daten, um unsere vertraglichen Verpflichtungen zu erfüllen. Dazu gehören insbesondere
          die Verpflichtungen zur Erbringung der vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe
          bei Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus verarbeiten wir die Daten zur Wahrung
          unserer Rechte und zum Zwecke der mit diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
          Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten auf Grundlage unserer berechtigten
          Interessen an einer ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung sowie an
          Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner und unseres Geschäftsbetriebes vor Missbrauch,
          Gefährdung ihrer Daten, Geheimnisse, Informationen und Rechte (z.B. zur Beteiligung von Telekommunikations-,
          Transport- und sonstigen Hilfsdiensten sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
          Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden Rechts geben wir die Daten von
          Vertragspartnern nur insoweit an Dritte weiter, als dies für die vorgenannten Zwecke oder zur Erfüllung
          gesetzlicher Pflichten erforderlich ist. Über weitere Formen der Verarbeitung, z.B. zu Marketingzwecken,
          werden die Vertragspartner im Rahmen dieser Datenschutzerklärung informiert.
        </p>
        <p>
          Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder im Rahmen
          der Datenerhebung, z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole (z.B.
          Sternchen o.ä.), oder persönlich mit.
        </p>
        <p>
          Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.h.,
          grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert werden,
          z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen. Die gesetzliche
          Aufbewahrungsfrist beträgt bei steuerrechtlich relevanten Unterlagen sowie bei Handelsbüchern, Inventaren,
          Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser Unterlagen erforderlichen Arbeitsanweisungen
          und sonstigen Organisationsunterlagen und Buchungsbelegen zehn Jahre sowie bei empfangenen Handels- und
          Geschäftsbriefen und Wiedergaben der abgesandten Handels- und Geschäftsbriefe sechs Jahre. Die Frist beginnt
          mit Ablauf des Kalenderjahres, in dem die letzte Eintragung in das Buch gemacht, das Inventar, die
          Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht aufgestellt, der Handels- oder Geschäftsbrief
          empfangen oder abgesandt worden oder der Buchungsbeleg entstanden ist, ferner die Aufzeichnung vorgenommen
          worden ist oder die sonstigen Unterlagen entstanden sind.
        </p>
        <p>
          Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im Verhältnis
          zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der jeweiligen
          Drittanbieter oder Plattformen.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten (z.B.
            Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern); Vertragsdaten
            (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
            Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kunden; Interessenten; Geschäfts- und Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice;
            Sicherheitsmaßnahmen; Kontaktanfragen und Kommunikation; Büro- und Organisationsverfahren; Verwaltung und
            Beantwortung von Anfragen.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO); Berechtigte Interessen (Art. 6 Abs.
            1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Kundenkonto:</strong> Vertragspartner können innerhalb unseres Onlineangebotes ein Konto anlegen
            (z.B. Kunden- bzw. Nutzerkonto, kurz &quot;Kundenkonto&quot;). Falls die Registrierung eines Kundenkontos
            erforderlich ist, werden Vertragspartner hierauf ebenso hingewiesen wie auf die für die Registrierung
            erforderlichen Angaben. Die Kundenkonten sind nicht öffentlich und können von Suchmaschinen nicht indexiert
            werden. Im Rahmen der Registrierung sowie anschließender Anmeldungen und Nutzungen des Kundenkontos
            speichern wir die IP-Adressen der Kunden nebst den Zugriffszeitpunkten, um die Registrierung nachweisen und
            etwaigem Missbrauch des Kundenkontos vorbeugen zu können. Wenn Kunden ihr Kundenkonto gekündigt haben,
            werden die das Kundenkonto betreffenden Daten gelöscht, vorbehaltlich, deren Aufbewahrung ist aus
            gesetzlichen Gründen erforderlich. Es obliegt den Kunden, ihre Daten bei erfolgter Kündigung des
            Kundenkontos zu sichern; Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
            1 lit. b) DSGVO).
          </li>
          <li>
            <strong>Fitness- und Ernährungsprogramm:</strong> ; Rechtsgrundlagen: Vertragserfüllung und vorvertragliche
            Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO) Wir verarbeiten die Daten unserer Klienten sowie Interessenten
            und anderer Auftraggeber oder Vertragspartner (einheitlich bezeichnet als &quot;Klienten&quot;), um ihnen
            gegenüber unsere Leistungen erbringen zu können. Die verarbeiteten Daten, die Art, der softUmfang, der Zweck
            und die Erforderlichkeit ihrer Verarbeitung bestimmen sich nach dem zugrundeliegenden Vertrags- und
            Klientenverhältnis. Im Rahmen unserer Tätigkeit können wir ferner besondere Kategorien von Daten, hier
            insbesondere Angaben zur persönlichen Fitness, Aktivitätslevel und Körperdaten verarbeiten. Sofern es für
            unsere Vertragserfüllung, zum Schutz lebenswichtiger Interessen oder gesetzlich erforderlich ist, bzw. eine
            Einwilligung der Klienten vorliegt, offenbaren oder übermitteln wir die Daten der Klienten unter Beachtung
            der berufsrechtlichen Vorgaben an Dritte oder Beauftragte, wie z.B. Behörden, Abrechnungsstellen sowie im
            Bereich der IT, der Büro- oder vergleichbarer Dienstleistungen.
          </li>
        </ul>
      </div>
      <div>
        <h2>Einsatz von Online-Plattformen zu Angebots- und Vertriebszwecken</h2>
        <p>
          Wir bieten unsere Leistungen auf Online-Plattformen, die von anderen Dienstanbietern betrieben werden, an. In
          diesem Zusammenhang gelten zusätzlich zu unseren Datenschutzhinweisen die Datenschutzhinweise der jeweiligen
          Plattformen. Dies gilt insbesondere im Hinblick auf die Durchführung des Zahlungsvorgangs und der auf den
          Plattformen eingesetzten Verfahren zur Reichweitemessung und zum interessensbezogenen Marketing.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten (z.B.
            Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern); Vertragsdaten
            (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
            Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kunden.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice; Marketing.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Google Play:</strong> App- und Softwareverkaufsplattform; Rechtsgrundlagen: Berechtigte Interessen
            (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street,
            Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
            Website:{' '}
            <a href="https://play.google.com/store/apps?hl=de" className="underline">
              https://play.google.com/store/apps?hl=de
            </a>
            ; Datenschutzerklärung:{' '}
            <a href="https://policies.google.com/privacy" className="underline">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Apple App Store:</strong> App- und Softwareverkaufsplattform; <strong>Dienstanbieter:</strong> Apple
            Inc., Infinite Loop, Cupertino, CA 95014, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
            (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://www.apple.com/de/ios/app-store/" className="underline">
              https://www.apple.com/de/ios/app-store/
            </a>
            ; <strong>Datenschutzerklärung:</strong>
            <a href="https://www.apple.com/legal/privacy/de-ww/" className="underline">
              https://www.apple.com/legal/privacy/de-ww/
            </a>
            .
          </li>
        </ul>
      </div>
      <div>
        <h2>Zahlungsverfahren</h2>
        <p>
          Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund gesetzlicher Pflichten oder sonst auf
          Grundlage unserer berechtigten Interessen bieten wir den betroffenen Personen effiziente und sichere
          Zahlungsmöglichkeiten an und setzen hierzu neben Banken und Kreditinstituten weitere Dienstleister ein
          (zusammenfassend &quot;Zahlungsdienstleister&quot;).
        </p>
        <p>
          Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name und die
          Adresse, Bankdaten, wie z.B. Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie die
          Vertrags-, Summen- und empfängerbezogenen Angaben. Die Angaben sind erforderlich, um die Transaktionen
          durchzuführen. Die eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei
          diesen gespeichert. D.h., wir erhalten keine konto- oder kreditkartenbezogenen Informationen, sondern
          lediglich Informationen mit Bestätigung oder Negativbeauskunftung der Zahlung. Unter Umständen werden die
          Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt
          die Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und die Datenschutzhinweise der
          Zahlungsdienstleister.
        </p>
        <p>
          Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen
          Zahlungsdienstleister, welche innerhalb der jeweiligen Webseiten bzw. Transaktionsapplikationen abrufbar sind.
          Wir verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung von Widerrufs-, Auskunfts-
          und anderen Betroffenenrechten.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten (z.B.
            Bankverbindungen, Rechnungen, Zahlungshistorie); Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
            Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Kontaktdaten (z.B. E-Mail,
            Telefonnummern).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kunden; Interessenten.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Apple Pay:</strong> Zahlungsdienstleistungen (technische Anbindung von Online-Bezahlmethoden);{' '}
            <strong>Dienstanbieter:</strong> Apple Inc., Infinite Loop, Cupertino, CA 95014, USA;{' '}
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://www.apple.com/de/apple-pay/" className="underline">
              https://www.apple.com/de/apple-pay/
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://www.apple.com/legal/privacy/de-ww/" className="underline">
              https://www.apple.com/legal/privacy/de-ww/
            </a>
            .
          </li>
          <li>
            <strong>Google Pay:</strong> Zahlungsdienstleistungen (technische Anbindung von Online-Bezahlmethoden);
            <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
            Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;{' '}
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://pay.google.com/intl/de_de/about/" className="underline">
              https://pay.google.com/intl/de_de/about/
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://policies.google.com/privacy" className="underline">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>PayPal:</strong> Zahlungsdienstleistungen (technische Anbindung von Online-Bezahlmethoden) (z.B.
            PayPal, PayPal Plus, Braintree); <strong>Dienstanbieter:</strong> PayPal (Europe) S.à r.l. et Cie, S.C.A.,
            22-24 Boulevard Royal, L-2449 Luxembourg; <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://www.paypal.com/de" className="underline">
              https://www.paypal.com/de
            </a>
            ; Datenschutzerklärung:{' '}
            <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full" className="underline">
              https://www.paypal.com/de/webapps/mpp/ua/privacy-full
            </a>
            .
          </li>
          <li>
            <strong>Stripe:</strong> Zahlungsdienstleistungen (technische Anbindung von Online-Bezahlmethoden);{' '}
            <strong>Dienstanbieter:</strong> Stripe, Inc., 510 Townsend Street, San Francisco, CA 94103, USA;{' '}
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://stripe.com" className="underline">
              https://stripe.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://stripe.com/de/privacy" className="underline">
              https://stripe.com/de/privacy
            </a>
            .
          </li>
        </ul>
      </div>
      <div>
        <h2>Bereitstellung des Onlineangebotes und Webhosting</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur Verfügung stellen zu können. Zu
          diesem Zweck verarbeiten wir die IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen
          unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu übermitteln.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Inhaltsdaten (z.B.
            Eingaben in Onlineformularen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten); Kunden;
            Geschäfts- und Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit;
            Informationstechnische Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und technischen
            Geräten (Computer, Server etc.).); Sicherheitsmaßnahmen; Erbringung vertraglicher Leistungen und
            Kundenservice.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Bereitstellung Onlineangebot auf gemietetem Speicherplatz:</strong> Für die Bereitstellung unseres
            Onlineangebotes nutzen wir Speicherplatz, Rechenkapazität und Software, die wir von einem entsprechenden
            Serveranbieter (auch &quot;Webhoster&quot; genannt) mieten oder anderweitig beziehen;{' '}
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
          <li>
            <strong>Erhebung von Zugriffsdaten und Logfiles:</strong> Der Zugriff auf unser Onlineangebot wird in Form
            von so genannten &quot;Server-Logfiles&quot; protokolliert. Zu den Serverlogfiles können die Adresse und
            Name der abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung
            über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
            besuchte Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles können
            zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der Server zu vermeiden
            (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum anderen, um die
            Auslastung der Server und ihre Stabilität sicherzustellen; <strong>Rechtsgrundlagen:</strong> Berechtigte
            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Löschung von Daten:</strong> Logfile-Informationen
            werden für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder anonymisiert. Daten, deren
            weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
            Vorfalls von der Löschung ausgenommen.
          </li>
          <li>
            <strong>Sentry:</strong> Überwachung der Systemstabilität und Ermittlung von Codefehlern - Angaben zum Gerät
            oder Fehlerzeitpunkt werden pseudonym erhoben und werden anschließend gelöscht;{' '}
            <strong>Dienstanbieter:</strong> Functional Software Inc., Sentry, 132 Hawthorne Street, San Francisco,
            California 94107, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
            DSGVO); <strong>Website:</strong>{' '}
            <a href="https://sentry.io" className="underline">
              https://sentry.io
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://sentry.io/privacy" className="underline">
              https://sentry.io/privacy
            </a>
            ;{' '}
            <strong>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
            </strong>
            <a href="https://sentry.io/legal/dpa/" className="underline">
              https://sentry.io/legal/dpa/
            </a>
            .
          </li>
          <li>
            <strong>Vercel:</strong> Leistungen auf dem Gebiet der Bereitstellung von informationstechnischer
            Infrastruktur und verbundenen Dienstleistungen (z.B. Speicherplatz und/oder Rechenkapazitäten) sowie
            Entwicklungsumgebung; <strong>Dienstanbieter:</strong> Vercel Inc., 340 S Lemon Ave #4133, Walnut, CA 91789,
            USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);{' '}
            <strong>Website:</strong>{' '}
            <a href="https://vercel.com" className="underline">
              https://vercel.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://vercel.com/legal/privacy-policy" className="underline">
              https://vercel.com/legal/privacy-policy
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a href="https://vercel.com/legal/dpa" className="underline">
              https://vercel.com/legal/dpa
            </a>
            ;{' '}
            <strong>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
            </strong>{' '}
            <a href="https://vercel.com/legal/dpa" className="underline">
              https://vercel.com/legal/dpa
            </a>
            .
          </li>
          <li>
            <strong>Amazon Web Services (AWS):</strong> Leistungen auf dem Gebiet der Bereitstellung von
            informationstechnischer Infrastruktur und verbundenen Dienstleistungen (z.B. Speicherplatz und/oder
            Rechenkapazitäten); <strong>Dienstanbieter:</strong> Amazon Web Services EMEA SARL, 38 avenue John F.
            Kennedy, L-1855, Luxemburg; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
            lit. f) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://aws.amazon.com/de/" className="underline">
              https://aws.amazon.com/de/
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://aws.amazon.com/de/privacy/" className="underline">
              https://aws.amazon.com/de/privacy/
            </a>
            ; Auftragsverarbeitungsvertrag:{' '}
            <a href="https://aws.amazon.com/de/compliance/gdpr-center/" className="underline">
              https://aws.amazon.com/de/compliance/gdpr-center/
            </a>
            ;{' '}
            <strong>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
            </strong>{' '}
            Einbeziehung im Auftragsverarbeitungsvertrag.
          </li>
        </ul>
      </div>
      <div>
        <h2>Besondere Hinweise zu Applikationen (Apps)</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer unserer Applikation, soweit diese erforderlich sind, um den Nutzern die
          Applikation sowie deren Funktionalitäten bereitstellen, deren Sicherheit überwachen und sie weiterentwickeln
          zu können. Wir können ferner Nutzer unter Beachtung der gesetzlichen Vorgaben kontaktieren, sofern die
          Kommunikation zu Zwecken der Administration oder Nutzung der Applikation erforderlich ist. Im Übrigen
          verweisen wir im Hinblick auf die Verarbeitung der Daten der Nutzer auf die Datenschutzhinweise in dieser
          Datenschutzerklärung.
        </p>
        <p>
          <strong>Rechtsgrundlagen:</strong> Die Verarbeitung von Daten, die für die Bereitstellung der Funktionalitäten
          der Applikation erforderlich ist, dient der Erfüllung von vertraglichen Pflichten. Dies gilt auch, wenn die
          Bereitstellung der Funktionen eine Berechtigung der Nutzer (z. B. Freigaben von Gerätefunktionen) voraussetzt.
          Sofern die Verarbeitung von Daten für die Bereitstellung der Funktionalitäten der Applikation nicht
          erforderlich ist, aber der Sicherheit der Applikation oder unseren betriebswirtschaftlichen Interessen dient
          (z. B. Erhebung von Daten zu Zwecken der Optimierung der Applikation oder Sicherheitszwecken), erfolgt sie auf
          Grundlage unserer berechtigten Interessen. Sofern Nutzer ausdrücklich deren Einwilligung in die Verarbeitung
          ihrer Daten gebeten werden, erfolgt die Verarbeitung der von der Einwilligung umfassten Daten auf Grundlage
          der Einwilligung.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Meta-/Kommunikationsdaten
            (z.B. Geräte-Informationen, IP-Adressen); Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
            Zahlungshistorie); Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Bild- und/ oder
            Videoaufnahmen (z. B. Fotografien oder Videoaufnahmen einer Person).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
            f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Kommerzielle Nutzung:</strong> Wir verarbeiten die Daten der Nutzer unserer Applikation,
            angemeldeter und etwaiger Testnutzer (nachfolgend einheitlich als &quot;Nutzer&quot; bezeichnet), um ihnen
            gegenüber unsere vertraglichen Leistungen erbringen zu können sowie auf Grundlage berechtigter Interessen,
            um die Sicherheit unserer Applikation gewährleisten und sie weiterzuentwickeln zu können. Die erforderlichen
            Angaben sind als solche im Rahmen des Nutzungs-, Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses
            gekennzeichnet und können die zur Leistungserbringung und zur etwaigen Abrechnung benötigten Angaben sowie
            Kontaktinformationen, um etwaige Rücksprachen halten zu können, umfassen; <strong>Rechtsgrundlagen:</strong>
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </li>
          <li>
            <strong>Geräteberechtigungen für den Zugriff auf Funktionen und Daten:</strong> Die Nutzung unserer
            Applikation oder ihrer Funktionalitäten kann Berechtigungen der Nutzer für Zugriff auf bestimmten Funktionen
            der verwendeten Geräte oder auf die, auf den Geräten gespeicherten oder mit Hilfe der Geräte zugänglichen
            Daten voraussetzen. Standardmäßig müssen diese Berechtigungen von den Nutzern erteilt werden und können
            jederzeit in den Einstellungen der jeweiligen Geräte widerrufen werden. Das genaue Verfahren zur Kontrolle
            der App-Berechtigungen kann vom Gerät und der Software der Nutzern abhängig sein. Bei Erläuterungsbedarf
            können sich Nutzer an uns wenden. Wir weisen darauf hin, dass die Versagung oder Widerruf der jeweiligen
            Berechtigungen die Funktionsfähigkeit unsere Applikation beeinflussen kann.
          </li>
          <li>
            <strong>Zugriff auf die Kamera sowie gespeicherten Aufnahmen:</strong> Im Rahmen der Nutzung unserer
            Applikation werden Bild- und/ oder Videoaufnahmen (wobei auch Audioaufnahmen umfasst sind) der Nutzer (und
            von anderen, von den Aufnahmen erfassten Personen) durch den Zugriff auf die Kamerafunktionen oder auf
            gespeicherte Aufnahmen verarbeitet. Der Zugriff auf die Kamerafunktionen oder gespeicherte Aufnahmen setzt
            eine jederzeit widerrufbare Berechtigung durch die Nutzer voraus. Die Verarbeitung der Bild- und/oder
            Videoaufnahmen dient jeweils nur der Bereitstellung der jeweiligen Funktionalität unserer Applikation,
            entsprechend ihrer Beschreibung gegenüber den Nutzern, bzw. ihrer typischen und erwartbaren Funktionsweise.
          </li>
          <li>
            <strong>Kein Standortverlauf und keine Bewegungsprofile:</strong> Die Standortdaten werden lediglich
            punktuell eingesetzt und nicht zur Bildung eines Standortverlaufs oder eines Bewegungsprofils der
            verwendeten Geräte, bzw. ihrer Nutzer verarbeitet.
          </li>
        </ul>
      </div>
      <div>
        <h2>Bezug von Applikationen über Appstores</h2>
        <p>
          Der Bezug unserer Applikation erfolgt über über spezielle Online-Plattformen, die von anderen Dienstanbietern
          betrieben werden, an (so genannte &quot;Appstores&quot;). In diesem Zusammenhang gelten zusätzlich zu unseren
          Datenschutzhinweisen die Datenschutzhinweise der jeweiligen Appstores. Dies gilt insbesondere im Hinblick auf
          die auf den Plattformen eingesetzten Verfahren zur Reichweitemessung und zum interessensbezogenen Marketing
          sowie etwaige Kostenpflicht.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten (z.B.
            Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z.B. E-Mail, Telefonnummern); Vertragsdaten
            (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
            Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Inhaltsdaten
            (z.B. Eingaben in Onlineformularen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kunden; Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice; Marketing.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Apple App Store:</strong> App- und Softwareverkaufsplattform; <strong>Dienstanbieter:</strong> Apple
            Inc., Infinite Loop, Cupertino, CA 95014, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
            (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://www.apple.com/de/ios/app-store/" className="underline">
              https://www.apple.com/de/ios/app-store/
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://www.apple.com/legal/privacy/de-ww/" className="underline">
              https://www.apple.com/legal/privacy/de-ww/
            </a>
            .
          </li>
          <li>
            <strong>Google Play:</strong> App- und Softwareverkaufsplattform; <strong>Dienstanbieter:</strong> Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte
            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://play.google.com/store/apps?hl=de" className="underline">
              https://play.google.com/store/apps?hl=de
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://policies.google.com/privacy" className="underline">
              https://policies.google.com/privacy
            </a>
            .
          </li>
        </ul>
      </div>
      <div>
        <h2>Registrierung, Anmeldung und Nutzerkonto</h2>
        <p>
          Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung werden den Nutzern die erforderlichen
          Pflichtangaben mitgeteilt und zu Zwecken der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher
          Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten gehören insbesondere die Login Informationen
          (Nutzername, Passwort sowie eine E-Mail-Adresse).
        </p>
        <p>
          Im Rahmen der Inanspruchnahme unserer Registrierungs- und Anmeldefunktionen sowie der Nutzung des Nutzerkontos
          speichern wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die Speicherung erfolgt auf
          Grundlage unserer berechtigten Interessen als auch jener der Nutzer an einem Schutz vor Missbrauch und
          sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, es sei denn,
          sie ist zur Verfolgung unserer Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung hierzu.
        </p>
        <p>
          Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant sind, wie z.B. technische Änderungen, per
          E-Mail informiert werden.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail,
            Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen); Meta-/Kommunikationsdaten (z.B.
            Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice;
            Sicherheitsmaßnahmen; Verwaltung und Beantwortung von Anfragen; Bereitstellung unseres Onlineangebotes und
            Nutzerfreundlichkeit.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Registrierung mit Klarnamen:</strong> Aufgrund der Natur unserer Community bitten wir die Nutzer
            unser Angebot nur unter Verwendung von Klarnamen zu nutzen. D.h. die Nutzung von Pseudonymen ist nicht
            zulässig; <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
            S. 1 lit. b) DSGVO).
          </li>
          <li>
            <strong>Keine Aufbewahrungspflicht für Daten:</strong> Es obliegt den Nutzern, ihre Daten bei erfolgter
            Kündigung vor dem Vertragsende zu sichern. Wir sind berechtigt, sämtliche während der Vertragsdauer
            gespeicherte Daten des Nutzers unwiederbringlich zu löschen; <strong>Rechtsgrundlagen:</strong>{' '}
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </li>
        </ul>
      </div>
      <div>
        <h2>Kontakt- und Anfragenverwaltung</h2>
        <p>
          Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie im
          Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet
          soweit dies zur Beantwortung der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.
        </p>
        <p>
          Die Beantwortung der Kontaktanfragen sowie die Verwaltung von Kontakt- und Anfragedaten im Rahmen von
          vertraglichen oder vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur
          Beantwortung von (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der berechtigten Interessen an der
          Beantwortung der Anfragen und Pflege von Nutzer- bzw. Geschäftsbeziehungen.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z.B. E-Mail, Telefonnummern); Inhaltsdaten (z.B.
            Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher Leistungen und Kundenservice;
            Kontaktanfragen und Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback (z.B. Sammeln von
            Feedback via Online-Formular); Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit;
            Direktmarketing (z.B. per E-Mail oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit.
            b) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Freshdesk:</strong> Management von Kontaktanfragen und Kommunikation;{' '}
            <strong>Dienstanbieter:</strong> Freshworks, Inc., 2950 S.Delaware Street, Suite 201, San Mateo, CA 94403,
            USA; <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
            lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://www.freshworks.com" className="underline">
              https://www.freshworks.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://www.freshworks.com/privacy/" className="underline">
              https://www.freshworks.com/privacy/
            </a>
            .
          </li>
          <li>
            <strong>SendGrid:</strong> E-Mail-Versand und Kommunikationsplattform für Transaktions- und
            Marketing-E-Mails; <strong>Dienstanbieter:</strong> SendGrid, Inc. 1801 California Street, Suite 500 Denver,
            Colorado 80202, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
            DSGVO); <strong>Website:</strong>{' '}
            <a href="https://sendgrid.com" className="underline">
              https://sendgrid.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://sendgrid.com/policies/privacy/" className="underline">
              https://sendgrid.com/policies/privacy/
            </a>
            ;{' '}
            <strong>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
            </strong>{' '}
            <a href="https://www.twilio.com/legal/data-protection-addendum" className="underline">
              https://www.twilio.com/legal/data-protection-addendum
            </a>
            .
          </li>
          <li>
            <strong>Mailchimp:</strong> E-Mail-Versand- und E-Mail-Marketing-Plattform;
            <strong>Dienstanbieter:</strong> Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA
            30308, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);{' '}
            <strong>Website:</strong>{' '}
            <a href="https://mailchimp.com" className="underline">
              https://mailchimp.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://mailchimp.com/legal/" className="underline">
              https://mailchimp.com/legal/
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a href="https://mailchimp.com/legal/" className="underline">
              https://mailchimp.com/legal/
            </a>
            ;{' '}
            <strong>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
            </strong>{' '}
            Einbeziehung im Auftragsverarbeitungsvertrag; <strong>Weitere Informationen:</strong> Besondere
            Sicherheitsmaßnahmen:{' '}
            <a href="https://mailchimp.com/help/Mailchimp-european-data-transfers/" className="underline">
              https://mailchimp.com/help/Mailchimp-european-data-transfers/
            </a>
            .
          </li>
        </ul>
      </div>
      <div>
        <h2>Push-Nachrichten</h2>
        <p>
          Mit der Zustimmung der Nutzer, können wir den Nutzern so genannte &quot;Push-Benachrichtigungen&quot;
          zusenden. Dabei handelt es sich um Nachrichten, die auf den Bildschirmen, Endgeräten oder in Browsern der
          Nutzer angezeigt werden, auch wenn unser Onlinedienst gerade nicht aktiv genutzt wird.
        </p>
        <p>
          Um sich für die Push-Nachrichten anzumelden, müssen Nutzer die Abfrage ihres Browsers bzw. Endgerätes zum
          Erhalt der Push-Nachrichten bestätigen. Dieser Zustimmungsprozess wird dokumentiert und gespeichert. Die
          Speicherung ist erforderlich, um zu erkennen, ob Nutzer dem Empfang der Push-Nachrichten zugestimmt haben
          sowie um die Zustimmung nachweisen zu können. Zu diesen Zwecken wird ein pseudonymer Identifikator des
          Browsers (sog. &quot;Push-Token&quot;) oder die Geräte-ID eines Endgerätes gespeichert.
        </p>
        <p>
          Die Push-Nachrichten können zum einen für die Erfüllung von vertraglichen Pflichten erforderlich sein (z. B.
          für die Nutzung unseres Onlineangebotes relevante technische und organisatorische Informationen) und werden
          ansonsten, sofern nicht nachfolgend speziell genannt, auf Grundlage einer Einwilligung der Nutzer versendet.
          Nutzer können den Empfang der Push-Nachrichten jederzeit mit Hilfe der Benachrichtigungseinstellungen ihrer
          jeweiligen Browser, bzw. Endgeräte ändern.
        </p>
        <ul>
          <li>
            Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit;
            Direktmarketing (z.B. per E-Mail oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Push-Nachrichten mit werblichem Inhalt:</strong> Die von uns gesendeten Push-Benachrichtigungen
            können werbliche Informationen beinhalten. Die werblichen Push-Nachrichten werden auf Grundlage einer
            Einwilligung der Nutzer verarbeitet. Sofern im Rahmen einer Zustimmung zum Empfang der werblichen
            Push-Nachrichten deren Inhalte konkret beschrieben werden, sind die Beschreibungen für die Einwilligung der
            Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns;{' '}
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
          </li>
          <li>
            <strong>Firebase: Dienstanbieter:</strong> Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
            Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;{' '}
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO);{' '}
            <strong>Website:</strong>{' '}
            <a href="https://firebase.google.com" className="underline">
              https://firebase.google.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://policies.google.com/privacy" className="underline">
              https://policies.google.com/privacy
            </a>
            ;{' '}
            <strong>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
            </strong>{' '}
            <a href="https://firebase.google.com/terms/data-processing-terms" className="underline">
              https://firebase.google.com/terms/data-processing-terms
            </a>
            .
          </li>
        </ul>
      </div>
      <div>
        <h2>Cloud-Dienste</h2>
        <p>
          Wir nutzen über das Internet zugängliche und auf den Servern ihrer Anbieter ausgeführte Softwaredienste
          (sogenannte &quot;Cloud-Dienste&quot;, auch bezeichnet als &quot;Software as a Service&quot;) für die
          folgenden Zwecke: Dokumentenspeicherung und Verwaltung, Kalenderverwaltung, E-Mail-Versand,
          Tabellenkalkulationen und Präsentationen, Austausch von Dokumenten, Inhalten und Informationen mit bestimmten
          Empfängern oder Veröffentlichung von Webseiten, Formularen oder sonstigen Inhalten und Informationen sowie
          Chats und Teilnahme an Audio- und Videokonferenzen.
        </p>
        <p>
          In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der Anbieter gespeichert
          werden, soweit diese Bestandteil von Kommunikationsvorgängen mit uns sind oder von uns sonst, wie im Rahmen
          dieser Datenschutzerklärung dargelegt, verarbeitet werden. Zu diesen Daten können insbesondere Stammdaten und
          Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren Inhalte gehören. Die
          Anbieter der Cloud-Dienste verarbeiten ferner Nutzungsdaten und Metadaten, die von ihnen zu Sicherheitszwecken
          und zur Serviceoptimierung verwendet werden.
        </p>
        <p>
          Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder öffentlich zugängliche Webseiten Formulare o.a.
          Dokumente und Inhalte bereitstellen, können die Anbieter Cookies auf den Geräten der Nutzer für Zwecke der
          Webanalyse oder, um sich Einstellungen der Nutzer (z.B. im Fall der Mediensteuerung) zu merken, speichern.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail,
            Telefonnummern); Inhaltsdaten (z.B. Eingaben in Onlineformularen); Nutzungsdaten (z.B. besuchte Webseiten,
            Interesse an Inhalten, Zugriffszeiten); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kunden; Beschäftigte (z.B. Angestellte, Bewerber, ehemalige
            Mitarbeiter); Interessenten; Kommunikationspartner; Nutzer (z.B. Webseitenbesucher, Nutzer von
            Onlinediensten); Geschäfts- und Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Büro- und Organisationsverfahren; Informationstechnische
            Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und technischen Geräten (Computer, Server
            etc.).).
          </li>
          <li>
            Rechtsgrundlagen: Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Amazon Web Services (AWS):</strong> Leistungen auf dem Gebiet der Bereitstellung von
            informationstechnischer Infrastruktur und verbundenen Dienstleistungen (z.B. Speicherplatz und/oder
            Rechenkapazitäten); <strong>Dienstanbieter:</strong> Amazon Web Services EMEA SARL, 38 avenue John F.
            Kennedy, L-1855, Luxemburg; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1
            lit. f) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://aws.amazon.com/de/" className="underline">
              https://aws.amazon.com/de/
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://aws.amazon.com/de/privacy/" className="underline">
              https://aws.amazon.com/de/privacy/
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a href="https://aws.amazon.com/de/compliance/gdpr-center/" className="underline">
              https://aws.amazon.com/de/compliance/gdpr-center/
            </a>
            ;{' '}
            <strong>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
            </strong>{' '}
            Einbeziehung im Auftragsverarbeitungsvertrag.
          </li>
        </ul>
      </div>
      <div>
        <h2>Newsletter und elektronische Benachrichtigungen</h2>
        <p>
          Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend
          &quot;Newsletter&quot;) nur mit der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im
          Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die Einwilligung
          der Nutzer maßgeblich. Im Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns.
        </p>
        <p>
          Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse angeben.
          Wir können Sie jedoch bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter, oder weitere Angaben,
          sofern diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.
        </p>
        <p>
          <strong>Double-Opt-In-Verfahren:</strong> Die Anmeldung zu unserem Newsletter erfolgt grundsätzlich in einem
          sogenannte Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die
          Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden
          E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess
          entsprechend den rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde-
          und des Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem
          Versanddienstleister gespeicherten Daten protokolliert.
        </p>
        <p>
          <strong>Löschung und Einschränkung der Verarbeitung:</strong> Wir können die ausgetragenen E-Mail-Adressen bis
          zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals
          gegebene Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen
          Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das
          ehemalige Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften Beachtung von
          Widersprüchen behalten wir uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste
          (sogenannte &quot;Blocklist&quot;) vor.
        </p>
        <p>
          Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu Zwecken des
          Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
          beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an einem effizienten und sicheren
          Versandsystem.
        </p>
        <p>
          <strong>Inhalte:</strong>
        </p>
        <p>Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.</p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail,
            Telefonnummern); Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Nutzungsdaten (z.B.
            besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Berechtigte Interessen
            (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
          <li>
            <strong>Widerspruchsmöglichkeit (Opt-Out):</strong> Sie können den Empfang unseres Newsletters jederzeit
            kündigen, d.h. Ihre Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link zur
            Kündigung des Newsletters finden Sie entweder am Ende eines jeden Newsletters oder können sonst eine der
            oben angegebenen Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Mailchimp:</strong> E-Mail-Versand- und E-Mail-Marketing-Plattform; <strong>Dienstanbieter:</strong>{' '}
            Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA;{' '}
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO);{' '}
            <strong>Website:</strong>{' '}
            <a href="https://mailchimp.com" className="underline">
              https://mailchimp.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://mailchimp.com/legal/" className="underline">
              https://mailchimp.com/legal/
            </a>
            ; <strong>Auftragsverarbeitungsvertrag:</strong>{' '}
            <a href="https://mailchimp.com/legal/" className="underline">
              https://mailchimp.com/legal/
            </a>
            ;{' '}
            <strong>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in Drittländern):
            </strong>{' '}
            Einbeziehung im Auftragsverarbeitungsvertrag; <strong>Weitere Informationen:</strong> Besondere
            Sicherheitsmaßnahmen:{' '}
            <a href="https://mailchimp.com/help/Mailchimp-european-data-transfers/" className="underline">
              https://mailchimp.com/help/Mailchimp-european-data-transfers/
            </a>
            .
          </li>
        </ul>
      </div>
      <div>
        <h2>Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon</h2>
        <p>
          Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen Kommunikation, die über diverse Kanäle, wie
          z.B. E-Mail, Telefon, Post oder Fax, entsprechend den gesetzlichen Vorgaben erfolgen kann.
        </p>
        <p>
          Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen oder der werblichen
          Kommunikation jederzeit zu widersprechen.
        </p>
        <p>
          Nach Widerruf oder Widerspruch speichern wir die zum Nachweis der bisherigen Berechtigung erforderlichen Daten
          zur Kontaktaufnahme oder Zusendung bis zu drei Jahre nach Ablauf des Jahres des Widerrufs oder Widerspruchs
          auf der Grundlage unserer berechtigten Interessen. Die Verarbeitung dieser Daten ist auf den Zweck einer
          möglichen Abwehr von Ansprüchen beschränkt. Auf der Grundlage des berechtigten Interesses, den Widerruf bzw.
          Widerspruch der Nutzer dauerhaft zu beachten, speichern wir ferner die zur Vermeidung einer erneuten
          Kontaktaufnahme erforderlichen Daten (z.B. je nach Kommunikationskanal die E-Mail-Adresse, Telefonnummer,
          Name).
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.B. Namen, Adressen); Kontaktdaten (z.B. E-Mail,
            Telefonnummern).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kommunikationspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Direktmarketing (z.B. per E-Mail oder postalisch).
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO); Berechtigte Interessen
            (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
      </div>
      <div>
        <h2>Kundenrezensionen und Bewertungsverfahren</h2>
        <p>
          Wir nehmen an Rezensions- und Bewertungsverfahren teil, um unsere Leistungen zu evaluieren, zu optimieren und
          zu bewerben. Wenn Nutzer uns über die beteiligten Bewertungsplattformen oder -verfahren bewerten oder
          anderweitig Feedback geben, gelten zusätzlich die Allgemeinen Geschäfts- oder Nutzungsbedingungen und die
          Datenschutzhinweise der Anbieter. Im Regelfall setzt die Bewertung zudem eine Registrierung bei den jeweiligen
          Anbietern voraus.
        </p>
        <p>
          Um sicherzustellen, dass die bewertenden Personen tatsächlich unsere Leistungen in Anspruch genommen haben,
          übermitteln wir mit Einwilligung der Kunden die hierzu erforderlichen Daten im Hinblick auf den Kunden und die
          in Anspruch genommene Leistung an die jeweilige Bewertungsplattform (einschließlich Name, E-Mail-Adresse und
          Bestellnummer bzw. Artikelnummer). Diese Daten werden alleine zur Verifizierung der Authentizität des Nutzers
          verwendet.
        </p>
        <ul>
          <li>
            <strong>Verarbeitete Datenarten:</strong> Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
            Kundenkategorie); Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten);
            Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen); Bestandsdaten (z.B. Namen, Adressen);
            Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten (z.B. E-Mail,
            Telefonnummern).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Kunden; Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Feedback (z.B. Sammeln von Feedback via Online-Formular);
            Marketing; Erbringung vertraglicher Leistungen und Kundenservice.
          </li>
          <li>
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:</strong>
        </p>
        <ul>
          <li>
            <strong>Google Play:</strong> App- und Softwareverkaufsplattform; <strong>Dienstanbieter:</strong> Google
            Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
            Amphitheatre Parkway, Mountain View, CA 94043, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte
            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://play.google.com/store/apps?hl=de" className="underline">
              https://play.google.com/store/apps?hl=de
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://policies.google.com/privacy" className="underline">
              https://policies.google.com/privacy
            </a>
            .
          </li>
          <li>
            <strong>Apple App Store:</strong> App- und Softwareverkaufsplattform; <strong>Dienstanbieter:</strong> Apple
            Inc., Infinite Loop, Cupertino, CA 95014, USA; <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen
            (Art. 6 Abs. 1 S. 1 lit. f) DSGVO); <strong>Website:</strong>{' '}
            <a href="https://www.apple.com/de/ios/app-store/" className="underline">
              https://www.apple.com/de/ios/app-store/
            </a>
            ; <strong>Datenschutzerklärung:</strong>{' '}
            <a href="https://www.apple.com/legal/privacy/de-ww/" className="underline">
              https://www.apple.com/legal/privacy/de-ww/
            </a>
            .
          </li>
        </ul>
      </div>
      <div>
        <h2>Änderung und Aktualisierung der Datenschutzerklärung</h2>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies
          erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits
          (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen
          angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern können und bitten die Angaben vor
          Kontaktaufnahme zu prüfen.
        </p>
      </div>
      <div>
        <h2>Rechte der betroffenen Personen</h2>
        <p>
          Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 21
          DSGVO ergeben:
        </p>
        <ul>
          <li>
            <strong>Widerspruchsrecht:</strong> Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die
            aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
            diese Bestimmungen gestütztes Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie
            betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
            Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
          </li>
          <li>
            <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte Einwilligungen jederzeit
            zu widerrufen.
          </li>
          <li>
            <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende
            Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der
            Daten entsprechend den gesetzlichen Vorgaben.
          </li>
          <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die
            Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu
            verlangen.
          </li>
          <li>
            <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach Maßgabe der
            gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw.
            alternativ nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu
            verlangen.
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten, die Sie uns
            bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und
            maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.
          </li>
          <li>
            <strong>Beschwerde bei Aufsichtsbehörde:</strong> Entsprechend den gesetzlichen Vorgaben und unbeschadet
            eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, haben Sie ferner das Recht, bei
            einer Datenschutzaufsichtsbehörde, insbesondere einer Aufsichtsbehörde im Mitgliedstaat, in dem Sie sich
            gewöhnlich aufhalten, der Aufsichtsbehörde Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes,
            eine Beschwerde einzulegen, wenn Sie der Ansicht sei sollten, dass die Verarbeitung der Ihre Person
            betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
          </li>
        </ul>
      </div>
      <div>
        <h2>Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten
          Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
          gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem
          Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
        </p>
        <ul>
          <li>
            <strong>Personenbezogene Daten:</strong> &quot;Personenbezogene Daten&quot; sind alle Informationen, die
            sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden &quot;betroffene
            Person&quot;) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
            insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
            einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden
            kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
            oder sozialen Identität dieser natürlichen Person sind.
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als &quot;Verantwortlicher&quot; wird die natürliche oder juristische
            Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und
            Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
          </li>
          <li>
            <strong>Verarbeitung:</strong> &quot;Verarbeitung&quot; ist jeder mit oder ohne Hilfe automatisierter
            Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
            Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das
            Speichern, das Übermitteln oder das Löschen.
          </li>
        </ul>
      </div>
    </div>
  );
};
