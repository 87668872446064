import { Filesystem, Directory } from '@capacitor/filesystem';
import { dbName } from './constants';

export const deleteOfflineData = async () => {
  let isSuccess = true;
  try {
    await Filesystem.rmdir({
      path: `${dbName}`,
      directory: Directory.Data,
      recursive: true,
    });
  } catch (err) {
    isSuccess = false;
    console.log(err);
  }

  return { isSuccess };
};
