export default {
  translation: {
    navigation: {
      workout: 'Training',
      nutrition: 'Ernährung',
      progress: 'Fortschritt',
      tips: 'Tipps',
      profile: 'Profil',
    },
    register: {
      title: 'Registrierung',
      registerButton: 'Melde dich neu an',
      alreadyHaveAnAccount: 'Hast du bereits einen Account?',
      loginHere: 'Zum Login',
      subscribe: 'Zum Newsletter anmelden',
      readAndAccept: 'Ich habe die',
      afterReadAndAccept: ' gelesen und aktzeptiere sie.',
      emailTaken: 'E-Mail-Adresse ist bereits vergeben'
    },
    registerConfirmation: {
      title: 'Danke für deine Anmeldung',
      emailSent: 'Wir haben dir eine Bestätigungsemail zugesendet.',
      msg: 'Bitte schaue in deinem Postfach für die E-Mail Adresse ({{email}}) nach und klicke auf den Aktivierungslink um die Anmeldung abzuschließen!',
    },
    emailConfirmation: {
      title: 'Email Bestätigung',
      thanks: 'Danke für die Bestätigung deiner E-Mail Adresse',
      msg: 'Nun kannst du dich einloggen und dein Urbanathletix Trainingsprogram auswählen.',
    },
    login: {
      title: 'Login',
      forgotPasswordLink: 'Passwort vergessen?',
      dontHaveAccaount: 'Du hast noch keinen Account?',
      registerHere: 'Zur Registrierung',
    },
    forgotPassword: {
      title: 'Passwort vergessen',
      backToLoginButton: 'Zurück zum Login',
      send: 'Senden',
    },
    resetPassword: {
      title: 'Passwort zurücksetzen',
      button: 'Weiter',
    },
    onboarding: {
      explanation:
        'Bevor wir beginnen benötigen wir einige Angaben von dir. Daraus ermitteln wir deinen individuellen Trainings- und Ernährungsplan.',
      letsgo: "Los geht's",
      setupProfile: 'Erstelle dein Profil',
      selectYourGender: 'Wähle dein Geschlecht',
      selectGender: 'Bitte Wähle',
      male: 'Männlich',
      female: 'Weiblich',
      selectAge: 'Wähle dein Alter',
      enterYourAge: 'Gib dein Alter ein',
      enterHeight: 'Gib deine Körpergröße ein (cm)',
      enterWeight: 'Gib dein Gewicht ein (kg)',
      // enterHeight: 'Enter your height (cm)',
      // enterWeight: 'Enter your weight (kg)',
      physicalActivity: 'Dein Aktivitätslevel',
      bodyType: 'Wähle deinen Körpertyp',
      goal: 'Definiere dein Ziel',
      trainPlace: 'Wo möchtest du trainieren?',
      getStarted: "Los geht's",
      beginJourney: 'Beginne dein Training',
      programDescription:
        'Dieses Programm ist auf deine Bedürfnisse zugeschnitten. Du wählst aus, wann du mit dem 11 Wochen Programm starten möchtest. Du erhälst Zugriff auf den Ernährungsplan für die erste Phase, damit du dich auf die kommenden Tage besser vorbereiten kannst.',
      selectDate: 'Startdatum wählen',
      notifications: 'Notifications',
      keepMeNotified: 'Erlaube Notifications',
      notificationMsg: 'Bleibe up to date mit regelmäßigen Tips für deinen aktuellen Entwicklungsstand.',
      turnOn: 'Notifications erlauben',
      notNow: 'Nicht jetzt',
    },
    payments: {
      title: 'Bereit zu starten?',
      payment: 'Zahlung',
      getAccess: 'Zugriff erhalten für {{amount}}€',
      with: 'mit',
    },
    workout: {
      title: 'Dein Trainer',
      overview: 'Übersicht',
      days: '{{days}} Tag(e)',
      untilBegin: 'bis dein Programm startet',
      todaysWorkout: 'Dein heutiges Workout',
      seeNutrition: 'Ernähungsplan',
      noWorkout: 'Trainingsfreier Tag',
      exercisesCount: 'Du hast {{count}} Übungen in Deinem heutigen Trainingsplan.',
      sets: 'Sätze',
      repetitions: 'Wiederholungen',
      selectExercise: 'Wähle Übung',
      pleaseSelectExercise: 'Bitte wähle eine Übung aus der Liste und starte dein Training!',
      beginExercise: 'Beginne Übung',
      exercise: 'Übung',
      timerRunsOut: '{{name}} bis die Zeit abläuft',
      information: 'Trainingsinformationen',
      changeStartDate:
        'Du kannst dein Startdatum anpassen, solange das Startdatum in der Zukunft liegt. Falls du heute beginnen möchtest, kannst du auch den heutigen Tag auswählen',
      saveChanges: 'Speichern',
      seconds: 'Sek.',
      backToToday: 'Heutiges Datum',
    },
    nutrition: {
      title: 'Ernährungsplan',
      subtitle: 'Phasenfortschritt',
      phase: 'Phase',
      overall: 'Allgemein',
      daysLeftInPhase: 'Tage in aktueller Phase',
      daysLeftInProgram: 'Tage im Programm',
      trackingStartsOn: 'Tracking startet am',
      programStartsOn: 'Programm startet am',
      selectRecipe: 'Wähle Rezept',
      pleaseSelectRecipe:
        'Bitte wähle ein Rezept aus der Liste und bereite die Gerichte zu die zu deiner aktuellen Phase passen.',
      breakfast: 'Frühstück',
      lunch: 'Mittagessen',
      dinner: 'Abendessen',
      snacks: 'Snacks',
      ingredients: 'Zutaten',
      directions: 'Zubereitung',
      nextPhase: 'Nächste Phase',
      currentPhase: 'Aktuelle Phase',
      phaseCompleted: 'Phase beendet',
      programCompleted: 'Programm beendet',
      information: 'Ernährungsplan Info',
    },
    progress: {
      title: 'Dein Fortschritt',
      phases: 'Phasen',
      placeholder: 'Bitte gib Dein Gewicht ein (bsp.: 78.5)',
      addProgressEntry: 'Fortschritt hinzufügen',
      addProgressDescription:
        'Hier kannst Du Deinen Fortschritt für die aktuelle Phase {{currentPhase}} hinterlegen. Gebe dein Gewicht ein und lade, wenn du möchtest, ein Bild von dir hoch.',
      addEntry: 'Hinzufügen',
      addPhotoOptional: 'Foto hinzufügen (Optional)',
      addPhoto: 'Foto hinzufügen',
      browseFiles: 'Wähle ein Bild aus',
      phaseStartsOn: 'Phase {{phaseNumber}} beginnt am {{date}}',
      weightAndPhoto: 'Gewicht und Foto',
      setWeight: 'Gib dein Gewicht ein',
      noPhoto: 'kein Foto',
      noInternet: 'Keine Internetverbindung',
      noInternetMsg:
        'Das hinzufügen von Fotos ist nur mit einer aktiven Internetverbindung möglich. Bitte überprüfe deine Internetverbindung.',
      deleteEntry: 'Eintrag löschen',
      gallery: 'Galerie',
      yourGallery: 'Deine Galerie',
      uploadProgress: 'Keine Fotos vorhanden. Lade hier Deine Fotos hoch, um Deinen Fortschritt visuell festzuhalten.',
      congratulations: 'Herzlichen Glückwunsch!',
      startWeight: 'Startgewicht',
      endWeight: 'Endgewicht',
    },
    tips: {
      title: 'Deine Tipps',
      tips: 'Tipps',
      latest: 'Neuste',
      older: 'Ältere Tipps',
      noOlderTips: 'Es gibt aktuell noch keine weiteren Tipps.',
    },
    profile: {
      title: 'Benutzerprofil',
      name: 'Name',
      physicalInfo: 'Deine Körperdaten',
      downloadData: 'Offline-Modus',
      resetPassword: 'Passwort zurücksetzen',
      logout: 'Abmelden',
      offlineMode: 'Offline-Modus',
      spaceNeeded: 'Speicherplatzbedarf',
      dataDownloaded: 'Du hast bereits die Daten heruntergeladen, die für den Offline-Modus notwendig sind.',
      downloadedOnDevice: 'Auf dein Gerät herunterladen',
      buttonDownload: 'Herunterladen',
      buttonDelete: 'Offline-Modus Daten löschen',
      deletingPopupTitle: 'Warnung! Alle deine persönlichen Daten werden gelöscht!',
      deletingPopupMessage: 'Bist du dir sicher, dass du alle deine Account Daten löschen möchtest?',
      deletingDataButton: 'Alle persönlichen Daten löschen',
      deletingDate: 'Deine Daten werden spätestens am {{deletionDate}} gelöscht.',
      wifiRecommended:
        'Der Download kann einige Minuten dauern. Wir empfehlen dir, den Download durchzuführen, wenn du mit einem W-Lan verbunden bist.',
    },
    additional: {
      newVersion: 'Neue Version verfügbar!',
      newVersionContent:
        'Eine neue Version von Urbanathletix ist für dich verfügbar. Du kannst sie nun herunterladen! Achtung: Der Download kann einige Minuten dauern. Wir empfehlen dir, den Download durchzuführen, wenn du mit einem W-Lan verbunden bist.',
      loadingData: 'Daten werden geladen',
      pleaseWait: 'Bitte warte solange wir deine Daten herunterladen',
      newPhase:
        'Du hast die {{phase}}. Phase erreicht! Gib dein Gewicht ein und lade ein Foto von dir hoch (optional) um deinen Fortschritt zu dokumentieren und beginne mit Phase {{phase}}!',
      rateAndFeedback: 'Bewerten und Feedback',
      feedbackDescription:
        'Wie gefällt dir die Urbanathletix App? Du kannst die App im App Store bewerten oder uns Feedback geben, damit wir deine Trainingserfahrung weiter verbessern können!',
      rateUs: 'Bewerte uns',
      feedback: 'Gib Feedback',
    },
    basics: {
      back: 'Zurück',
      save: 'Speichern',
      update: 'Updaten',
      dismiss: 'Zurückweisen',
      email: 'E-Mail Adresse',
      password: 'Passwort',
      confirmPassword: 'Passwort bestätigen',
      firstName: 'Vorname',
      lastName: 'Nachname',
      terms: 'AGB',
      policy: 'Datenschutzerklärung',
      and: 'und',
      impressum: 'Impressum',
      gender: 'Geschlecht',
      height: 'Körpergröße',
      age: 'Alter',
      weight: 'Anfangsgewicht',
      minutes: 'Minuten',
      calendar: 'Kalendar',
      delete: 'Löschen',
      easy: 'einfach',
      medium: 'mittel',
      hard: 'schwer',
    },
    notifications: {},
    success: {
      emailSent: 'Eine E-Mail wurde gesendet.',
      downloadCompleted: 'Downdload erfolgreich abgeschlossen',
      deleteCompleted: 'Daten erfolgreich gelöscht',
      deleteEntry: 'Eintrag erfolgreich gelöscht',
      dateChanges: 'Startdatum erfolgreich geändert',
    },
    errors: {
      invalidUser: 'Ungültige E-Mail Adresse oder Passwort',
      firstNameRequire: 'Bitte gib deinen Vornamen ein',
      lastNameRequire: 'Bitte gib deinen Nachname ein',
      emailRequired: 'E-Mail benötigt',
      emailInvalid: 'Dies ist keine gültige E-Mail Adresse',
      passwordRequired: 'Passwort benötigt',
      passwordCharacters: 'Das Passwort muss mindestes 8 Zeichen haben',
      passwordConfirmationRequired: 'Bitte bestätige dein Passwort',
      passwordConfirmationMatch: 'Passwörter stimmen nicht überein',
      termsRequired: 'Die AGBs und die Datenschutzerklärung müssen aktzeptiert werden',
      wentWrong: 'Etwas ist schief gelaufen. Bitte versuche es noch  einmal.',
    },
  },
};
