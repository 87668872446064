import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useRegisterMutation } from './authAPI';
import { RegisterBody } from './types';
import { Button, Input, Checkbox, ErrorMsg, LoadingOverlay } from '../../common/components';

export const RegisterPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [register, { isLoading, isSuccess, error, reset }] = useRegisterMutation();

  const [errMsg, setErrMsg] = useState('');

  const RegistrationValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('errors.firstNameRequire')),
    lastName: Yup.string().required(t('errors.lastNameRequire')),
    email: Yup.string().required(t('errors.emailRequired')).email(t('errors.emailInvalid')),
    password: Yup.string().required(t('errors.passwordRequired')).min(8, t('errors.passwordCharacters')),
    confirmPassword: Yup.string()
      .required(t('errors.passwordConfirmationRequired'))
      .equals([Yup.ref('password')], t('errors.passwordConfirmationMatch')),
    agreement: Yup.boolean().oneOf([true], t('errors.termsRequired')),
  });

  const handleSubmit = async (values: RegisterBody) => {
    localStorage.setItem('email', values.email);
    await register(values);
  };

  useEffect(() => {
    if (isSuccess) navigate('/register-confirmation');
    if (error && (error as any).data?.error.message === 'Email is already taken') {
      setErrMsg(t('register.emailTaken'))
    }
  }, [isSuccess, error]);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <h1 className="mb-3 md:mb-6">{t('register.title')}</h1>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          isSubscribed: false,
          agreement: false,
        }}
        validationSchema={RegistrationValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="flex flex-col justify-between h-[68%] w-full" autoComplete="off">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <Input
                  autoComplete="off"
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('basics.firstName')}
                  value={values.firstName}
                  error={errors.firstName}
                  touched={touched.firstName}
                  halfWidth
                />
                <Input
                  autoComplete="off"
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={t('basics.lastName')}
                  value={values.lastName}
                  error={errors.lastName}
                  touched={touched.lastName}
                  halfWidth
                />
              </div>
              <Input
                autoComplete="off"
                type="email"
                name="email"
                onChange={(e) => {
                  handleChange(e);
                  reset();
                }}
                onBlur={handleBlur}
                placeholder={t('basics.email')}
                value={values.email}
                error={errors.email}
                touched={touched.email}
              />
              <Input
                autoComplete="new-password"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('basics.password')}
                value={values.password}
                error={errors.password}
                touched={touched.password}
              />
              <label
                className="w-[100%] text-left text-font-secondary text-sm mb-3"
              >
                {t('errors.passwordCharacters')}
              </label>
              <Input
                autoComplete="new-password"
                type="password"
                name="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t('basics.confirmPassword')}
                value={values.confirmPassword}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
              />
            </div>
            <div className="w-full">
              <div className="flex justify-between mb-3 cursor-pointer">
                <Checkbox
                  name="isSubscribed"
                  id="isSubscribed"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('isSubscribed', e.target.checked)}
                  checked={values.isSubscribed}
                />
                <label htmlFor="isSubscribed" className="w-[90%] text-left cursor-pointer">
                  {t('register.subscribe')}
                </label>
              </div>
              <div className="flex justify-between mb-3 cursor-pointer">
                <Checkbox
                  name="agreement"
                  id="agreement"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('agreement', e.target.checked)}
                  onBlur={handleBlur}
                  checked={values.agreement}
                  error={errors.agreement}
                  touched={touched.agreement}
                />
                <p className="w-[90%] text-left cursor-pointer">
                  <label className="cursor-pointer" htmlFor="agreement">
                    {t('register.readAndAccept')}{' '}
                  </label>
                  <Link to="/terms" className="font-bold" target="_blank">
                    {t('basics.terms')}
                  </Link>
                  ,{' '}
                  <Link to="/privacy-policy" className="font-bold" target="_blank">
                    {t('basics.policy')}
                  </Link>{' '}
                  <label className="cursor-pointer" htmlFor="agreement">
                    {t('basics.and')}{' '}
                  </label>
                  <Link to="/imprint" className="font-bold" target="_blank">
                    {t('basics.impressum')}
                  </Link>
                  <label className="cursor-pointer" htmlFor="agreement">
                    {t('register.afterReadAndAccept')}{' '}
                  </label>
                </p>
              </div>
              <Button
                onClick={handleSubmit}
                disabled={
                  !values.firstName ||
                  !values.lastName ||
                  !values.email ||
                  !values.password ||
                  !values.confirmPassword ||
                  !values.agreement ||
                  !!Object.keys(errors).length ||
                  isLoading
                }
                title={t('register.registerButton')}
              />
              <p className="mt-3">
                {t('register.alreadyHaveAnAccount')}{' '}
                <Link to="/login" className="font-bold cursor-pointer">
                  {t('register.loginHere')}
                </Link>
              </p>
            </div>
            {((touched.firstName && errors.firstName) ||
              (touched.lastName && errors.lastName) ||
              (touched.email && errors.email) ||
              (touched.password && errors.password) ||
              (touched.confirmPassword && errors.confirmPassword) ||
              (touched.agreement && errors.agreement)) ||
              errMsg && (
              <ErrorMsg
                text={
                  errors.firstName ||
                  errors.lastName ||
                  errors.email ||
                  errors.password ||
                  errors.confirmPassword ||
                  errors.agreement ||
                  errMsg ||
                  ''
                }
              />
            )}
          </form>
        )}
      </Formik>
    </>
  );
};
