import React, { useEffect, useState } from 'react';
import { isPlatform } from '@ionic/core';
import { useDispatch } from 'react-redux';
import i18next, { t } from 'i18next';
import { format } from 'date-fns';
import { de, uk } from 'date-fns/locale';

import { CalendarContext } from 'App';
import { useAppSelector } from 'app/hooks';
import { authSelector } from 'features/auth/authSlice';
import { nutritionSelector } from 'features/nutrition/nutritionSlice';
import { WorkoutDay } from 'features/workout/types';
import { setSelectedDate, workoutSelector } from 'features/workout/workoutSlice';
import { validateClickableDates } from 'common/helpers/validateClickableDates';

import arrowBack from 'assets/icons/ArrowBack.svg';
import ChevronLeftIcon from 'assets/icons/ChevronLeft.svg';
import ChevronRightIcon from 'assets/icons/ChevronRight.svg';

export function CalendarBuild() {
  const dispatch = useDispatch();
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const DAYS_OF_THE_WEEK = ['MO', 'DI', 'MI', 'DO', ' FR', 'SA', 'SO'];
  const MONTHS = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  const { setShowCalendar } = React.useContext(CalendarContext);
  const { user } = useAppSelector(authSelector); // start date end date console.log
  const { nutritionProgram } = useAppSelector(nutritionSelector); //borq dni + izchislenie za kraina data
  const { workoutProgram, selectedDate } = useAppSelector(workoutSelector); //

  const today = new Date();
  const [date, setDate] = useState(today);
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date));

  const oneDay = 1000 * 60 * 60 * 24;

  const toSafariDate = (date: string) => {
    const dateArray = date.split('-');
    const prepStartDate = dateArray.map((dateElem: string, index: number) => {
      if (index === 1) {
        return +dateElem - 1;
      }
      return +dateElem;
    });

    return new Date(prepStartDate[0], prepStartDate[1], prepStartDate[2]);
  };

  const startDate = toSafariDate(user.startDate).getTime();
  const endDate = nutritionProgram.days * oneDay + startDate - 1;
  const trainingDays = workoutProgram.days;

  const currentDay = Math.floor((new Date().getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24)) + 1;
  const currentSelectedDay =
    Math.floor((new Date(selectedDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24)) + 1;

  const workoutDays = trainingDays ? [...trainingDays] : [];
  const nextDayWithExercises = workoutDays
    ?.sort((a, b) => a.day - b.day)
    .find((day: WorkoutDay) => day.day > currentDay && day.exercises.length)?.day;
  const nextDateWithExercises = new Date(
    new Date(startDate).getTime() + 1000 * 3600 * 24 * (nextDayWithExercises ? nextDayWithExercises - 1 : 1)
  );

  const handleSelectDay = (date: Date) => {
    if (validateClickableDates(date, nextDateWithExercises, startDate)) {
      dispatch(setSelectedDate(new Date(date).getTime()));
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    setMonth(date.getMonth());
    setYear(date.getFullYear());
    setStartDay(getStartDayOfMonth(date));
  }, [date]);

  function getStartDayOfMonth(date: Date) {
    const day = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return day === 0 ? 7 : day;
  }

  function getDaysWithWorkouts(date: Date) {
    const day =
      Math.floor((new Date(date).getTime() - toSafariDate(user.startDate).getTime()) / (1000 * 3600 * 24)) + 1;
    const workout = trainingDays.find((trainingDay: WorkoutDay) => trainingDay.day === day);
    return !!workout;
  }

  function isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  const days = isLeapYear(date.getFullYear()) ? DAYS_LEAP : DAYS;
  const renderDays = () => {
    const daysArray: JSX.Element[] = [];
    for (let i = 1; i < startDay; i++) {
      daysArray.push(
        <div key={'placeholder' + month + i}>
          <div className={` w-9 h-9 text-[12px] text-center rounded-ms relative`}></div>
        </div>
      );
    }

    for (let i = 1; i <= days[month]; i++) {
      const d = i;
      const isToday = new Date(year, month, i).getTime() === today.setHours(0, 0, 0, 0);
      const calendarDay =
        Math.floor((new Date(year, month, i).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24)) + 1;
      daysArray.push(
        <div
          onClick={() => handleSelectDay(new Date(year, month, i))}
          key={'day' + month + i}
          className={`w-9 h-9 md:w-auto lg:h-14 text-[12px] lg:p-2 text-center rounded-ms relative
          ${calendarDay === currentSelectedDay ? 'border border-white' : ''}
          ${validateClickableDates(new Date(year, month, i), nextDateWithExercises, startDate) ? 'cursor-pointer' : ''}
          ${
            validateClickableDates(new Date(year, month, i), nextDateWithExercises, startDate) && !isToday
              ? '!bg-gray-light'
              : ''
          }
          ${
            isToday
              ? 'bg-red '
              : `${
                  new Date(year, month, i).getTime() >= startDate && new Date(year, month, i).getTime() <= endDate
                    ? 'bg-dayofweek'
                    : 'bg-transparent opacity-25 border'
                }`
          }`}
        >
          <div>{d > 0 ? d : ''}</div>
          <div className="uppercase text-center text-[10px] lg:text-[12px]">
            {/*{format(new Date(`${year}.${month + 1}.${i}`), 'eee')}*/}
            {format(new Date(year, month, i), 'EEE', {
              locale: i18next.language === 'de' ? de : uk,
            }).substr(0, 2)}
          </div>
          {getDaysWithWorkouts(new Date(year, month, i)) && (
            <div
              className={`w-1 h-1 rounded-full absolute bottom-0.5 lg:bottom-3 lg:left-[47%] left-[45%] ${
                isToday ? 'bg-white' : 'bg-red'
              }`}
            />
          )}
        </div>
      );
    }
    return daysArray;
  };

  return (
    <div
      className={`fixed py-5 pb-[64px] px-5 xl:ml-0 lg:px-40 inset-0 bg-main xl:relative xl:w-full xl:bg-gray-base xl:rounded-lg xl:p-6 z-20 ${
        isPlatform('ios') && !isPlatform('mobileweb') ? 'safe-top' : ''
      }`}
    >
      <div className="fixed block xl:hidden right-top-round left-[80%] -top-8 md:left-[90%] md:-top-36 -z-[1]" />
      <div className="fixed block xl:hidden left-down-round top-[38%] right-[60%] md:top-[50%] md:left-[-30px] -z-[1]" />
      <div className="flex flex-row justify-center xl:hidden">
        <div onClick={() => setShowCalendar(false)} className=" flex flex-1 mr-auto">
          <img className="w-4" src={arrowBack} />
          <p className="ml-1 mt-[1px] text-base">{t('basics.back')}</p>
        </div>
        <h3 className="flex-1 text-center top-0 xl:hidden text-base">{t('basics.calendar')}</h3>
        <div className="flex-1 ml-auto" />
      </div>
      <div className="safe-top">
        <div className="flex w-full  flex-row justify-between mt-10 xl:mt-0">
          <div onClick={() => setDate(new Date(year, month - 1, 1))}>
            <img className="cursor-pointer w-6" src={ChevronLeftIcon} alt={'letzte monat'} />
          </div>
          <div className="text-lg font-bold">
            {MONTHS[month]} {year}
          </div>
          <div onClick={() => setDate(new Date(year, month + 1, 1))}>
            <img className="cursor-pointer w-6" src={ChevronRightIcon} alt={'nächster monat'} />
          </div>
        </div>

        <div className="grid grid-cols-7 justify-items-center md:justify-items-stretch gap-4 mt-6">
          {DAYS_OF_THE_WEEK.map((days) => (
            <div className="text-center opacity-25 text-[10px] md:text-sm" key={days}>
              {days}
            </div>
          ))}
          {renderDays()}
        </div>
      </div>
    </div>
  );
}
