import { useSelector } from 'react-redux';

import { progressesSelector } from 'features/progress/progressSlice';
import { useGetProgressQuery } from 'features/progress/progressAPI';

export const useGetProgressQueryWithState = () => {
  const { progresses } = useSelector(progressesSelector);
  const {
    data = progresses,
    isFetching,
    isLoading,
    refetch,
  } = useGetProgressQuery(undefined, { skip: !!progresses.length });

  return { data, isFetching, isLoading, refetch };
};
