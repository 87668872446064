import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { ErrorMsg, LoadingOverlay, SlideDot } from 'common/components';
import { useSaveUserDetailsMutation } from './onboardingAPI';
import { DefaultUserInformation } from './types';
import { SetProfile } from './SetProfile/SetProfile';
import { PhysicalActivity } from './PhysicalActivity/PhysicalActivity';
import { Bodytype } from './BodyType/BodyType';
import { Goal } from './Goal/Goal';
import { Location } from './Location/Location';

import CaretLeftIcon from 'assets/icons/CaretLeft.svg';

export const OnboardingPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [saveData, { isSuccess, isError, isLoading }] = useSaveUserDetailsMutation();

  const [currentStep, setCurrentStep] = useState(1);
  const onboardingSteps = 5;
  const isOnLastStep = onboardingSteps === currentStep;

  const handleSubmit = async (values: DefaultUserInformation) => {
    if (!isOnLastStep) setCurrentStep(currentStep + 1);
    else await saveData(values);
  };

  useEffect(() => {
    if (isSuccess) navigate('/payments');
  }, [isSuccess]);

  return (
    <>
      <Formik
        initialValues={{
          gender: '',
          age: null,
          height: null,
          weight: null,
          activity: '',
          bodyType: '',
          goal: '',
          location: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="h-full">
            {currentStep === 1 && (
              <SetProfile
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            )}
            {currentStep === 2 && (
              <PhysicalActivity
                handleClick={(value: string) => setFieldValue('activity', value)}
                selectedValue={values.activity}
              />
            )}
            {currentStep === 3 && (
              <Bodytype
                handleClick={(value: string) => setFieldValue('bodyType', value)}
                selectedValue={values.bodyType}
                gender={values.gender}
              />
            )}
            {currentStep === 4 && (
              <Goal handleClick={(value: string) => setFieldValue('goal', value)} selectedValue={values.goal} />
            )}
            {currentStep === 5 && (
              <Location
                handleClick={(value: string) => setFieldValue('location', value)}
                selectedValue={values.location}
              />
            )}
            {!!currentStep && (
              <div className="flex justify-between w-full mt-10">
                <p className="inline-flex items-center cursor-pointer" onClick={() => setCurrentStep(currentStep - 1)}>
                  {currentStep > 1 && (
                    <>
                      <img className="w-5" src={CaretLeftIcon} />
                      <span>{t('basics.back')}</span>
                    </>
                  )}
                </p>

                <div className="inline-flex items-center">
                  {Array.apply(1, Array(onboardingSteps)).map((el, index) => (
                    // onClick={() => setCurrentStep(index + 1)}
                    <SlideDot key={`onboarding-${index}`} isActive={currentStep === index + 1} />
                  ))}
                </div>
                <button
                  className="cursor-pointer h-16 w-16 duration-200 hover:scale-105 bg-next-button disabled:bg-next-button-disabled"
                  type="submit"
                  disabled={
                    (currentStep === 1 && (!values.gender || !values.age || !values.height || !values.weight)) ||
                    (currentStep === 2 && !values.activity) ||
                    (currentStep === 3 && !values.bodyType) ||
                    (currentStep === 4 && !values.goal) ||
                    (currentStep === 5 && !values.location)
                  }
                />
              </div>
            )}
          </form>
        )}
      </Formik>
      {isLoading && <LoadingOverlay />}
      {isError && <ErrorMsg text={t('errors.wentWrong')} />}
    </>
  );
};
