import React from 'react';
import { useAppSelector } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { authSelector } from 'features/auth/authSlice';
import { PhasesProps } from 'features/nutrition/types';
import { nutritionSelector } from 'features/nutrition/nutritionSlice';
import { CircleProgressBar } from '../CircleProgressBar/CircleProgressBar';

export const ProgramProgress = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const { nutritionProgram } = useAppSelector(nutritionSelector);

  const startDate = format(new Date(user.startDate), 'dd.MM.yyyy');

  const currentDay = Math.floor((new Date().getTime() - new Date(user.startDate).getTime()) / (1000 * 3600 * 24)) + 1;
  const currentPhase: PhasesProps = nutritionProgram?.phases.find(
    (phase: PhasesProps) => phase.phase.fromDay <= currentDay && phase.phase.untilDay >= currentDay
  );

  const phaseProgress =
    currentDay > 0
      ? (100 * (currentDay - currentPhase?.phase?.fromDay + 1)) /
        (currentPhase?.phase?.untilDay - currentPhase?.phase.fromDay + 1)
      : 0;
  const programProgress = currentDay > 0 ? (100 * currentDay) / nutritionProgram?.days : 0;

  return (
    <div className="flex justify-between bg-progresbarbg rounded-lg h-24 xl:h-29 p-2 xl:p-3 mb-4 xl:mb-6">
      <div className="flex justify-between items-center w-[calc(50%-7px)] xl:w-[calc(50%-15px)]">
        <CircleProgressBar
          percentage={phaseProgress}
          color={nutritionProgram?.days < currentDay ? '#2BD600' : '#E0BB00'}
          day={
            currentDay > 0 && currentDay <= nutritionProgram?.days
              ? `${currentDay - currentPhase?.phase?.fromDay + 1}`
              : currentDay > nutritionProgram?.days
              ? `${currentPhase?.phase?.untilDay - currentPhase?.phase?.fromDay + 1}`
              : ''
          }
        />
        <div className="w-[calc(100%-65px)] xl:w-[calc(100%-100px)]">
          <p className="xl:text-lg font-bold">
            {t('nutrition.phase')} {currentPhase?.phaseNumber}
          </p>
          <p className="text-sm xl:text-base text-font-secondary">
            {currentDay <= 0 && `${t('nutrition.trackingStartsOn')} ${startDate}`}
            {currentDay > 0 &&
              currentDay <= nutritionProgram?.days &&
              `${currentPhase?.phase?.untilDay - currentDay} ${t('nutrition.daysLeftInPhase')}`}
            {currentDay > nutritionProgram?.days && t('nutrition.phaseCompleted')}
          </p>
        </div>
      </div>

      <div className="flex justify-between items-center w-[calc(50%-7px)] xl:w-[calc(50%-15px)]">
        <CircleProgressBar
          percentage={currentDay > nutritionProgram?.days ? 100 : programProgress}
          color="#2BD600"
          day={currentDay > 0 ? `${currentDay > nutritionProgram?.days ? nutritionProgram?.days : currentDay}` : ''}
        />
        <div className="w-[calc(100%-65px)] xl:w-[calc(100%-100px)]">
          <p className="xl:text-lg font-bold">{t('nutrition.overall')}</p>
          <p className="text-sm xl:text-base text-font-secondary">
            {currentDay <= 0 && `${t('nutrition.programStartsOn')} ${startDate}`}
            {currentDay > 0 &&
              currentDay <= nutritionProgram?.days &&
              `${nutritionProgram?.days - currentDay} ${t('nutrition.daysLeftInProgram')}`}
            {currentDay > nutritionProgram?.days && t('nutrition.programCompleted')}
          </p>
        </div>
      </div>
    </div>
  );
};
