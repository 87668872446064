import SedentaryImage from 'assets/images/Sedentary.jpeg';
import ModeratelyImage from 'assets/images/Moderately.jpeg';
import VigorouslyImage from 'assets/images/Vigorously.jpeg';
import ExtremelyImage from 'assets/images/Extremely.jpeg';

export const PhysicalActivityItems = [
  {
    title: 'Sitzende Tätigkeit',
    description: 'Beispiel: Büroangestellte, die sich wenig oder gar nicht bewegen.',
    image: SedentaryImage,
    value: 'sedentary',
  },
  {
    title: 'Geringfügig aktiv',
    description: 'Du verbringst in der Regel einen großen Teil Deines Tages auf den Beinen.',
    image: ModeratelyImage,
    value: 'moderate',
  },
  {
    title: 'Mäßig aktiv',
    description:
      'Du verbringst einen großen Teil Deines Tages mit körperlichen Tätigkeiten - zum Beispiel als Postbote oder Kellnerin.',
    image: VigorouslyImage,
    value: 'vigorous',
  },
  {
    title: 'Sehr aktiv',
    description:
      'Du verbringst die meiste Zeit Deines Tages mit körperlicher Arbeit - zum Beispiel als Schreiner oder Fahrradkurier.',
    image: ExtremelyImage,
    value: 'extreme',
  },
];
