import React from 'react';
import { t } from 'i18next';

import { Progresses, ProgressItem } from 'features/progress/types';
import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';

interface PhaseProgressProps {
  phaseNumber: number;
  phaseProgress: Progresses;
  setEntry: (value: ProgressItem | null) => void;
  programDone: boolean;
}

export const PhaseProgressGallery: React.FunctionComponent<PhaseProgressProps> = ({
  phaseNumber,
  phaseProgress,
  setEntry,
  programDone = false,
}) => {
  const { platform } = useCheckPlatform();

  return (
    <>
      {!!phaseProgress.length && (
        <div className="mb-8 last:mb-0">
          <p className="text-sm font-bold mb-4">
            {t('nutrition.phase')} {phaseNumber}
          </p>
          <div
            className={`grid grid-cols-4 ${
              programDone && platform === 'desktop' ? 'md:h-30' : 'xl:grid-cols-5 md:h-[209px]'
            } gap-[7px] xl:gap-4 h-[82px]`}
          >
            {phaseProgress?.map((entry) => (
              <img
                key={`image-${entry.id}`}
                src={entry.image?.url}
                className={`rounded-ms w-full h-[82px] ${
                  programDone && platform === 'desktop' ? 'md:h-30' : 'md:h-[209px]'
                } object-cover cursor-pointer`}
                onClick={() => setEntry(entry)}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
