import { isPlatform } from '@ionic/core';
import React, { useContext, useEffect } from 'react';
import { t } from 'i18next';
import Logo from 'assets/Logo.png';
import { NavigationItems } from './config';
import { NavigationLink } from './NavLink';
import { useLocation } from 'react-router-dom';
import { CalendarContext } from 'App';

export const Navigation = () => {
  const { pathname } = useLocation();
  const { setShowCalendar } = useContext(CalendarContext);

  useEffect(() => {
    setShowCalendar(false);
  }, [pathname]);

  return (
    <div
      className={`fixed bottom-0 ${
        isPlatform('ios') && !isPlatform('mobileweb') ? 'h-20' : 'h-16'
      } w-screen px-5 lg:py-0 lg:px-0 lg:h-screen lg:w-21 bg-gray-base z-[30]`}
    >
      <img src={Logo} className="hidden lg:block w-21 h-auto mt-6" />
      <div className="flex lg:flex-col justify-between w-full lg:mt-20">
        {NavigationItems.map((item) => (
          <NavigationLink
            key={`${item.title}-link`}
            link={item.link}
            title={t(item.title)}
            icon={item.image}
            activeIcon={item.imageActive}
          />
        ))}
      </div>
    </div>
  );
};
