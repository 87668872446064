import React from 'react';
import { t } from 'i18next';

import WorkoutIcon from 'assets/icons/Workout-active.svg';
import ScheduleIcon from 'assets/icons/Schedule.svg';
import ReplayIcon from 'assets/icons/Replay.svg';
import HourglassIcon from 'assets/icons/Hourglass.svg';

interface ExerciseInformationProps {
  sets: number;
  duration: number;
  repetitions: number;
  exerciseBreak: number;
  unit: string;
}

export const ExerciseInformation: React.FunctionComponent<ExerciseInformationProps> = ({
  sets,
  duration,
  repetitions,
  exerciseBreak,
  unit,
}) => {
  return (
    <div className="flex space-x-2 xl:space-x-4">
      {sets && (
        <div>
          <img className="inline h-4 mb-[3px] mr-1" src={WorkoutIcon} />
          <p className="inline">
            <span className="font-bold">{sets}</span> {t('workout.sets')}
          </p>
        </div>
      )}
      {duration && (
        <div>
          <img className="inline h-4 mb-[3px] mr-1" src={ScheduleIcon} />
          <p className="inline">
            <span className="font-bold">{duration}</span>{' '}
            <span className="first-letter:uppercase">{t(`workout.${unit}`)}</span>
          </p>
        </div>
      )}
      {repetitions && (
        <div>
          <img className="inline h-4 mb-[3px] mr-1" src={ReplayIcon} />
          <p className="inline">
            <span className="font-bold">{repetitions}</span> {t('workout.repetitions')}
          </p>
        </div>
      )}
      {exerciseBreak && (
        <div>
          {<img className="inline h-4 mb-[3px] mr-1" src={HourglassIcon} />}
          <p className="inline">
            <span className="font-bold">{exerciseBreak}</span>{' '}
            <span className="first-letter:uppercase">{t(`workout.${unit}`)}</span>
          </p>
        </div>
      )}
    </div>
  );
};
