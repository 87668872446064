import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarContext } from 'App';

import CalendarIcon from 'assets/icons/Calendar.svg';
import CalendarRed from 'assets/icons/Calendar-red.svg';
import InfoIcon from 'assets/icons/Info.svg';

export const Header: React.FunctionComponent<{ title: string; infoType?: string }> = ({ title, infoType }) => {
  const navigate = useNavigate();
  const { showCalendar, setShowCalendar } = useContext(CalendarContext);

  return (
    <div className="flex width-full justify-between mb-6">
      <h1>{title}</h1>
      <div className="flex">
        {infoType && (
          <img
            src={InfoIcon}
            className="mr-4 w-8 h-auto cursor-pointer"
            onClick={() => navigate(`/information${infoType ? `?type=${infoType}` : ''}`)}
          />
        )}
        <img
          onClick={() => setShowCalendar(!showCalendar)}
          src={!showCalendar ? CalendarIcon : CalendarRed}
          className="w-8 h-auto cursor-pointer"
        />
      </div>
    </div>
  );
};
