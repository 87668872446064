import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';

import { Header } from 'common/components';
import { authSelector } from 'features/auth/authSlice';
import { workoutSelector } from 'features/workout/workoutSlice';
import { nutritionSelector } from 'features/nutrition/nutritionSlice';
import { Tips } from './types';

import ArrowBack from 'assets/icons/ArrowBack.svg';
import { CalendarContext } from 'App';
import { CalendarBuild } from 'common/components/Calendar/CalendarBuild';
import { replaceDataUrl } from '../offline-mode/queries/getData';
import { useCheckPlatform } from 'common/helpers/hooks/useCheckPlatform';

interface TipElementProps {
  onClick: () => void;
  tip: Tips;
}

const TipElement: React.FunctionComponent<TipElementProps> = ({ onClick, tip }) => {
  const [storedImage, setImage] = useState(tip?.image?.url || '');

  useEffect(() => {
    const getImageFromStorage = async (image: string) => {
      return replaceDataUrl(image);
    };
    if (tip?.image?.url) {
      getImageFromStorage(tip.image.url).then((url: string) => {
        setImage(url);
      });
    }
  }, [tip?.image?.url]);

  return (
    <div onClick={onClick} className="flex">
      <img className="w-36 h-30 lg:w-[174px] object-cover rounded-ml" src={storedImage} />
      <div className="ml-4 lg:w-[calc(100%-190px)]">
        <h4 className="font-bold text-base text-left leading-5">{tip?.title}</h4>
        <p className="w-full text-sm mt-1 leading-4 text-left font-normal lg:hidden text-ellipsis">
          {tip?.description}
        </p>
      </div>
    </div>
  );
};

export const TipsPage = () => {
  const { showCalendar } = useContext(CalendarContext);
  const { t } = useTranslation();
  const { user } = useAppSelector(authSelector);
  const { platform } = useCheckPlatform();
  const { workoutProgram } = useAppSelector(workoutSelector);
  const { nutritionProgram } = useAppSelector(nutritionSelector);

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<Tips | null>(null);

  const currentDay = Math.floor((new Date().getTime() - new Date(user.startDate).getTime()) / (1000 * 3600 * 24)) + 1;

  const tips =
    workoutProgram?.tips
      .concat(nutritionProgram?.tips)
      .filter((tip: Tips, index: number, allTips: Tips[]) => allTips.map((t) => t.id).indexOf(tip.id) === index)
      .sort((a: Tips, b: Tips) => b.day - a.day) || [];

  const lastTip = !selected
    ? tips?.find((tip: Tips) => tip?.day === currentDay) ||
      tips?.filter((tip: Tips) => tip?.day < currentDay).pop() ||
      (tips?.length && tips[0]) ||
      null
    : selected;
  const leftTips = tips?.filter((tip: Tips) => tip?.day <= currentDay);

  const [storedImage, setImage] = useState(lastTip?.image?.url || '');

  useEffect(() => {
    const getImageFromStorage = async (image: string) => {
      return replaceDataUrl(image);
    };
    if (lastTip?.image?.url) {
      getImageFromStorage(lastTip.image.url).then((url: string) => {
        setImage(url);
      });
    }
  }, [lastTip?.image?.url]);

  const renderMobile = () => {
    return (
      <>
        <div className={show ? 'hidden lg:block' : ''}>
          <Header title={t('tips.title')} />
          <h3 className="lg:hidden">{t('tips.latest')}</h3>
        </div>
        <div
          className={`flex items-center relative ${show ? 'flex lg:hidden' : 'hidden'}`}
          onClick={() => setShow(false)}
        >
          <img src={ArrowBack} className="w-4" />
          <p className="ml-0.5">{t('basics.back')}</p>
          <h4 className="absolute w-full text-center">{t('tips.tips')}</h4>
        </div>

        {/* <div className="lg:flex"> */}
        <div className="lg:flex pb-[96px] xl:pb-12 lg:w-full justify-between">
          <div className="relative lg:w-[calc(60%-15px)] lg:pointer-events-none" onClick={() => setShow(true)}>
            <img className="mt-6 lg:mt-0 rounded-lg object-cover h-48 w-full lg:h-[376px]" src={storedImage} />
            {!show ? (
              <>
                <h4 className="lg:hidden absolute left-3 right-3 bottom-3 p-3 bg-main bg-opacity-75 w-fill text-center font-bold text-lg leading-5 rounded-ml">
                  {lastTip?.title}
                </h4>
                <div>
                  <p className="hidden xl:block lg:w-[100%] text-left lg:mt-10">{lastTip?.description}</p>
                </div>
              </>
            ) : null}
            <h4 className="mt-4 hidden lg:block font-bold text-lg leading-5">{lastTip?.title}</h4>
            <div>
              <p className="hidden lg:block lg:w-[100%] text-left lg:mt-10">{lastTip?.description}</p>
            </div>
          </div>

          {showCalendar ? (
            <div className="lg:w-[calc(40%-15px)] ">
              <CalendarBuild />
            </div>
          ) : (
            <div className="lg:w-[calc(40%-15px)]">
              <div className="flex mt-6 lg:mt-0 lg:hidden">
                <div>
                  <h3>{lastTip?.title}</h3>
                  <p className="mt-6 text-left">{lastTip?.description}</p>
                </div>
              </div>
              <div className="space-y-6 w-full mt-6 lg:mt-0">
                <h3 className="lg:mt-1 mb-4">{t('tips.older')}</h3>
                {!leftTips?.length ? (
                  <p className="text-font-secondary">{t('tips.noOlderTips')}</p>
                ) : (
                  leftTips?.map((tip: Tips, i: number) => (
                    <TipElement
                      key={`tip-${i}`}
                      onClick={() => {
                        setSelected(tip);
                        setShow(true);
                        window.scrollTo(0, 0);
                      }}
                      tip={tip}
                    />
                  ))
                )}
              </div>
            </div>
          )}
        </div>
        {/* </div> */}
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        {!show ? (
          <>
            <Header title={t('tips.title')} />
            <h3 className="lg:hidden">{t('tips.latest')}</h3>
          </>
        ) : (
          <div className="flex items-center relative" onClick={() => setShow(false)}>
            <img src={ArrowBack} className="w-4" />
            <p className="ml-0.5">{t('basics.back')}</p>
            <h4 className="absolute w-full text-center">{t('tips.tips')}</h4>
          </div>
        )}

        <div className="lg:flex">
          <div className="lg:flex pb-[96px] xl:pb-12 lg:w-full justify-between">
            <div className="relative lg:w-[calc(60%-15px)] lg:pointer-events-none" onClick={() => setShow(true)}>
              <img className="mt-6 lg:mt-0 rounded-lg object-cover h-48 w-full lg:h-[376px]" src={storedImage} />
              {!show ? (
                <h4 className="lg:hidden absolute left-3 right-3 bottom-3 p-3 bg-main bg-opacity-75 w-fill text-center font-bold text-lg leading-5 rounded-ml">
                  {lastTip?.title}
                </h4>
              ) : null}
              <h4 className="mt-4 hidden lg:block font-bold text-lg leading-5">{lastTip?.title}</h4>
              <div>
                <p className="hidden lg:block lg:w-[100%] text-left lg:mt-10">{lastTip?.description}</p>
              </div>
            </div>

            {showCalendar ? (
              <div className="lg:w-[calc(40%-15px)] ">
                <CalendarBuild />
              </div>
            ) : (
              <div className="flex mt-6 lg:mt-0 lg:w-[calc(40%-15px)]">
                {show ? (
                  <div>
                    <h3>{lastTip?.title}</h3>
                    <p className="mt-6 text-left">{lastTip?.description}</p>
                  </div>
                ) : (
                  <div className="space-y-6 w-full">
                    <h3 className="lg:mt-1 mb-4">{t('tips.older')}</h3>
                    {!leftTips?.length ? (
                      <p className="text-font-secondary">{t('tips.noOlderTips')}</p>
                    ) : (
                      leftTips?.map((tip: Tips, i: number) => (
                        <TipElement
                          key={`tip-${i}`}
                          onClick={() => {
                            setSelected(tip);
                          }}
                          tip={tip}
                        />
                      ))
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return <>{platform === 'non-desktop' ? renderMobile() : renderDesktop()}</>;
};
