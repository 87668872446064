import React from 'react';

import WarningIcon from 'assets/icons/Warning.svg';

export const ErrorMsg: React.FunctionComponent<{ text: string; blocked?: boolean }> = ({ text, blocked }) => {
  return (
    <div
      className={`${
        blocked ? 'mb-6' : 'fixed top-12 left-[50%] translate-x-[-50%]'
      } flex rounded-ms bg-white text-left text-error w-auto px-3 py-1 z-[30]`}
    >
      <img src={WarningIcon} className="mr-2" />
      {text}
    </div>
  );
};
