import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalsBackground } from '../ModalBackground/ModalBackground';
import { Button } from '../Button/Button';

import Logo from 'assets/Logo.png';

interface DataLoaderProps {
  showPopup: boolean;
  closePopup: () => void;
  setShouldUpdate: (shouldUpdate: boolean) => void;
}

export const DataLoader: React.FunctionComponent<DataLoaderProps> = ({ showPopup, closePopup, setShouldUpdate }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed inset-0 flex justify-center bg-main items-center z-40">
      <div className="fixed right-top-round left-[80%] bottom-[75%] md:left-[90%] md:bottom-[80%] z-[-1]"></div>
      <div className="fixed left-down-round top-[38%] right-[60%] md:top-[50%] md:right-[90%] z-[-1]"></div>
      {showPopup && (
        <ModalsBackground>
          <div>
            <h1 className="mb-4">{t('additional.newVersion')}</h1>
            <p>{t('additional.newVersionContent')}</p>
          </div>
          <div className="flex justify-between items-center">
            <div className="w-[49%]">
              <Button
                title={t('basics.update')}
                onClick={() => {
                  setShouldUpdate(true);
                  closePopup();
                }}
                responsive
              />
            </div>
            <a
              className="w-[49%] text-center font-bold"
              onClick={() => {
                setShouldUpdate(false);
                closePopup();
              }}
            >
              {t('basics.dismiss')}
            </a>
          </div>
        </ModalsBackground>
      )}
      <div className="m-auto">
        <div>
          <img src={Logo} alt="#" className="block mt-[120px] m-auto w-40 md:w-44 h-auto  md:mb-5" />
        </div>
        <div className="flex h-4 mt-16 justify-center items-end space-x-1">
          <p className="dot1 rounded-full"></p>
          <p className="dot2 rounded-full"></p>
          <p className="dot3 rounded-full"></p>
        </div>
        <p className="font-bold text-lg text-center mt-6">{t('additional.loadingData')}</p>
        <p className="text-center mt-3">{t('additional.pleaseWait')}</p>
      </div>
    </div>
  );
};
