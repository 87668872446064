import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useLoginMutation } from './authAPI';
import { LoginBody } from './types';
import { Button, ErrorMsg, Input, LoadingOverlay } from '../../common/components';

export const LoginPage: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [login, { isLoading, isError, reset }] = useLoginMutation();

  const LoginValidationSchema = Yup.object().shape({
    email: Yup.string().required(t('errors.emailRequired')).email(t('errors.emailInvalid')),
    password: Yup.string().required(t('errors.passwordRequired')).min(8, t('errors.passwordCharacters')),
  });

  const handleSubmit = async (values: LoginBody) => {
    await login({ email: values.email, password: values.password });
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <h1 className="mb-3 md:mb-6">{t('login.title')}</h1>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="flex flex-col justify-between h-[68%] w-full">
            <div className="flex flex-col">
              <Input
                autoComplete="username"
                type="email"
                name="email"
                onChange={(e) => {
                  handleChange(e);
                  reset();
                }}
                onBlur={handleBlur}
                placeholder={t('basics.email')}
                value={values.email}
                error={errors.email}
                touched={touched.email}
              />
              <Input
                autoComplete="password"
                type="password"
                name="password"
                onChange={(e) => {
                  handleChange(e);
                  reset();
                }}
                onBlur={handleBlur}
                placeholder={t('basics.password')}
                value={values.password}
                error={errors.password}
                touched={touched.password}
              />
              <Link to="/forgot-password" className="text-right">
                {t('login.forgotPasswordLink')}
              </Link>
            </div>
            <div className="w-full flex flex-col items-center">
              {((touched.email && errors.email) || (touched.password && errors.password) || isError) && (
                <ErrorMsg text={errors.email || errors.password || t('errors.invalidUser') || ''} blocked />
              )}
              <Button
                onClick={handleSubmit}
                disabled={!values.email || !values.password || !!Object.keys(errors).length || isLoading}
                title={t('login.title')}
              />
              <p className="mt-3">
                {t('login.dontHaveAccaount')}{' '}
                <Link to="/register" className="font-bold cursor-pointer">
                  {t('login.registerHere')}
                </Link>
              </p>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
