import React from 'react';
import { useTranslation } from 'react-i18next';

import { GoalItem } from '../components/GoalItem/GoalItem';
import { GoalItems } from './config';

export const Goal: React.FunctionComponent<{ handleClick: (value: string) => void; selectedValue: string }> = ({
  handleClick,
  selectedValue,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="mb-6 md:mb-9">{t('onboarding.goal')}</h1>
      <div className="flex flex-col md:flex-row justify-between h-[calc(100%-160px)]">
        {GoalItems.map((goal) => (
          <GoalItem
            key={`${goal.value}`}
            {...goal}
            handleClick={(value: string) => handleClick(value)}
            isActive={selectedValue === goal.value}
          />
        ))}
      </div>
    </>
  );
};
