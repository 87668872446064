import React, { useEffect, useState } from 'react';
import { t } from 'i18next';

import { Meal } from 'features/nutrition/types';
import { MealInformation } from 'common/components/MealInformation/MealInformation';

import ArrowBackIcon from 'assets/icons/ArrowBack.svg';
import { replaceDataUrl } from '../../../offline-mode/queries/getData';

interface MealRecipeProps {
  mealTime: 'breakfast' | 'lunch' | 'dinner' | 'snacks';
  selectedMeal: Meal;
  close?: () => void;
}

export const MealRecipe: React.FunctionComponent<MealRecipeProps> = ({ mealTime, selectedMeal, close }) => {
  const [activeButton, setActiveButton] = useState<'ingredients' | 'directions'>('ingredients');

  const { title, image, ingredients, directions, duration, difficulty } = selectedMeal;

  const [storedImage, setImage] = useState(image?.url || '');
  console.log(storedImage);
  useEffect(() => {
    const getImageFromStorage = async (image: string) => {
      return replaceDataUrl(image);
    };
    if (image?.url) {
      getImageFromStorage(image.url).then((url: string) => {
        setImage(url);
      });
    }
  }, [image.url]);

  return (
    <>
      <div className="text-center relative mb-8 xl:hidden">
        <div className="absolute flex items-center h-6" onClick={() => (close ? close() : null)}>
          <img className="w-4 h-auto mr-1" src={ArrowBackIcon} />
          <span>{t('basics.back')}</span>
        </div>
        <h4 className="first-letter:uppercase">{t(`nutrition.${mealTime}`)}</h4>
      </div>

      {storedImage !== '' && (
        <img className="rounded-lg w-full h-[220px] xl:h-60 object-cover mb-4" src={storedImage} />
      )}

      <h3 className="mb-2">{title}</h3>
      <MealInformation duration={duration} difficulty={t(`basics.${difficulty}`)} />

      <div className="flex mt-6 mb-5">
        <span
          className={`text-center w-full rounded-ms cursor-pointer text-ms font-bold py-2 ${
            activeButton === 'ingredients' ? 'font-bold bg-white-opacity shadow-box' : ''
          } `}
          onClick={() => setActiveButton('ingredients')}
        >
          {t('nutrition.ingredients')}
        </span>

        <span
          className={`text-center w-full rounded-ms cursor-pointer text-ms font-bold py-2 ${
            activeButton === 'directions' ? 'font-bold bg-white-opacity' : ''
          } `}
          onClick={() => setActiveButton('directions')}
        >
          {t('nutrition.directions')}
        </span>
      </div>
      <div className="pb-[96px] xl:pb-12">
        {activeButton === 'ingredients' ? (
          <div className="bb-inner-html" dangerouslySetInnerHTML={{ __html: ingredients }}></div>
        ) : (
          <div className="bb-inner-html" dangerouslySetInnerHTML={{ __html: directions }}></div>
        )}
      </div>
    </>
  );
};
