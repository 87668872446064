import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store/store';
import { WorkoutProgram } from './types';

const initialState: { workoutProgram: WorkoutProgram | null; selectedDate: number } = {
  workoutProgram: null,
  selectedDate: new Date().getTime(),
};

export const workoutSlice = createSlice({
  name: 'workout',
  initialState,
  reducers: {
    setWorkoutProgram: (state, { payload }) => {
      state.workoutProgram = payload;
    },
    resetWorkoutProgram: (state) => {
      state.workoutProgram = null;
    },
    setSelectedDate: (state, { payload }) => {
      state.selectedDate = payload;
    },
  },
});

export const { setWorkoutProgram, resetWorkoutProgram, setSelectedDate } = workoutSlice.actions;
export const workoutSelector = (state: RootState) => state.workoutSlice;
