import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { isPlatform } from '@ionic/core';
import { dbName } from './constants';

export const connectToDB = async () => {
  if (isPlatform('mobile') && !isPlatform('mobileweb')) {
    const connection: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);

    try {
      const ret = await connection.checkConnectionsConsistency();
      const isConn = await connection.isConnection(dbName);

      let db: SQLiteDBConnection;

      if (ret.result && isConn.result) {
        db = await connection.retrieveConnection(dbName);
      } else {
        db = await connection.createConnection(dbName, false, 'no-encryption', 1);
      }

      await db.open();

      return { db, connection };
    } catch (err) {
      console.log('ERR!');
      console.log(err);
    }
  }
};
