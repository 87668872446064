import React from 'react';

export interface LoadingOverlayProps {
  message?: string;
}

export const LoadingOverlay: React.FunctionComponent<LoadingOverlayProps> = ({ message }) => {
  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-loading z-[80]">
      <div className="animate-spin rounded-full h-16 w-16 border-4 border-b-red border-r-red border-t-red border-l-transparent"></div>
      <p className="block mt-5">{message || ''}</p>
    </div>
  );
};
