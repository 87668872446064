import React, { ChangeEvent, useEffect, useState } from 'react';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera';
import { t } from 'i18next';

import { Button, Input, LoadingOverlay } from 'common/components';
import { onlineStatus } from 'common/helpers/onlineStatus';
import { useCreateProgressMutation } from 'features/progress/progressAPI';

import CloseIcon from 'assets/icons/Close.svg';
import ImageIcon from 'assets/icons/Image.svg';
import WifiOffIcon from 'assets/icons/WifiOff.svg';
import { isPlatform } from '@ionic/react';

interface AddProgressProps {
  currentPhase: number;
  phaseId: number;
  close?: () => void;
}

export const AddProgress: React.FunctionComponent<AddProgressProps> = ({ currentPhase, phaseId, close }) => {
  const { isOnline } = onlineStatus();

  const [upload, { isSuccess, isLoading }] = useCreateProgressMutation();
  const formData = new FormData();

  const [images, setImages] = useState<ImageListType>([]);
  const [kilograms, setKilograms] = useState<number>(0);
  const [cameraImage, setCameraImage] = useState<ImageType | null>();

  const uploadProgress = async () => {
    const data = { weight: kilograms, phase: phaseId };

    if (images.length && images[0].file) {
      formData.append('files.image', images[0].file);
    }

    if (cameraImage?.dataURL) {
      const blobPrep = await fetch(cameraImage.dataURL);
      const blob = await blobPrep.blob();
      const file = new File([blob], 'File name', { type: 'image/jpg' });
      formData.append('files.image', file);
    }
    formData.append('data', JSON.stringify(data));

    await upload(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      setImages([]);
      setKilograms(0);
    }
  }, [isSuccess]);

  const getPicture = async () => {
    const options: CameraOptions = {
      quality: 100,
      sourceType: Camera.PictureSourceType.CAMERA,
      destinationType: Camera.DestinationType.DATA_URL,
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE,
      correctOrientation: true,
    };
    const imageData = await Camera.getPicture(options);
    if (imageData) {
      const newImageAsBase64 = { dataURL: 'data:image/jpeg;base64,' + imageData } as ImageType;
      setCameraImage(newImageAsBase64);
      setImages([...images, newImageAsBase64]);
    }
  };

  const handleKilogramChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value;
    if (+newVal < 0) setKilograms(0);
    else if (/\d+([.,]\d{2})/.test(newVal)) return;
    else setKilograms(+newVal);
  };

  return (
    <div className="rounded-lg xl:bg-progresbarbg md:px-4 md:py-6">
      {isLoading && <LoadingOverlay />}
      <div className="flex items-center justify-between">
        <h1>{t('progress.addProgressEntry')}</h1>
        <img className="w-8 xl:hidden" src={CloseIcon} onClick={close} />
      </div>
      {isOnline ? (
        <>
          <p className="text-font-secondary text-base mt-8 xl:mt-4 min-h-18 xl:w-[80%]">
            {t('progress.addProgressDescription', { currentPhase })}
          </p>
          <div className="mt-8 xl:mt-6">
            <h3 className="mb-3 xl:mb-4">{t('progress.setWeight')}</h3>
            <Input
              value={kilograms || ''}
              onBlur={() => null}
              onChange={handleKilogramChange}
              placeholder={t('progress.placeholder')}
              name=""
              type="number"
              autoComplete="off"
              error={undefined}
              touched={undefined}
            />
          </div>
          <div className="mt-5 pb-[96px] xl:pb-12 flex flex-col justify-between h-[calc(100%-335px)] xl:h-[calc(100%-270px)]">
            <div className="mb-10">
              <h4 className="mb-3 text-lg font-bold">{t('progress.addPhotoOptional')}</h4>
              <ImageUploading
                value={images}
                onChange={(imageList: ImageListType) => setImages(imageList)}
                maxNumber={1}
              >
                {({ imageList, onImageUpload, onImageRemove, dragProps }) => (
                  <div
                    className="relative flex flex-col items-center justify-center border border-white-opacity h-[180px] xl:h-[240px] bg-white bg-opacity-[0.15] rounded-ms text-center cursor-pointer"
                    onClick={isPlatform('mobile') && !isPlatform('mobileweb') ? getPicture : onImageUpload}
                    {...dragProps}
                  >
                    {!imageList.length && (
                      <>
                        <div className="text-base font-bold">
                          <img className="inline w-5 mb-1 mr-1" src={ImageIcon} />
                          {t('progress.addPhoto')}
                        </div>
                        <p className="hidden xl:block">{t('progress.browseFiles')}</p>
                      </>
                    )}
                    {imageList.map((image, index) => (
                      <div key={index}>
                        <img
                          className="absolute inset-0 object-cover w-full h-full p-1 md:p-4 rounded-ms md:rounded-lg"
                          src={image.dataURL}
                        />
                        <img
                          className="absolute bg-progresbarbg right-2 top-2 md:right-1 md:top-1 rounded-full p-1.5 w-6 cursor-pointer"
                          src={CloseIcon}
                          onClick={() => onImageRemove(index)}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </div>
            <Button title={t('progress.addEntry')} onClick={uploadProgress} responsive disabled={!kilograms} />
          </div>
        </>
      ) : (
        <div className="h-[calc(100vh-145px)] flex flex-col items-center justify-center">
          <span className="block mb-8 text-xl text-font-secondary">{t('progress.noInternet')}</span>
          <img src={WifiOffIcon} className="w-[196px] mb-8" />
          <span className="text-base text-center text-font-secondary">{t('progress.noInternetMsg')}</span>
        </div>
      )}
    </div>
  );
};
