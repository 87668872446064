import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ResetPasswordProps } from './types';
import { Button, ErrorMsg, Input, LoadingOverlay } from 'common/components';
import { useResetPasswordMutation } from './authAPI';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();

  const [resetPassword, { isLoading, isSuccess, isError, reset }] = useResetPasswordMutation();

  const ResetPasswordValidationSchema = Yup.object().shape({
    password: Yup.string().required(t('errors.passwordRequired')).min(8, t('errors.passwordCharacters')),
    passwordConfirmation: Yup.string()
      .required(t('errors.passwordConfirmationRequired'))
      .equals([Yup.ref('password')], t('errors.passwordConfirmationMatch')),
  });

  const handleSubmit = async (values: ResetPasswordProps) => {
    const searchParams = new URLSearchParams(search);
    const codeSearchParam = searchParams.get('code');

    await resetPassword({ ...values, code: codeSearchParam || '' });
  };

  useEffect(() => {
    if (isSuccess) navigate('/login');
  }, [isSuccess]);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <h1 className="mb-3 md:mb-6">{t('resetPassword.title')}</h1>
      <Formik
        initialValues={{ password: '', passwordConfirmation: '' }}
        validationSchema={ResetPasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit} className="flex flex-col justify-between h-[68%] w-full">
            <div className="flex flex-col">
              <Input
                autoComplete="password"
                type="password"
                name="password"
                onChange={(e) => {
                  handleChange(e);
                  reset();
                }}
                onBlur={handleBlur}
                placeholder={t('basics.password')}
                value={values.password}
                error={errors.password}
                touched={touched.password}
              />
              <Input
                autoComplete="new-password"
                type="password"
                name="passwordConfirmation"
                onChange={(e) => {
                  handleChange(e);
                  reset();
                }}
                onBlur={handleBlur}
                placeholder={t('basics.confirmPassword')}
                value={values.passwordConfirmation}
                error={errors.passwordConfirmation}
                touched={touched.passwordConfirmation}
              />
            </div>
            <div className='flex flex-col items-center'>
              <Button
                onClick={handleSubmit}
                disabled={!values.password || !values.passwordConfirmation || !!Object.keys(errors).length || isLoading}
                title={t('resetPassword.button')}
              />
              {((touched.password && errors.password) ||
                (touched.passwordConfirmation && errors.passwordConfirmation) ||
                isError) && (
                <ErrorMsg text={errors.password || errors.passwordConfirmation || t('errors.wentWrong') || ''} blocked />
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
