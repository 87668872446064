import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, FormikTouched } from 'formik';

import { SetProfileData } from '../types';
import { DropDown } from 'common/components';

interface SetProfileProps {
  values: SetProfileData;
  handleChange: (e: React.ChangeEvent) => void;
  handleBlur: (e: React.FocusEvent) => void;
  errors: FormikErrors<SetProfileData>;
  touched: FormikTouched<SetProfileData>;
}

export const SetProfile: React.FunctionComponent<SetProfileProps> = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
}) => {
  const { t } = useTranslation();

  const genderOptions = [
    {
      value: 'male',
      title: t('onboarding.male'),
    },
    {
      value: 'female',
      title: t('onboarding.female'),
    },
  ];

  const fillOptions = (startValue: number, endValue: number) => {
    const values = [];
    for (let i = startValue; i <= endValue; i++) {
      values.push({ value: i, title: i });
    }
    return values;
  };

  return (
    <>
      <h1 className="mb-6 md:mb-9">{t('onboarding.setupProfile')}</h1>
      <p className="md:w-1/2 mb-6 md:mb-8">{t('onboarding.explanation')}</p>
      <div className="h-[calc(100%-246px)] md:h-[calc(100%-234px)]">
        <div className="max-h-[441px] h-full flex flex-col md:flex-row justify-between ">
          <div className="flex flex-col h-full justify-around md:w-[calc(50%-30px)] md:max-h-[220px]">
            <div>
              <p className="font-bold mb-2">{t('onboarding.selectYourGender')}</p>
              <DropDown
                name="gender"
                placeholder={t('onboarding.selectGender')}
                value={values.gender || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.gender}
                touched={touched.gender}
                options={genderOptions}
              />
            </div>
            <div>
              <p className="font-bold mb-2">{t('onboarding.selectAge')}</p>
              <DropDown
                name="age"
                placeholder={t('onboarding.enterYourAge')}
                value={values.age || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.age}
                touched={touched.age}
                options={fillOptions(16, 99)}
              />
            </div>
          </div>
          <div className="flex flex-col h-full justify-around md:w-[calc(50%-30px)] md:max-h-[220px]">
            <div>
              <p className="font-bold mb-2">{t('onboarding.enterHeight')}</p>
              <DropDown
                name="height"
                placeholder={t('onboarding.enterHeight')}
                value={values.height || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.height}
                touched={touched.height}
                options={fillOptions(100, 250)}
              />
            </div>
            <div>
              <p className="font-bold mb-2">{t('onboarding.enterWeight')}</p>
              <DropDown
                name="weight"
                placeholder={t('onboarding.enterWeight')}
                value={values.weight || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.weight}
                touched={touched.weight}
                options={fillOptions(30, 150)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
