import { useSelector } from 'react-redux';

import { useGetProgramDetailsQuery } from 'features/payments/paymentsAPI';
import { paymentsSelector } from 'features/payments/paymentsSlice';

export const useGetProgramDetailsQueryWithState = () => {
  const { program } = useSelector(paymentsSelector);
  const { data = program, isFetching, isLoading, refetch } = useGetProgramDetailsQuery(undefined, { skip: !!program });

  return { data, isFetching, isLoading, refetch };
};
