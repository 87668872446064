import { NutritionProgram } from 'features/nutrition/types';
import { WorkoutProgram } from 'features/workout/types';
import { connectToDB } from '../connectToDb';
import { dataSchemes } from './dataSchemes';

export const storeData = async (
  data: { workout: WorkoutProgram; nutrition: NutritionProgram },
  offlineSettings: { isOfflineMode: boolean; dataSize: number }
) => {
  const connectionResult = await connectToDB();
  const DB = connectionResult?.db;
  const connection = connectionResult?.connection;

  try {
    const dataScheme = await dataSchemes(data, offlineSettings);
    const fixSingleQuoteInText = JSON.stringify(dataScheme).replace(/'/g, '###SINGLEQUOTE###');
    await connection?.importFromJson(fixSingleQuoteInText);
  } catch (err) {
    console.log(err, ' -> saving data error');
  }

  await DB?.close();
};
