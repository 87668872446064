import React, { useEffect } from 'react';
import { t } from 'i18next';
import { format } from 'date-fns';

import { ProgressItem } from 'features/progress/types';
import { Button, ErrorMsg, LoadingOverlay, SuccessMsg } from 'common/components';
import { useDeleteProgressMutation } from 'features/progress/progressAPI';

import NoPhotographyIcon from 'assets/icons/NoPhotography.svg';
import WeightIcon from 'assets/icons/Weight.svg';

export const ImageSlide: React.FunctionComponent<{
  progress: ProgressItem;
  phaseNumber: number;
  onClick: (entry: ProgressItem) => void;
}> = ({ progress, phaseNumber, onClick }) => {
  const [deleteProgress, { isSuccess, isError, isLoading, reset }] = useDeleteProgressMutation();
  const { id, image, weight, createdAt } = progress;

  const deleteEntry = async () => {
    await deleteProgress(id);
  };

  useEffect(() => {
    if (isSuccess || isError) setTimeout(() => reset(), 5000);
  }, [isSuccess, isError]);

  return (
    <div className="relative border border-white-opacity w-full h-50 xl:h-[245px] rounded-ms">
      {isLoading && <LoadingOverlay />}
      {isSuccess && <SuccessMsg text={t('success.deleteEntry')} />}
      {isError && <ErrorMsg text={t('errors.wentWrong')} />}
      <div className="h-full" onClick={() => (progress.image ? onClick(progress) : null)}>
        {image ? (
          <img src={image.url} className="cursor-pointer object-cover w-full h-full rounded-ms" />
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center text-sm mb-2">
            <img src={NoPhotographyIcon} className="w-8" />
            <span>{t('progress.noPhoto')}</span>
          </div>
        )}
      </div>
      <div className="absolute bottom-4 left-4 w-[calc(100%-32px)]">
        <div className="w-full flex justify-between items-end">
          <div className="bg-main-opacity p-2 rounded-sm w-[133px]">
            <div className="flex justify-between text-sm mb-1">
              <span>{format(new Date(createdAt), 'dd/MM/yy')}</span>
              <span>
                {t('nutrition.phase')} {phaseNumber}
              </span>
            </div>
            <div className="flex items-center">
              <img src={WeightIcon} className="w-6 mr-1" />
              <span className="text-lg font-bold">{weight} Kg</span>
            </div>
          </div>

          <div className="w-[136px]">
            <Button title={t('progress.deleteEntry')} onClick={deleteEntry} />
          </div>
        </div>
      </div>
    </div>
  );
};
